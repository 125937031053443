import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useApi } from '../../../../shared/helpers/api';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const Reauthorization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { api } = useApi();

  const result = Object.fromEntries(new URLSearchParams(location.search).entries());
  useEffect(() => {
    const controller = new AbortController();
    const reauthorize = async () => {
      if (result?.code) {
        await api.post(apiRoutes.auth.reauthorization, { code: result.code }, { signal: controller.signal });
      }
    };

    reauthorize();
    navigate(seoRoutes.login);
    return () => controller.abort();
  }, [result, navigate]);

  return (
    <div>Reauth</div>
  );
};

export default Reauthorization;
