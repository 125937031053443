import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CODE_HASH } from '../../../SEO/helpers/constants';
import Input from '../../../../shared/components/Input';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { useApi } from '../../../../shared/helpers/api';
import {
  StepEnum,
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  isSignUpButtonDisabled,
  passwordErrorsConfig,
} from '../../../SEO/views/SetPassword/utils';
import { inputFields } from './utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import AdminLoader from '../../components/AdminLoader';
import { ReactComponent as ShowPassword } from '../../../../shared/assets/eyeIcon.svg';
import { ReactComponent as InfoIcon } from '../../../../shared/assets/circleInfo.svg';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';
import { adminRoutes } from '../../../../shared/helpers/innerRoutes';

const SetAdminPassword = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ password: '', passwordConfirmation: '' });
  const [showPasswordInput, setShowPasswordInput] = useState({ password: false, passwordConfirmation: false });
  const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false);
  const [errors, setErrors] = useState({});

  const { password, passwordConfirmation } = form;
  const resetHash = new URLSearchParams(search).get(CODE_HASH);
  const errMessage = getErrorMessage({ password, passwordConfirmation, passwordConfirmationBlurred });

  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password,
    passwordConfirmation,
    passwordConfirmationBlurred,
  });

  const readOnlySingUpButton = isSignUpButtonDisabled({ errMessage, errConfirmationPasswordMessage });

  const confirmationPasswordBlurHandler = (val) => {
    setPasswordConfirmationBlurred(val);
  };

  const passwordInputForm = inputFields({ t, showPasswordInput, confirmationPasswordBlurHandler });
  const checkIfPassworsdMatch = ({ pass, passConfirm }) => {
    setErrors((prev) => ({
      ...prev,
      passwordsMatch: pass !== passConfirm ? passwordErrorsConfig.passwordsAreDifferent : false,
    }));
  };

  const passwordHelperMessage = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  const handleForm = (name) => (value) => {
    setForm((prev) => {
      if (name === StepEnum.password) {
        checkIfPassworsdMatch({ password: value, passwordConfirmation: prev.passwordConfirmation });
      } else {
        checkIfPassworsdMatch({ password: prev.password, passwordConfirmation: value });
      }
      return { ...prev, [name]: value };
    });
  };
  const submitFormHandler = async () => {
    setIsLoading(true);
    try {
      await api.post(adminApiRoutes.user.changePasswordByEmail, { newPassword: password, codeHash: resetHash });
      navigate(adminRoutes.admin);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };
  const handleError = (name) => (value) => {
    setErrors((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      ) : (
        <>
          <div className={classes.title}>{t('adminControlPanel.setPassword')}</div>
          <div className={classes.subTitle}>{t('adminControlPanel.setPasswordSubTitle')}</div>
          <div>
            {passwordInputForm.map(({ key, label, showPassword, onBlur, onFocus }) => (
              <div key={key}>
                <div className={classes.inputTitle}>{label}</div>
                <div className={classes.passwordInputWrapper}>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    labelRised
                    className={classes.input}
                    placeholder={label}
                    onChange={handleForm(key)}
                    onError={handleError(key)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                  />
                  {Boolean(form[key].length) && (
                    <ShowPassword
                      onClick={() => setShowPasswordInput((prev) => ({ ...prev, [key]: !prev[key] }))}
                      className={classes.showPasswordIcon}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={classes.passwordHelper}>
            <InfoIcon className={classes.infoIcon} />
            {passwordHelperMessage}
          </div>
          <Button
            className={classes.confirmBtn}
            label={t('global.confirm')}
            onClick={submitFormHandler}
            readOnly={readOnlySingUpButton}
          />
        </>
      )}
    </div>
  );
};

export default SetAdminPassword;
