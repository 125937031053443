import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import Pill from '../../../../shared/components/Pill';
import classes from './styles.module.css';
import { activePillConfig } from '../../views/AdministratorList/utils';
import SubscriptionToggler from '../SubscriptionToggle';
import { DATE_FORMAT_FULL_MONTH, DD_MMMM_YYYY_HOUR_FORMAT } from '../../../SEO/helpers/constants';
import { formatDate } from '../../../../shared/helpers/helpers';

export const accountDetailsConfig = ({ t, data, isManager, fetchData }) => {
  const { active, createdAt, lastLogin, activeChange, id, subscriptionStatus } = data;

  const commonConfig = [
    {
      title: t('adminControlPanel.accountSeoCreationData'),
      key: 'createdAt',
      value: formatDate(createdAt, DATE_FORMAT_FULL_MONTH),
    },
    {
      title: t('adminControlPanel.lastSeoLoggedIn'),
      key: 'lastLogin',
      value: formatDate(lastLogin, DD_MMMM_YYYY_HOUR_FORMAT),
    },
    {
      title: t('adminControlPanel.accountStatus'),
      key: 'active',
      value: (
        <Pill className={clsx(classes.pill, active && classes.activeStatusPill)} label={activePillConfig(active, t)} />
      ),
    },
    {
      key: 'lastChange',
      title: t('adminControlPanel.statusLastChange'),
      value: formatDate(activeChange, DD_MMMM_YYYY_HOUR_FORMAT),
    },
  ];

  if (isManager) {
    commonConfig.splice(2, 0, {
      key: 'subscriptionStatus',
      title: t('adminControlPanel.subscriptionStatus'),
      value: (
        <div className={classes.managerSubscription}>
          <Pill
            className={clsx(classes.pill, classes[subscriptionStatus.toLowerCase()])}
            label={activePillConfig(subscriptionStatus, t)}
          />
          <SubscriptionToggler id={id} subscriptionStatus={subscriptionStatus} onSuccess={fetchData} />
        </div>
      ),
    });
  }
  return commonConfig;
};

export const parseHistoryStatus = (changeHistoryData) => changeHistoryData.map((item) => ({
  ...item,
  createdAt: formatDate(item.createdAt, 'DD.MM.YYYY HH:mm'),
  type: item.status
    ? i18next.t('adminControlPanel.activateAccountSuccess')
    : i18next.t('adminControlPanel.desactivateAccountSuccess'),
}));
