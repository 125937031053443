import { ARTICLE_ENUM, FORMALITY_ENUM, RESPONSE_LENGTHS_ENUM, SINGULARITY_ENUM } from '../../../helpers/enums';

export const formalityOptions = (t) => [
  {
    value: FORMALITY_ENUM.formal,
    label: t('aiConfig.formal'),
    text: t('aiConfig.formalText'),
  },
  { value: FORMALITY_ENUM.notFormal, label: t('aiConfig.notFormal'), text: t('aiConfig.notFormalText') },
];

export const articleOptions = (t) => [
  { value: ARTICLE_ENUM.masculine, label: t('aiConfig.masculineLabel'), text: t('aiConfig.masculineText') },
  { value: ARTICLE_ENUM.feminine, label: t('aiConfig.femineLabel'), text: t('aiConfig.femineText') },
  { value: ARTICLE_ENUM.neutral, label: t('aiConfig.neutralLabel'), text: t('aiConfig.neutralText') },
];

export const singularityOptions = (t) => [
  { value: SINGULARITY_ENUM.singular, label: t('aiConfig.singularLabel'), text: t('aiConfig.singularText') },
  {
    value: SINGULARITY_ENUM.plural,
    label: t('aiConfig.pluralLabel'),
    text: t('aiConfig.pluralText'),
  },
];

export const responseLengthsOptions = (t) => [
  { value: 180, label: t('aiConfig.responseLengths180Label') },
  { value: 330, label: t('aiConfig.responseLengths330Label') },
  { value: 450, label: t('aiConfig.responseLengths450Label') },
];

export const formKey = {
  formality: 'formality',
  article: 'article',
  singularity: 'singularity',
  responseLength: 'responseLength',
  sign: 'sign',
  customKeywords: 'customKeywords',
};

export const SIGN_INPUT_MAX_LENGTH = 500;
export const KEYWORD_INPUT_MAX_LENGTH = 50;

export const initialFormState = {
  formality: FORMALITY_ENUM.formal,
  article: ARTICLE_ENUM.neutral,
  singularity: SINGULARITY_ENUM.plural,
  responseLength: 330,
  sign: '',
  customKeywords: [],
};

export const getQuestionnaireOption = (key, t) => ({
  formality: formalityOptions,
  article: articleOptions,
  singularity: singularityOptions,
  responseLength: responseLengthsOptions
}[key](t));
