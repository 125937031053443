import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { paymentTypeLabel } from './utils';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';
import { formatDate } from '../../../../../shared/helpers/helpers';

const { recurring } = paymentMethodEnum;

const SubscriptionSummary = ({
  subscriptionData = { subscriptionDate: '', paymentType: '', subscriptionBuyDate: '', userActionDate: '' },
  subscriptionStatus,
}) => {
  const { t } = useTranslation();
  const { paymentType, subscriptionBuyDate, subscriptionDate, userActionDate } = subscriptionData;

  const ACTIVATION_DATE = t('subscription.activationDate');
  const isTrial = !paymentType?.length;
  const isReccuring = paymentType === recurring;
  const isCanceled = subscriptionStatus === 'CANCELED';
  const EXPIRE_DATE = isReccuring && !isCanceled ? t('settings.nextPaymentDate') : t('subscription.expireDate');
  return (
    <div className={clsx(classes.subscriptionDetails, isTrial && classes.twoColumns)}>
      {!isTrial && (
        <div className={classes.value}>
          {t('subscription.paymentMethod')}
          <span>{t(paymentTypeLabel[paymentType] || '')}</span>
        </div>
      )}
      {subscriptionBuyDate && (
        <div className={classes.value}>
          {ACTIVATION_DATE}
          <span>{formatDate(subscriptionBuyDate)}</span>
        </div>
      )}
      {userActionDate && (
        <div className={classes.value}>
          {t('subscription.userActionDate')}
          <span>{formatDate(userActionDate)}</span>
        </div>
      )}
      <div className={classes.value}>
        {EXPIRE_DATE}
        <span>{formatDate(subscriptionDate)}</span>
      </div>
    </div>
  );
};

export default SubscriptionSummary;
