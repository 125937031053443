import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as InfoIcon } from '../../assets/circleInfo.svg';

const TextHelper = ({ message, customClass }) => (
  <div className={clsx(classes.passwordHelper, customClass)}>
    <InfoIcon className={clsx(classes.infoIcon, customClass)} />
    <span>{message}</span>
  </div>
);

export default TextHelper;
