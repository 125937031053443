import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { renderFeatureText } from './utils';
import { generateKey } from '../../../../shared/helpers/helpers';
import { useIsMobile } from '../../../../shared/helpers/hooks';
import { SMALL_MOBILE_WIDTH } from '../../../SEO/helpers/constants';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';
import { ReactComponent as Chevron } from '../../../../shared/assets/chevronDown.svg';

const PriceModel = ({ model, preview, purchaseMode, onPlanSelect }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const { popular, title, subTitle, price, features } = model;
  const isMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const toggleDetailsBtnLabel = isExpanded ? t('settings.hideDetails') : t('settings.showDetails');
  const navigateToWelcomeScreen = () => {
    window.location.href = '/welcome';
  };
  const expandHandler = () => {
    setIsExpanded((prev) => !prev);
  };

  const isSelectPlanButtonDisplayed = !preview || purchaseMode;
  const modelWrapperClass = clsx(
    classes.modelWrapper,
    popular && classes.popularWrapper,
    isMobile && preview && classes.mobileWrapper,
  );
  const modelTitleClass = clsx(classes.modelTitle, popular && classes.popular);
  const checkIconWrapperClass = clsx(classes.checkIconWrapper, popular && classes.popular);
  const buttonClass = clsx(classes.button, popular && classes.popularBtn);

  const mobilePreview = isMobile && preview;
  return (
    <div className={modelWrapperClass}>
      <div className={classes.row}>
        <div className={modelTitleClass}>{title}</div>
        {popular && <div className={classes.popularLabel}>{t('landingPage.pricingSection.popular')}</div>}
      </div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div className={classes.price}>
        {`${price} PLN`}
        <span>{t('landingPage.pricingSection.monthly')}</span>
      </div>

      {(!mobilePreview || isExpanded) && (
        <div
          className={clsx(
            classes.modelFeaturesList,
            mobilePreview && classes.displayNone,
            isExpanded && classes.showDetails,
          )}
        >
          {features.map((el, idx) => (
            <div key={generateKey(el, idx)} className={classes.featureText}>
              <div className={checkIconWrapperClass}>
                <CheckIcon />
              </div>
              {renderFeatureText(el)}
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <Button
          label={toggleDetailsBtnLabel}
          className={classes.showDetailsBtn}
          Icon={Chevron}
          iconStyle={clsx(classes.chevronStyle, isExpanded && classes.chevronExpanded)}
          onClick={expandHandler}
        />
      )}
      {isSelectPlanButtonDisplayed && (
        <Button
          className={buttonClass}
          label={t('landingPage.pricingSection.selectPlan')}
          onClick={purchaseMode ? () => onPlanSelect(model) : navigateToWelcomeScreen}
        />
      )}
    </div>
  );
};

export default PriceModel;
