import React from 'react';

const PillWithIcon = ({ className, children, onClick }) => (
  <div
    onClick={onClick}
    className={className}
  >
    {children}
  </div>
);

export default PillWithIcon;
