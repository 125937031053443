import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../../shared/components/Table';
import { seoCardListClientTableConfig } from './utils';
import classes from './styles.module.css';
import ExpandedSeoCardRow from '../../../../Admin/modules/SeoCards/ExpandedSeoCardRow';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH } from '../../../helpers/constants';
import CardItem from '../CardItem';

const List = ({ data, sortHandler, orderBy }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobileLayout = useIsMobile(MIN_TABLET_WIDTH);
  const onExpandRowRender = (row) => <ExpandedSeoCardRow rowData={row.links} admin={false} />;

  const onCardDetailsNavigate = (row) => {
    const { id } = row;
    navigate(`/dashboard/cards/${id}`);
  };
  const renderBoxItemListHandler = (item) => <CardItem item={item} />;

  return (
    isMobileLayout ? <div className={classes.mobileList}>{data.map(renderBoxItemListHandler)}</div> : (
      <Table
        admin={false}
        className={classes.tableSeoCardList}
        columns={seoCardListClientTableConfig(t)}
        data={data}
        editHandler={onCardDetailsNavigate}
        emptyStateTitle={t('adminControlPanel.noData')}
        expandableRow
        stickyCells={false}
        tableBodyStyle={classes.tableBody}
        onExpandRowRender={onExpandRowRender}
        orderBy={orderBy}
        onSortClick={sortHandler}
      />
    ));
};

export default List;
