import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { paymentHistoryItemConfig } from './utils';
import { actionEnum, paymentStatusEnum } from '../PaymentHistory/utils';
import { fetchInvoice } from '../PaymentHistoryList/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import Button from '../../../../../shared/components/Button';

const { getInvoice } = actionEnum;
const rejected = paymentStatusEnum.CANCELED.toLocaleLowerCase();
const completed = paymentStatusEnum.COMPLETED.toLocaleLowerCase();

const PaymentHistoryItem = ({ item }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const paymentItemStatus = item.status.value;
  const isRejected = paymentItemStatus === rejected;
  const actionButtonLabel = paymentItemStatus === completed ? t('settings.getInvoice') : t('settings.completePayment');

  const handleActionClick = useCallback((name) => {
    const { redirectUrl, id } = item;
    const apiPath = '/invoice/getInvoiceUrl';
    if (name === getInvoice) {
      fetchInvoice({ id, api, apiPath, showNotification });
    } else {
      window.location.href = redirectUrl;
    }
  }, []);
  return (
    <div key={item.id} className={classes.wrapper}>
      {paymentHistoryItemConfig.map((config) => (
        <div className={classes.dataItemWrapper} key={config.name}>
          {Boolean(config.name.length) && <p className={classes.key}>{t(config.name)}</p>}
          <div className={classes.value}>{config.render(item)}</div>
        </div>
      ))}
      {!isRejected && <Button className={classes.actionButton} onClick={() => handleActionClick(item?.action.value)} label={actionButtonLabel}/>}
    </div>
  );
};

export default PaymentHistoryItem;
