import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Tooltip from '../../../../../shared/components/Tooltip';
import { hourTransformer } from '../../../../../shared/components/Input/transformers';
import { hourValidator, makeRequired } from '../../../../../shared/components/Input/validators';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/plus.svg';
import { ReactComponent as BinIcon } from '../../../../../shared/assets/bin.svg';
import { useGetSubscriptionData } from '../../../../../shared/helpers/hooks';

const requiredHourValidator = makeRequired(hourValidator);

const HourList = ({ className, hours, onAdd, onDelete, onChange, onError, isEdit, adminPreview }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);
  const { isReadOnlySubPlan } = useGetSubscriptionData();
  const error = errors.some((el) => el.from || el.to);
  const isButtonRendered = !adminPreview && !isReadOnlySubPlan;
  const handleError = (key, index) => (value) => {
    setErrors((prev) => {
      const iterator = new Array(Math.max(prev.length, index + 1)).fill({ from: false, to: false });
      return iterator.map((el, id) => {
        const prevError = prev[id] || el;

        if (index === id) {
          return { ...prevError, [key]: value };
        }

        return prevError;
      });
    });
  };

  const handleDelete = (index) => () => {
    onDelete(index);
  };

  const handleChange = (key, index) => (value) => {
    onChange({ hourIndex: index, key, value });
  };

  const rangeErrors = useMemo(
    () => hours.map((el, index) => {
      const hourError = errors[index];
      if (hourError && (hourError.from || hourError.to)) {
        return false;
      }

      const rangeError = dayjs(`2002-08-11T${el.from}`).isAfter(`2002-08-11T${el.to}`);

      if (!rangeError) {
        return false;
      }

      return t('businessProfileEdit.wrongRange');
    }),
    [errors, hours],
  );

  const renderButton = (isAddButton, onClick, text, IconComponent) => isButtonRendered && (
  <Tooltip tooltipStyle={classes.tooltipStyle} isEdit={isEdit} text={t(text)}>
    <button
      type="button"
      onClick={onClick}
      disabled={!isEdit}
      className={classes.button}
      aria-label={isAddButton ? 'Add' : 'Delete'}
    >
      <IconComponent className={isAddButton ? classes.plusIcon : classes.binIcon} />
    </button>
  </Tooltip>
  );

  useEffect(() => {
    onError(error || rangeErrors.some(Boolean));
  }, [error]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {hours.map((item, index) => (
        <div className={classes.row}>
          <Input
            className={classes.input}
            label={t('businessProfileEdit.from')}
            readOnly={!isEdit}
            onChange={handleChange('from', index)}
            transformer={hourTransformer}
            validator={requiredHourValidator}
            onError={handleError('from', index)}
            value={item.from}
            error={rangeErrors[index]}
            showErrorAfterBlur
          />
          <Input
            className={classes.input}
            label={t('businessProfileEdit.to')}
            readOnly={!isEdit}
            onChange={handleChange('to', index)}
            transformer={hourTransformer}
            validator={requiredHourValidator}
            onError={handleError('to', index)}
            value={item.to}
            error={rangeErrors[index]}
            showErrorAfterBlur
          />
          {index === 0
            ? renderButton(true, onAdd, 'businessProfileEdit.addHourToolTip', PlusIcon)
            : renderButton(false, () => handleDelete(index), 'businessProfileEdit.removeHourToolTip', BinIcon)}
        </div>
      ))}
    </div>
  );
};

export default HourList;
