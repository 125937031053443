import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import PlansContent from '../PlansContent';
import { getNavigationText, getPreviousStep, purchaseStepConfig } from './utils';
import { SMALL_MOBILE_WIDTH, subscriptionsConfig } from '../../../helpers/constants';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import PurchaseSummary from '../PurchaseSummary';
import SecurePaymentForm from '../SecurePaymentForm';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../../../shared/helpers/enums';
import { ReactComponent as Alert } from '../../../../../shared/assets/alertTriangle.svg';

const { selectPlan, summary, secureFormRecurring } = purchaseStepConfig;

const PurchaseSubscriptionForm = ({
  onClose,
  onPaymentDataChange,
  currentSubsription,
  onSuccess,
  subscriptionData,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { subscription, status } = subscriptionData;
  const isActiveTrial = subscription === subscriptionsConfig.trial && status === SUBSCRIPTION_STATUS_ENUM.ACTIVE;

  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const {
    businessProfile: { id },
  } = useDashboard();
  const [purchaseFormStep, setPurchaseFormStep] = useState(selectPlan);

  const [payload, setPayload] = useState({
    businessProfileId: id,
    subscription: null,
    paymentType: null,
    invoiceDetailsId: null,
    consentPrivacyPolicy: null,
    consentToProvideService: null,
  });

  const selectedOffer = useMemo(
    () => ({
      title: payload?.subscription,
      type: payload?.paymentType,
    }),
    [payload],
  );

  const navigationText = getNavigationText(isSmallMobile, purchaseFormStep, t);

  const modalTitle = () => {
    if (purchaseFormStep === summary) {
      return t('subscription.purchaseSummary');
    }
    if (purchaseFormStep === selectPlan) {
      return t('subscription.buySubscription');
    }
    return t('subscription.securePayment');
  };

  const handleOfferSelect = (offer) => {
    const { title, type } = offer;
    setPayload((prev) => ({
      ...prev,
      subscription: title.toLowerCase(),
      paymentType: type,
    }));
  };

  const handleStepChange = (value) => () => {
    if (!value) {
      onClose();
      return;
    }
    setPurchaseFormStep(value);
  };

  const payloadChangeHandler = (name) => (value) => {
    setPayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSecureForm = () => {
    setPurchaseFormStep(secureFormRecurring);
  };

  const fetchTrialStatus = async () => {
    setIsLoading(true);
    try {
      const {
        data: { trialUsed },
      } = await api.post(apiRoutes.subscription.trialStatus, {});
      const userTrialAvailable = !trialUsed;
      return userTrialAvailable;
    } catch (err) {
      handleApiError({ err, showNotification, t });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const submitPurchaseRequestHandler = async () => {
    const isConsentsChecked = payload.consentPrivacyPolicy && payload.consentToProvideService;
    const isTrial = payload.subscription === subscriptionsConfig.trial;
    if (!isConsentsChecked) {
      showNotification({ message: t('subscription.pleaseAcceptConsents'), type: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      if (isTrial) {
        const trialAvailable = await fetchTrialStatus();
        if (!trialAvailable) {
          showNotification({ message: t('subscription.trialNotAvailable'), type: 'error' });
          setIsLoading(false);
          return;
        }
        await api.post(apiRoutes.subscription.startTrial, { businessProfileId: id });
        showNotification({ message: t('subscription.trialActivated'), type: 'success' });
        await onSuccess();
        onClose();
      } else {
        const { data } = await api.post(apiRoutes.payment.create, payload);
        onPaymentDataChange(data);
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      onCancel={onClose}
      modalTitle={modalTitle()}
      contentStyle={clsx(
        classes.modalWrapper,
        purchaseFormStep === summary && classes.summaryWrapper,
        isActiveTrial && classes.activeTrialWrapper,
      )}
      showArrowBack={purchaseFormStep !== selectPlan && !isSmallMobile}
      rwdBackTitle={navigationText}
      onBack={handleStepChange(getPreviousStep(purchaseFormStep))}
      backdropLocked
    >
      {isLoading && <LoaderOverlay customStyle={classes.loader} />}
      {isActiveTrial && (
        <div className={classes.trialActions}>
          <Alert className={classes.alertIcon} />
          <span>{t('subscription.activeTrialPurchaseModal')}</span>
        </div>
      )}
      <div className={classes.body}>
        {purchaseFormStep === selectPlan && (
          <PlansContent
            selectedOffer={selectedOffer}
            onOfferSelect={handleOfferSelect}
            summaryClickHandler={() => setPurchaseFormStep(summary)}
            currentSubsription={currentSubsription}
          />
        )}
        {purchaseFormStep === summary && (
          <PurchaseSummary
            selectedOffer={selectedOffer}
            onModalClose={onClose}
            onDataChange={payloadChangeHandler}
            payload={payload}
            onRecurringForm={handleSecureForm}
            submitFormHandler={submitPurchaseRequestHandler}
          />
        )}
        {purchaseFormStep === secureFormRecurring && (
          <SecurePaymentForm
            selectedOffer={selectedOffer}
            onModalClose={onClose}
            onPaymentDataChange={onPaymentDataChange}
            onDataChange={payloadChangeHandler('cardToken')}
            submitFormHandler={submitPurchaseRequestHandler}
          />
        )}
      </div>
    </Modal>
  );
};

export default PurchaseSubscriptionForm;
