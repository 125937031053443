import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import Input from '../../../../../shared/components/Input';
import { makeRequired, required } from '../../../../../shared/components/Input/validators';
import ActionBox from '../ActionBox';
import { StateEnum } from '../../../../../shared/helpers/state';
import { GOOGLE_BUSINESS_TITLE_MAX_LENGTH } from '../../../helpers/constants';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { updateBusinessProfileTitle } = apiRoutes;

const NameForm = ({ form, setForm, onError, setState, readOnlyAction, isEdit, editHandler }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const {
    businessProfile: { id: businessProfileId },
  } = useDashboard();

  const [name, setName] = useState('');

  const saveBusinessProfileTitle = async () => {
    await api.post(updateBusinessProfileTitle, {
      businessProfileId,
      title: name,
    });
  };

  const handleSuccess = () => {
    showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
    setForm((prev) => ({ ...prev, title: name }));
    setState(StateEnum.success);
    editHandler();
  };

  const handleError = (err) => {
    handleApiError({ err, showNotification, t });
    setState(StateEnum.error);
  };

  const handleSave = async () => {
    setState(StateEnum.loading);
    try {
      await saveBusinessProfileTitle();
      handleSuccess();
    } catch (err) {
      handleError(err);
    }
  };

  const handleCancel = () => {
    editHandler();
  };

  useEffect(() => {
    if (!isEdit) {
      setName(form.title);
    }
  }, [isEdit, form.title, setName]);

  return (
    <div className={classes.wrapper}>
      <Input
        label={t('businessProfileEdit.nameCompany')}
        readOnly={!isEdit}
        value={name}
        onBlur={setName}
        validator={makeRequired(required(1))}
        onError={onError}
        maxLength={GOOGLE_BUSINESS_TITLE_MAX_LENGTH}
        showErrorAfterBlur
        transformer={(v) => String(v).trim()}
        autoComplete="off"
      />
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default NameForm;
