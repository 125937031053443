import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import KeywordsSelect from '../../modules/GenerateReport/KeywordsSelect';
import Report from '../../modules/GenerateReport/Report';
import classes from './styles.module.css';
import { parseUrlPrams, validateFilters } from './utils';
import { useApi } from '../../../../shared/helpers/api';
import { ReportStatusEnum } from '../../modules/GenerateReport/Report/utils';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import { useAuth } from '../../../../shared/helpers/auth';
import LoaderComponent from '../../modules/GenerateReport/LoaderComponent';
import RWDFilters from '../../modules/GenerateReport/RWDFilters';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import { adminApiRoutes, apiRoutes } from '../../../../shared/helpers/apiRoutes';

const StateEnum = {
  loading: 'loading',
  error: 'error',
  reportDisplayed: 'reportDisplayed',
  success: 'success',
  init: 'init',
};

const FiltersStateEnum = {
  error: 'error',
  success: 'success',
};

const GenerateReport = ({ reportParams, adminPreview, userId }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { businessProfiles } = useAuth();
  const { api } = useApi();

  const [filters, setFilters] = useState({ keyword: '' });
  const [accountDetails, setAccountDetails] = useState({});
  const [status, setStatus] = useState(StateEnum.loading);
  const [filtersStatus, setFiltersStatus] = useState(FiltersStateEnum.error);
  const [report, setReport] = useState({ status: ReportStatusEnum.loading });

  const busienssProfile = useMemo(() => {
    if (!businessProfiles) {
      return null;
    }

    return businessProfiles.find((el) => el.id === filters.businessProfileId);
  }, [businessProfiles, filters.businessProfileId]);

  const handleFiltersChange = ({ name, value }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleGenerate = async () => {
    try {
      setStatus(StateEnum.loading);
      setReport({ status: ReportStatusEnum.loading });
      const payload = {
        netWidth: filters.net,
        area: filters.area * 1000,
        distance: filters.distance * 1000,
        keyword: filters.keyword,
        businessProfileId: filters.businessProfileId,
      };
      const payloadWithAddress = {
        ...payload,
        storefrontAddress: {
          locality: filters.locality,
          addressLines: filters.addressLines,
          postalCode: filters.postalCode,
        },
      };
      const payloadToUse = filters.addressLines ? payloadWithAddress : payload;
      const { data: generatedReport } = await api.post(apiRoutes.generateReport.generateReport, payloadToUse);
      const { data } = await api.post(apiRoutes.report.getReport, {
        reportId: generatedReport.reportId,
        businessProfileId: busienssProfile.id,
      });
      setReport({ status: ReportStatusEnum.exists, data });
      setFilters((prev) => ({ ...prev, reportId: generatedReport.reportId }));
      setStatus(StateEnum.reportDisplayed);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };
  const fetch = async (controller) => {
    try {
      setStatus(StateEnum.init);
      const params = parseUrlPrams({ search: search || reportParams });
      if (!adminPreview) {
        const { data: accountDetailsData } = await api.post(apiRoutes.account.getAccountDetails, {});
        setAccountDetails({ businessProfiles: accountDetailsData.businessProfiles });
      }
      if (!params) {
        setFilters({ area: 5, distance: 2, net: 7 });
        setStatus(StateEnum.success);
        return;
      }
      if (params.reportId) {
        const apiPath = adminPreview ? adminApiRoutes.client.getReport : apiRoutes.report.getReport;
        const apiPayload = adminPreview
          ? { reportId: params.reportId, userId }
          : { reportId: params.reportId, businessProfileId: params.businessProfileId };
        const { data } = await api.post(apiPath, apiPayload, { signal: controller.signal });
        setReport({ status: ReportStatusEnum.exists, data });
        setFilters(params);
        setStatus(StateEnum.reportDisplayed);
        return;
      }
      if (params.businessProfileId) {
        const hasBusinessProfile = businessProfiles.find((el) => el.id === params.businessProfileId);
        if (!hasBusinessProfile) {
          throw new Error('Wrong businessProfileId');
        }
        if (params.keyword) {
          const hasKeyword = hasBusinessProfile.keywords.some((el) => el.id === params.keywordId);
          if (!hasKeyword) {
            throw new Error('Wrong kayword');
          }
        }
      }
      setFilters(params);
      setStatus(StateEnum.success);
    } catch (err) {
      if (err.message !== 'canceled') {
        setStatus(StateEnum.error);
        handleApiError({ err, showNotification, t });
      }
    }
  };
  useEffect(() => {
    if (adminPreview) {
      return;
    }
    const params = new URLSearchParams('');
    const isValid = validateFilters(filters);
    setFiltersStatus(isValid ? FiltersStateEnum.success : FiltersStateEnum.error);
    if (isValid) {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, value);
      });
      const newSearch = params.toString();
      if (newSearch !== window.location.search.substring(1)) {
        navigate({
          search: newSearch,
        });
      }
    }
  }, [adminPreview, filters]);

  useEffect(() => {
    const controller = new AbortController();

    fetch(controller);

    return () => controller.abort();
  }, [reportParams, userId]);

  return (
    <div className={classes.wrapper}>
      {status === StateEnum.loading && <LoaderComponent message={t('generateReport.generatingReport')} />}
      {status === StateEnum.init && <LoaderComponent message={t('generateReport.downloadingReport')} />}
      <KeywordsSelect
        className={classes.select}
        values={filters}
        buttonLabel={t('generateReport.addKeyWords')}
        onChange={handleFiltersChange}
        accountDetails={accountDetails}
        onGenerateReport={handleGenerate}
        adminPreview={adminPreview}
      />
      <div className={classes.rwdFilters}>
        <RWDFilters
          readOnlyBtn={filtersStatus === FiltersStateEnum.error}
          onGenerateReport={handleGenerate}
          accountDetails={accountDetails}
          onChange={handleFiltersChange}
          values={filters}
        />
      </div>

      {status === StateEnum.reportDisplayed && (
        <Report
          filters={filters}
          businessProfiles={businessProfiles}
          className={clsx(classes.report, StateEnum.loading === status && classes.blurredReport)}
          data={report.data}
          businessProfile={busienssProfile}
          adminPreview={adminPreview}
        />
      )}
      {status === StateEnum.error && (
        <div className={classes.body}>
          <ErrorMessage message={t('global.errorOccurred')} />
        </div>
      )}
    </div>
  );
};

export default GenerateReport;
