import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/editIcon.svg';
import { useModal } from '../../../../../shared/helpers/hooks';
import AddMediaModal from '../../Media/AddMediaModal';
import VideoPlayer from '../../../../../shared/components/VideoPlayer';

const PostponedMediaItem = ({ onItemClick, item, isSelected, isEdit, onSuccess }) => {
  const { t } = useTranslation();
  const { modalHandler, showModal } = useModal();
  const { name, thumbnailUrl } = item;

  const toggleModal = (e) => {
    e.stopPropagation();
    modalHandler();
  };

  return (
    <>
      {showModal && <AddMediaModal onSuccess={onSuccess} handleModal={modalHandler} editItem={item} />}
      <div className={classes.postponedCover} onClick={onItemClick}>
        {item.isImage ? (
          <img
            className={clsx(classes.userImage, isSelected && classes.borderSelected)}
            key={name}
            src={thumbnailUrl}
            alt="business profile media"
            crossOrigin="anonymous"
          />
        ) : (
          <VideoPlayer crossAnonymous videoUrl={item.sourceUrl} />
        )}
      </div>
      <div onClick={toggleModal}>
        <Pill
          label={t('businessProfileEdit.publication', { date: item.publishDate })}
          className={classes.postponedPill}
          Icon={isEdit && EditIcon}
          iconStyle={classes.pillIconStyle}
        />
      </div>
    </>
  );
};
export default PostponedMediaItem;
