import React, { useEffect, useId, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as ErrorIcon } from '../../assets/errorTriangle.svg';
import CharCounter from '../CharCounter';

const Textarea = ({
  labelRised,
  label,
  className,
  value,
  onChange = () => {},
  onBlur = () => {},
  validator,
  onError = () => {},
  readOnly,
  onFocus = () => {},
  error,
  type = 'text',
  onKeyDown,
  showErrorAfterBlur,
  transformer,
  maxLength,
  textAreaStyle,
  admin,
}) => {
  const id = useId();
  const [afterBlur, setAfterBlur] = useState(false);
  const [innerState, setInnerState] = useState(transformer ? transformer(value) : value);
  const [innerError, setInnerError] = useState(() => (validator ? validator(value) : false));
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const labelIsUp = focused || innerState || labelRised;

  const showError = (!showErrorAfterBlur || afterBlur) && !readOnly && innerError;
  const renderCounter = maxLength && Boolean(innerState?.length) && !readOnly;

  const handleError = (isValid) => {
    setInnerError(isValid);
    if (!readOnly) {
      onError(isValid);
    }
  };

  const adjustHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [innerState]);

  useEffect(() => {
    setTimeout(adjustHeight, 0);
  }, []);

  const handleChange = (event) => {
    const currentValue = transformer ? transformer(event.target.value) : event.target.value;

    if (validator) {
      const isValid = validator(event.target.value);
      handleError(isValid);
    }
    setInnerState(currentValue);
    onChange(event.target.value);
    setTimeout(adjustHeight, 0);
  };

  const blurHandler = () => {
    setAfterBlur(true);
    setFocused(false);
    onBlur(innerState);
  };

  const focusHandler = () => {
    setFocused(true);
    onFocus();
  };

  useEffect(() => {
    if (readOnly) {
      onError(false);
    } else {
      onError(innerError);
    }
  }, [readOnly]);

  useEffect(() => {
    handleError(error);
  }, [error]);

  useEffect(() => {
    const currentValue = transformer ? transformer(value) : value;
    if (validator) {
      const isValid = validator(currentValue);
      handleError(isValid);
    }
    setInnerState(currentValue);
  }, [value]);

  return (
    <div className={classes.textAreaBox}>
      <div
        className={clsx(
          className,
          classes.wrapper,
          focused && classes.focused,
          readOnly && classes.disabled,
          showError && classes.error,
          admin && classes.admin,
        )}
      >
        {label && (
          <div className={clsx(classes.label, labelIsUp && classes.focused, readOnly && classes.disabled)}>{label}</div>
        )}
        <textarea
          id={id}
          maxLength={maxLength}
          onKeyDown={onKeyDown}
          ref={inputRef}
          disabled={readOnly}
          className={clsx(classes.textarea, textAreaStyle)}
          value={innerState}
          onChange={handleChange}
          onFocus={focusHandler}
          onBlur={blurHandler}
          style={{ overflowY: 'hidden', resize: 'none' }}
        />
        {renderCounter && (
          <CharCounter className={classes.counter} maxLength={maxLength} value={innerState?.length || 0} />
        )}
      </div>
      {showError && (
        <div className={classes.errorLabel}>
          <ErrorIcon className={classes.errorIcon} />
          {error || t(innerError)}
        </div>
      )}
    </div>
  );
};

export default Textarea;
