import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Radio from '../../../../../shared/components/Radio';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';

const QuestionnaireOptions = ({ handleFormChange, selectedValue, options, questionLabel }) => (
  <div className={classes.questionnaireSection}>
    <div className={classes.question}>{questionLabel}</div>
    <div className={classes.radioWrapper}>
      {options.map(({ value, label, text }) => (
        <div key={value} className={classes.radio}>
          <PillWithIcon
            onClick={() => handleFormChange(value)}
            className={clsx(classes.radioInput, value === selectedValue && classes.active)}
          >
            <Radio
              className={classes.circle}
              onClick={() => handleFormChange(value)}
              value={value === selectedValue}
            />
            <p>{label}</p>
          </PillWithIcon>
          <span className={classes.radioText}>{text}</span>
        </div>
      ))}
    </div>
  </div>
);

export default QuestionnaireOptions;
