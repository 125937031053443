export const priceModelBusinessCardTitles = (t) => [
  {
    title: t('landingPage.pricingSection.free'),
    price: 0,
    period: t('landingPage.pricingSection.monthly'),
  },
  {
    title: t('landingPage.pricingSection.mini'),
    price: 29,
    period: t('landingPage.pricingSection.monthly'),
  },
  {
    title: t('landingPage.pricingSection.basic'),
    price: 99,
    period: t('landingPage.pricingSection.monthly'),
  },
  {
    title: t('landingPage.pricingSection.pro'),
    price: 149,
    period: t('landingPage.pricingSection.monthly'),
  },
  {
    title: t('landingPage.pricingSection.trial'),
    price: 0,
    period: t('landingPage.pricingSection.monthly'),
  },
];

export const plans = (t) => [
  {
    featureTitle: t('landingPage.pricingSection.import'),
    availability: { free: true, mini: true, basic: true, pro: true, trial: true },
  },
  {
    featureTitle: t('landingPage.pricingSection.dashboard'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.businessCardStatus'),
        availability: { free: true, mini: true, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.displayAmountStatistic'),
        availability: { free: true, mini: true, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.businessProfileCompletion'),
        availability: { free: true, mini: true, basic: true, pro: true, trial: true },
      },
    ],
  },
  {
    featureTitle: t('landingPage.pricingSection.businessProfileEdit'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.basicInformation'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: t('landingPage.pricingSection.preview'),
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.contactInformation'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: t('landingPage.pricingSection.preview'),
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.locationAndArea'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: t('landingPage.pricingSection.preview'),
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.openingHours'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: t('landingPage.pricingSection.preview'),
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.services'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: t('landingPage.pricingSection.preview'),
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.descriptionAIAssistant'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
    ],
  },
  {
    featureTitle: t('landingPage.pricingSection.reviews'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.displayAndFilter'),
        availability: {
          free: t('landingPage.pricingSection.preview'),
          mini: true,
          basic: true,
          pro: true,
          trial: true,
        },
      },
      {
        name: t('landingPage.pricingSection.reviewAnswering'),
        availability: { free: false, mini: true, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.answerTemplatesMassActions'),
        availability: { free: false, mini: true, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.reviewAIAssistant'),
        availability: { free: false, mini: true, basic: true, pro: true, trial: true },
      },
    ],
  },
  {
    featureTitle: t('landingPage.pricingSection.localPosts'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.createLocalPost'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.scheduleAddPost'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
    ],
  },
  {
    featureTitle: t('landingPage.pricingSection.media'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.uploadMedia'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
      {
        name: t('landingPage.pricingSection.scheduleAddMedia'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
    ],
  },
  {
    featureTitle: t('landingPage.pricingSection.reports'),
    subFeatures: [
      {
        name: t('landingPage.pricingSection.numberOfReports'),
        availability: { free: false, mini: false, basic: 2, pro: 4, trial: 2 },
      },
      {
        name: t('landingPage.pricingSection.reportNet'),
        availability: { free: false, mini: false, basic: '7x7', pro: '7x7', trial: '7x7' },
      },
      {
        name: t('landingPage.pricingSection.aiPositioningAssistant'),
        availability: { free: false, mini: false, basic: true, pro: true, trial: true },
      },
    ],
  },
];

export const priceModelSEOCardsMatrixConfig = (t) => [
  {
    featureTitle: t('landingPage.pricingSection.manageCards'),
    availability: { free: true, mini: true, basic: true, pro: true, trial: true },
  },
  {
    featureTitle: t('landingPage.pricingSection.cardStatistic'),
    availability: {
      free: t('landingPage.pricingSection.monthlyPlural'),
      mini: t('landingPage.pricingSection.weeklyPlural'),
      basic: t('landingPage.pricingSection.dailyPlural'),
      pro: t('landingPage.pricingSection.hourlyPlural'),
      trial: t('landingPage.pricingSection.hourlyPlural'),
    },
  },
];
