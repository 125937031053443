import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import Stars from '../../../../../shared/components/Stars';
import { parsingStringStars } from '../../../views/Opinions/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';
import AITabs from '../AITabs';
import { aiTabsConfig } from '../AITabs/utils';
import { ReactComponent as Rocket } from '../../../../../shared/assets/rocket.svg';
import { ReactComponent as StarIcon } from '../../../../../shared/assets/star.svg';
import { ratingConfig } from './utils';

const ReviewResponseExamples = ({ businessProfile, goBackHandler, toggler, responseExamples, setResponseExamples }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const fetchReviewResponseExamples = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(apiRoutes.review.getReviewResponseExamples, {
        businessProfileId: businessProfile.id,
      });
      setResponseExamples(data);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const displayReviewsHandler = () => {
    navigation(seoRoutes.opinions, { state: { businessProfile } });
  };
  useEffect(() => {
    if (businessProfile.id && !responseExamples.length) {
      fetchReviewResponseExamples();
    }
  }, [businessProfile.id, responseExamples.length]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.overlay} />;
  }

  return (
    <div className={classes.wrapper}>
      <AITabs toggler={toggler} current={aiTabsConfig.examples} />
      <div className={classes.header}>
        <h3 className={classes.title}>{t('aiConfig.promptExamples')}</h3>
        <p>{t('aiConfig.promptExamplesSubInfo')}</p>
      </div>
      <div className={classes.boxTypeWrapper}>
        <p>{t('aiConfig.review')}</p>
        <p>{t('aiConfig.assistantProposal')}</p>
      </div>
      <div className={classes.content}>

        {responseExamples.map((example, index) => (
          <div key={example.content} className={classes.example}>
            {example.starRating === ratingConfig.FOUR && (
              <div key={example.content} className={classes.reviewType}>
                <span>{t('aiConfig.goodReviews')}</span>
                <StarIcon className={classes.starIcon} />
              </div>
            )}
            {example.starRating === ratingConfig.THREE && (
              <div key={example.content} className={classes.reviewType}>
                <span>{t('aiConfig.worseReviews')}</span>
                <StarIcon className={classes.starIcon} />
              </div>
            )}
            <div className={classes.exampleContent}>
              <div className={classes.review}>
                <span className={classes.reviewAuthor}>{example.reviewer.displayName}</span>
                <Stars rating={parsingStringStars(example.starRating)} />
                <span className={classes.reviewText}>{example.comment}</span>
              </div>
              <div className={classes.review}>
                <span className={classes.reviewText}>{example.reviewReply.comment}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.actionsWrapper}>
        <Button onClick={goBackHandler} className={classes.goBackBtn} label={t('global.back')}/>
        <Button Icon={Rocket} iconStyle={classes.rocketIcon} onClick={displayReviewsHandler} className={classes.confirmBtn} label={t('aiConfig.displayReviews')} />
      </div>
    </div>
  );
};

export default ReviewResponseExamples;
