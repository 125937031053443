import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { t } from 'i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useDashboard } from '../../helpers/dashboard';
import { useNotification } from '../../../../shared/helpers/notification';
import VerificationActions from '../../modules/Verification/VerificationActions';
import VerificationOptionsWrapper from '../../modules/Verification/VerificationOptionsWrapper';
import VerificationStatus from '../../modules/Verification/VerificationStatus';
import AddressForm from '../../modules/Verification/AddressForm';
import VerificationPin from '../../modules/Verification/VerificationPin';
import { businessTypeEnum } from '../../modules/BusinessProfileCreate/FormShareLocation/utils';
import { GOOGLE_VERIFY_URL } from '../../helpers/constants';
import { fetchOptions, fetchStatus, verificationPending } from './utils';
import { parseProfileStatus } from '../../components/DashboardLayout/utils';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';

const VerificationView = ({ businessCreated }) => {
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { pathname } = useLocation();
  const { businessProfile } = useDashboard();

  const [isLoading, setIsLoading] = useState(false);
  const [verifyOptions, setVerifyOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [businessStatus, setBusinessStatus] = useState(null);
  const [verificationName, setVerificationName] = useState(null);
  const [customerOnlyBusinessLocation, setCustomerOnlyBusinessLocation] = useState(null);
  const [verificationPayload, setVerificationPayload] = useState(null);
  const [payload, setPayload] = useState(null);
  const [businesssId, setBusinessId] = useState(null);

  const { businessType } = businessProfile;

  const shouldRenderAddressForm = (businessType === businessTypeEnum.CUSTOMER_LOCATION_ONLY || !businessCreated?.storeFrontAddress)
    && !verifyOptions.length;

  const standAloneVerification = pathname === seoRoutes.verification;

  const verificationPayloadChangeHandler = (name) => (value) => {
    setVerificationPayload((prev) => ({ ...prev, [name]: value }));
  };

  const onOptionSelectHandler = (option) => {
    setSelectedOption(option);
  };

  const handleVerification = async () => {
    if (selectedOption.method === 'GOOGLE') {
      window.open(GOOGLE_VERIFY_URL, '_blank');
    } else {
      try {
        setIsLoading(true);
        const {
          data: { name },
        } = await api.post(apiRoutes.businessProfile.verifyBusinessProfile, payload);
        setVerificationName(name);
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const shouldFetchOptions = !verificationPending.includes(businessStatus?.id) && businesssId;
    if (shouldFetchOptions) {
      fetchOptions({ setIsLoading, api, businesssId, setVerifyOptions });
    }
  }, [businessCreated, businesssId]);

  useEffect(() => {
    const statusInLocalStorage = localStorage.getItem(`status-${businesssId}`);
    setBusinessStatus(statusInLocalStorage);
    if (businesssId && !statusInLocalStorage) {
      fetchStatus({ api, setBusinessStatus, businesssId, showNotification, t, parseProfileStatus });
    }
  }, [businesssId]);

  useEffect(() => {
    setBusinessId(businessCreated?.id ? businessCreated.id : businessProfile.id);
  }, [businessCreated]);

  useEffect(() => {
    const payloadWithNoContext = {
      businessProfileId: businesssId,
      ...verificationPayload,
    };

    const payloadWithContext = { ...payloadWithNoContext, context: { ...customerOnlyBusinessLocation } };

    setPayload(shouldRenderAddressForm ? payloadWithContext : payloadWithNoContext);
  }, [shouldRenderAddressForm, selectedOption, customerOnlyBusinessLocation, businesssId, verificationPayload]);

  useEffect(() => {
    setVerificationPayload(() => ({
      method: selectedOption?.verificationMethod,
      phoneNumber: selectedOption?.phoneNumber,
    }));
  }, [selectedOption]);

  if (isLoading || !businessStatus) {
    return <LoaderOverlay />;
  }

  if (verificationPending?.includes(businessStatus)) {
    return <VerificationStatus status={businessStatus} />;
  }

  if (verificationName) {
    return (
      <VerificationPin
        verificationName={verificationName}
        id={businessProfile.id}
        standAloneVerification={standAloneVerification}
      />
    );
  }

  if (shouldRenderAddressForm) {
    return (
      <AddressForm
        getVerificationOptions={setVerifyOptions}
        setAddress={setCustomerOnlyBusinessLocation}
        standAloneVerification={standAloneVerification}
        businessCreated={businessCreated}
      />
    );
  }

  return (
    <div className={clsx(classes.wrapper, standAloneVerification && classes.standAloneWrapper)}>
      <VerificationOptionsWrapper
        onOptionSelectHandler={onOptionSelectHandler}
        selectedOption={selectedOption}
        verifyOptions={verifyOptions}
        standAloneVerification={standAloneVerification}
        onPayloadChange={verificationPayloadChangeHandler}
      />
      <VerificationActions handleVerification={handleVerification} standAloneVerification={standAloneVerification} />
    </div>
  );
};
export default VerificationView;
