import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import Button from '../../../../../shared/components/Button';
import Textarea from '../../../../../shared/components/Textarea';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { getModalText } from './utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const DesactivateAccountModal = ({ onClose, userData, fetchData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [readOnlyBtn, setReadOnlyBtn] = useState(true);

  const { active, id: userId } = userData;
  const { modalTitle, reasonText } = getModalText(active, t);

  const notificationMessage = active
    ? t('adminControlPanel.desactivateAccountSuccess')
    : t('adminControlPanel.activateAccountSuccess');

  const onSaveBtnClick = async () => {
    setIsLoading(true);
    try {
      await api.post(adminApiRoutes.client.updateClientActive, { userId, active: !active, comment });
      await fetchData(adminApiRoutes.client.clientDetails, { id: userId });
      showNotification({
        type: 'success',
        message: notificationMessage,
      });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const onReasonChange = (value) => {
    setComment(value);
  };

  useEffect(() => {
    const minReasonLength = 10;
    setReadOnlyBtn(comment.length < minReasonLength);
  }, [comment]);

  return (
    <Modal
      titleStyle={classes.desactivateModalTitleStyle}
      customIconStyle={classes.closeIcon}
      contentStyle={classes.modalContent}
      onCancel={onClose}
      modalTitle={modalTitle}
    >
      {isLoading ? (
        <LoaderOverlay customStyle={classes.loaderWrapper} isAdmin />
      ) : (
        <>
          <div className={classes.reasonInformation}>{reasonText}</div>
          <Textarea className={classes.textArea} admin onChange={onReasonChange} maxLength={1000} value={comment} />
          <div className={classes.actionsWrapper}>
            <Button label={t('global.cancel')} onClick={onClose} />
            <Button
              className={classes.saveBtn}
              label={t('global.confirm')}
              readOnly={readOnlyBtn}
              onClick={onSaveBtnClick}
            />
          </div>
        </>
      )}
    </Modal>
  );
};

export default DesactivateAccountModal;
