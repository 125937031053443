export const apiRoutes = {
  account: {
    detachAccount: 'account/detachAccount',
    getAccountDetails: '/account/getAccountDetails',
  },
  auth: {
    accountInfo: '/auth/accountInfo',
    changePassword: '/auth/changePassword',
    changeUserPassword: '/auth/changeUserPassword',
    getSession: '/auth/getSession',
    login: '/auth/login',
    logout: '/auth/logout',
    updateUserPhoneNumber: '/auth/updateUserPhoneNumber',
    reauthorization: '/auth/reauthorization',
    refreshToken: '/auth/refreshToken',
    resetPassword: 'auth/resetPassword',
    signUp: '/auth/signUp',
    updatePhoneNumber: '/auth/updatePhoneNumber',
  },
  businessProfile: {
    attachBusinessProfiles: '/business_profile/attachBusinessProfiles',
    attachExternalBusinessProfile: '/business_profile/attachExternalBusinessProfile',
    createBusinessProfile: 'business_profile/createBusinessProfile',
    detachBusinessProfiles: '/business_profile/detachBusinessProfiles',
    getBusinessProfile: '/business_profile/getBusinessProfile',
    getBusinessProfiles: '/business_profile/getBusinessProfiles',
    getBusinessProfileChangeHistory: '/business_profile/getBusinessProfileChangeHistory',
    getBusinessProfileNumber: '/business_profile/getBusinessProfileNumber',
    getVerificationOptions: '/business_profile/getVerificationOptions',
    getVerificationStatus: '/business_profile/getVerificationStatus',
    searchGoogleLocation: '/business_profile/searchGoogleLocation',
    updateBusinessOpeningInfo: 'business_profile/updateBusinessOpeningInfo',
    updateBusinessProfileAddress: '/business_profile/updateBusinessProfileAddress',
    updateBusinessProfileCategories: '/business_profile/updateBusinessProfileCategories',
    updateBusinessProfileDescription: '/business_profile/updateBusinessProfileDescription',
    updateBusinessProfileHours: '/business_profile/updateBusinessProfileHours',
    updateBusinessProfileLabels: '/business_profile/updateBusinessProfileLabels',
    updateBusinessProfilePhoneNumbers: '/business_profile/updateBusinessProfilePhoneNumbers',
    updateBusinessProfileServiceArea: '/business_profile/updateBusinessProfileServiceArea',
    updateBusinessProfileServiceItems: 'business_profile/updateBusinessProfileServiceItems',
    updateBusinessProfileTitle: '/business_profile/updateBusinessProfileTitle',
    updateBusinessProfileWebsite: '/business_profile/updateBusinessProfileWebsite',
    verifyBusinessProfile: '/business_profile/verifyBusinessProfile',
    verifyCompleteBusinessProfile: '/business_profile/verifyCompleteBusinessProfile',
  },
  card: {
    cardDelete: '/card/cardDelete',
    cardDetails: '/card/cardDetails',
    cardList: '/card/cardList',
    cardListExport: '/card/cardListExport',
    linkList: '/card/linkList',
    linkStatistic: '/card/linkStatistic',
    linkStatisticsExport: '/card/linkStatisticsExport',
    updateCardDetails: '/card/updateCardDetails',
    updateLink: '/card/updateLink',
  },
  generateReport: {
    generateReport: '/generate_report/generateReport',
  },
  googleAuth: {
    getAccounts: '/google_auth/getAccounts',
    getGoogleAuthUrl: '/google_auth/getGoogleAuthUrl',
    getReauthorizationUrl: '/google_auth/getReauthorizationUrl',
  },
  invoice: {
    addUserInvoiceDetails: '/invoice/addUserInvoiceDetails',
    autocompleteByNip: '/invoice/autocompleteByNip',
    delete: '/invoice/deleteUserInvoiceDetails',
    editUserInvoiceDetails: '/invoice/editUserInvoiceDetails',
    getDetails: '/invoice/getUserInvoiceDetails',
    getUrl: '/invoice/getInvoiceUrl',
    setDefaultInvoiceDetails: '/invoice/setDefaultInvoiceDetails',
  },
  localPost: {
    deleteLocalPost: '/local_post/deleteLocalPost',
    deletePostponedLocalPost: '/local_post/deletePostponedLocalPost',
    localPostList: '/local_post/localPostList',
  },
  map: {
    generateMapByAddress: '/map/generateMapByAddress',
  },
  media: {
    createMedia: '/media/createMedia',
    createPostponedMedia: '/media/createPostponedMedia',
    getMediaList: '/media/mediaList',
    delete: '/media/delete',
    mediaList: '/media/mediaList',
    update: '/media/update',
  },
  notification: {
    deleteNotifications: '/notification/deleteNotifications',
    notificationList: '/notification/notificationList',
    updateIsViewed: '/notification/updateIsViewed',
  },
  payment: {
    create: '/payment/createPayment',
    listPayments: '/payment/listPayments',
  },
  performance: {
    getKeywords: '/performance/getKeywords',
  },
  place: {
    getPlacePhoto: '/place/getPlacePhoto',
    getPlaceNameByPlaceReferenceId: '/place/getPlaceNameByPlaceReferenceId',
    getServiceAreaAutocompleteList: '/place/getServiceAreaAutocompleteList',
    generatePlacePhoto: '/place/generatePlacePhoto',
  },
  placeCategory: {
    getPlaceCategory: '/place_category/getPlaceCategory',
    getPlaceCategoryList: '/place_category/getPlaceCategoryList',
  },
  report: {
    getReport: '/report/getReport',
    getReportList: '/report/getReportList',
    generateReportPdf: '/report/generateReportPdf',
  },
  review: {
    answerWithTemplate: '/review/postReviewsTemplate',
    deleteReviewReply: '/review/deleteReply',
    getAutomatic: '/review/getAutomatic',
    getReviews: '/review/getReviews',
    getReviewsLink: '/review/getReviewsLink',
    upsertReply: '/review/upsertReply',
    upsertPromptConfiguration: '/review/upsertPromptConfiguration',
    getPromptConfiguration: '/review/getPromptConfiguration',
    deletePromptConfiguration: '/review/deletePromptConfiguration',
    getReviewResponseExamples: '/review/getReviewResponseExamples',
  },
  reviewReplyTemplate: {
    addTemplate: '/review_reply_template/addTemplate',
    deleteTemplate: '/review_reply_template/deleteTemplate',
    editTemplate: '/review_reply_template/editTemplate',
    getTemplates: '/review_reply_template/getTemplates',
  },
  subscription: {
    subscriptionByBpId: '/subscription/subscriptionByBpId',
    subscriptionList: '/subscription/subscriptionsList',
    startTrial: '/subscription/startTrial',
    trialStatus: '/subscription/trialStatus',
    changeInvoiceData: '/subscription/changeInvoiceData',
    cancelSubscription: '/subscription/cancelSubscription',
    deleteTrial: '/subscription/deleteTrial',
  },
  getAssetsPlace: (date, file) => `/assets/place/${date}/${file}`,
  getPlacePhoto: (apiGetPlacePhotoPath, date, fileName) => `${apiGetPlacePhotoPath}/${date}/${fileName}`,
  getPreviewMap: (fileDate, fileName) => `/map/getPreviewMap/${fileDate}/${fileName}`,
  getReportPdf: (date, fileName) => `/report/getReportPdf/${date}/${fileName}`,
};

export const adminApiRoutes = {
  auth: {
    getSession: '/acp/auth/getSession',
    login: '/acp/auth/login',
    refreshToken: '/acp/auth/refreshToken',
  },
  card: {
    addCard: '/acp/card/addCard',
    autocompleteEmail: '/acp/card/autocompleteEmail',
    cardCodeExists: '/acp/card/cardCodeExists',
    cardDelete: '/acp/card/cardDelete',
    cardExport: '/acp/card/cardExport',
    details: '/acp/card/cardDetails',
    cardLinkCodeExists: '/acp/card/cardLinkCodeExists',
    cardList: '/acp/card/cardList',
    getUserDetailsByEmail: '/acp/card/getUserDetailsByEmail',
    linkList: '/acp/card/linkList',
    linkStatistic: 'acp/card/linkStatistic',
    linkStatisticsExport: 'acp/card/linkStatisticsExport',
    updateCardDetails: '/acp/card/updateCardDetails',
    updateLink: '/acp/card/updateLink',
  },
  client: {
    autocompleteNotManager: '/acp/client/autocompleteNotManager',
    businessProfileList: '/acp/client/businessProfilesList',
    clientActiveChangeList: '/acp/client/clientActiveChangeList',
    clientDetails: '/acp/client/clientDetails',
    clientList: '/acp/client/clientList',
    getBusinessProfile: '/acp/client/getBusinessProfile',
    acpGetBusinessProfileChangeHistory: '/acp/client/getBusinessProfileChangeHistory',
    getBusinessProfileNumber: '/acp/client/getBusinessProfileNumber',
    generatePlacePhoto: '/acp/client/generatePlacePhoto',
    getClientDetails: '/acp/client/getClientDetails',
    getPlacePhoto: '/acp/client/getPlacePhoto',
    getReport: '/acp/client/getReport',
    getReportList: '/acp/client/getReportList',
    getReviews: 'acp/client/getReviews',
    getTemplates: '/acp/client/getTemplates',
    getVerificationStatus: '/acp/client/getVerificationStatus',
    listExport: '/acp/client/clientListExport',
    localPostList: '/acp/client/localPostList',
    managerList: '/acp/client/managerList',
    mediaList: '/acp/client/mediaList',
    updateClientActive: '/acp/client/updateClientActive',
    updateClientDetails: '/acp/client/updateClientDetails',
    updateUserManager: '/acp/client/updateUserManager',
  },
  invoice: {
    getInvoiceUrl: '/acp/invoice/getInvoiceUrl',
    getUserInvoiceDetails: '/acp/invoice/getUserInvoiceDetails',
  },
  payment: {
    listPayments: '/acp/payment/listPayments',
  },
  subscription: {
    addSubscription: '/acp/subscription/addSubscription',
    deleteSubscription: '/acp/subscription/deleteSubscription',
    subscriptionList: '/acp/subscription/subscriptionsList',
    deleteClientSubscription: '/acp/subscription/deleteClientSubscription',
  },
  user: {
    addUser: '/acp/user/addUser',
    changePassword: '/acp/user/changePassword',
    changePasswordByEmail: '/acp/user/changePasswordByEmail',
    listExport: '/acp/user/userListExport',
    userList: '/acp/user/userList',
    updateUser: '/acp/user/updateUser',
    sendChangePasswordEmail: '/acp/user/sendChangePasswordEmail',
  },
};
