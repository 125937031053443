import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { handleApiError } from '../ChangePassword/utils';

export const fetchInvoice = async ({ id, api, apiPath, showNotification }) => {
  try {
    const { data: invoice } = await api.post(apiPath, { paymentId: id });
    if (invoice?.viewUrl) {
      const newWindow = window.open(invoice.viewUrl, '_blank');
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        navigator.clipboard.writeText(invoice.viewUrl);
        showNotification({ message: i18next.t('invoices.popupBlocked'), type: 'warning' });
      }
    }
  } catch (error) {
    handleApiError({ err: error, t: i18next.t, showNotification });
  }
};

const renderBusinessProfile = ({ name, address }) => (
  <div className={classes.bp}>
    <div className={classes.name}>{name}</div>
    <div className={classes.address}>{address}</div>
  </div>
);

export const renderStatus = ({ value, label }) => (
  <Pill label={label} className={clsx(classes.pill, classes[value.toLowerCase()])} />
);
const renderPaymentType = (val, t) => {
  const [type, period] = t(val).split('\n');
  return (
    <div className={classes.paymentType}>
      <div className={classes.paymentType}>{type}</div>
      <div className={classes.paymentType}>{period}</div>
    </div>
  );
};

export const renderPaidDetails = ({ Icon, value, type }) => {
  if (type) {
    return (
      <div className={classes.card}>
        <Icon className={classes.paidByIcon} />
        <div className={classes.cardNumber}>{value}</div>
      </div>
    );
  }
  return '-';
};
const isSmallScreen = window.innerWidth < 1024;

export const paymentListConfig = (t) => [
  {
    key: 'orderDate',
    sort: 'order_date',
    header: 'settings.orderDate',
    render: (val) => val,
    width: isSmallScreen ? '170px' : 1,
    rowClassName: classes.cell,
    bold: true,
  },
  {
    key: 'connectedBusinessProfile',
    header: 'settings.connectedBusinessProfile',
    render: renderBusinessProfile,
    width: isSmallScreen ? '210px' : 2,

    rowClassName: classes.businessProfileInfo,
  },
  {
    key: 'orderNo',
    header: 'settings.orderNo',
    render: (val) => val,
    width: isSmallScreen ? '130px' : 0.75,
    rowClassName: classes.cell,
    bold: true,
  },
  {
    key: 'subscription',
    sort: 'subscription',
    header: 'settings.plan',
    render: (val) => val,
    width: isSmallScreen ? '90px' : 0.5,
    rowClassName: clsx(classes.subscriptionName, classes.cell),
    bold: true,
  },
  {
    key: 'paymentType',
    header: 'settings.payment',
    render: (val) => renderPaymentType(val, t),
    width: isSmallScreen ? '144px' : 1,
    rowClassName: classes.cell,
  },
  {
    key: 'amount',
    sort: 'amount',
    render: (val) => `${val / 100} PLN`,
    header: 'settings.paymentPrice',
    width: isSmallScreen ? '110px' : 0.5,
    bold: true,
    rowClassName: classes.cell,
  },
  {
    key: 'paidBy',
    render: renderPaidDetails,
    header: 'settings.paymentMethod',
    width: isSmallScreen ? '160px' : 1,
    bold: true,
  },
  {
    key: 'status',
    render: renderStatus,
    header: 'settings.status',
    width: isSmallScreen ? '240px' : 1,
  },
  {
    key: 'action',
    action: true,
    width: isSmallScreen ? '180px' : 0.75,
    render: (value) => (value?.Icon ? <div className={classes.iconWrapper}>{value.Icon}</div> : value),
    rowClassName: classes.actionCell,
  },
];
