import { ReactComponent as UsersIcon } from '../../../../shared/assets/usersGroup.svg';
import { ReactComponent as CardsIcon } from '../../../../shared/assets/cards.svg';
import { ReactComponent as AdministratorsIcon } from '../../../../shared/assets/administrators.svg';
import { ReactComponent as Moderator } from '../../../../shared/assets/briefcase.svg';
import { ROLE_ENUM } from '../../views/AdministratorDetails/utils';
import { adminRoutes } from '../../../../shared/helpers/innerRoutes';

const { administrators, cards, managers, users } = adminRoutes;

const addToCollectionSuperAdminSections = (sections, t) => {
  sections.push(
    {
      title: t('adminControlPanel.administrators'),
      path: administrators,
      Icon: AdministratorsIcon,
    },
    {
      title: t('adminControlPanel.managersNav'),
      path: managers,
      Icon: Moderator,
    },
  );
};

const addToCollectionCommonSections = (sections, t) => {
  sections.push(
    {
      title: t('adminControlPanel.navUserTitle'),
      path: users,
      Icon: UsersIcon,
    },
    {
      title: t('adminControlPanel.opinieo'),
      path: cards,
      Icon: CardsIcon,
    },
  );
};

export const generateAdminNavigationSections = (t, role) => {
  const sections = [];

  if (role === ROLE_ENUM.superAdmin) {
    addToCollectionSuperAdminSections(sections, t);
  }
  addToCollectionCommonSections(sections, t);

  return sections;
};

export const isCurrentPath = (sectionPath, locationPath) => (sectionPath === '/admin/' && locationPath === '/admin/'
  ? true
  : sectionPath !== '/admin/' && locationPath.startsWith(sectionPath));
