import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH, SMALL_MOBILE_WIDTH } from '../../../helpers/constants';
import { useAuth } from '../../../../../shared/helpers/auth';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const BusinessCardSettingsHeader = () => {
  const { t } = useTranslation();
  const { businessProfiles } = useAuth();
  const navigate = useNavigate();

  const createNewBusinessCardHandler = () => {
    navigate(seoRoutes.createBusinessProfile);
  };

  const isMobile = useIsMobile(MIN_TABLET_WIDTH);
  const pillLabel = isMobile ? businessProfiles.length : t('settings.businessCardsCount', { number: businessProfiles.length });
  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const label = isSmallMobile ? t('settings.add') : t('settings.addNew');
  return (
    <div className={classes.header}>
      <div className={classes.title}>
        {t('settings.myBusinessCards')}
        <Pill className={classes.pill} label={pillLabel} />
      </div>
      <Button
        className={classes.addNewBtn}
        label={label}
        Icon={!isSmallMobile && AddCircle}
        onClick={createNewBusinessCardHandler}
      />
    </div>
  );
};
export default BusinessCardSettingsHeader;
