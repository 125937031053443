import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classes from './styles.module.css';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const BusinessProfileWithNoAccess = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper} >
      <h3 className={classes.title}>
        {t('global.noAccessSubTitle')}
      </h3>
      <p>{t('aiConfig.noAccessSubInformation')}</p>
      <Link className={classes.link} to={seoRoutes.subscription} >{t('aiConfig.purchaseSubscription')}</Link>
    </div>
  );
};

export default BusinessProfileWithNoAccess;
