import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const TabNavigator = ({ activeTab, onTabClick, tabs }) => (
  <div className={classes.wrapper}>
    {tabs.map((tab) => (
      <div
        key={tab.label}
        className={clsx(classes.tab, activeTab === tab.label && classes.active)}
        onClick={() => onTabClick(tab)}
      >
        {tab.label}
      </div>
    ))}
  </div>
);

export default TabNavigator;
