import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import BuyerDataEmptyState from '../BuyerDataEmptyState';
import BuyerDataForm from '../BuyerDataForm';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Select from '../../../../../shared/components/Select';
import BuyerDataDetails from '../BuyerDataDetails';
import { showInvoiceDetails } from './utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const initialState = {
  name: '',
  lastName: '',
  country: '',
  state: '',
  postalCode: '',
  city: '',
  address: '',
  email: '',
  phone: '',
  defaultInvoice: false,
};

const BuyerData = ({ readOnlyButtonHandler, onChange }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [userStoredInvoiceData, setUserStoredInvoiceData] = useState([]);
  const [buyerData, setBuyerData] = useState(initialState);

  const isListDisplayed = userStoredInvoiceData.length > 1;
  const toggleForm = () => setOpenForm((prev) => !prev);

  const handleSelectStoredData = (dataId) => {
    onChange(dataId);
    const foundData = userStoredInvoiceData.find((el) => el.id === dataId);
    setBuyerData(foundData);
    setOpenForm(false);
  };

  const getUserInvoiceData = async () => {
    setIsLoading(true);
    try {
      const {
        data: { userInvoiceDetails },
      } = await api.post(apiRoutes.invoice.getDetails, {});
      setUserStoredInvoiceData(userInvoiceDetails);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInvoiceData();
  }, []);

  useEffect(() => {
    if (userStoredInvoiceData.length === 1) {
      setBuyerData(userStoredInvoiceData[0]);
      onChange(userStoredInvoiceData[0].id);
    } else {
      const foundDefault = userStoredInvoiceData.find((el) => el.defaultInvoice);
      if (foundDefault) {
        onChange(foundDefault.id);
        setBuyerData(foundDefault);
      }
    }
  }, [userStoredInvoiceData]);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}

      <div className={classes.buyerData}>
        {!openForm ? (
          <div className={classes.title}>{t('settings.buyerDataTitle')}</div>
        ) : (
          <BuyerDataForm onCancel={toggleForm} onSuccess={getUserInvoiceData} editData={null} dataExist={false} />
        )}
        {isListDisplayed && (
          <Select
            label={t('invoices.selectDataForPayment')}
            list={userStoredInvoiceData}
            showOnList={showInvoiceDetails}
            value={buyerData}
            onSelect={handleSelectStoredData}
            className={classes.select}
          />
        )}
        {!userStoredInvoiceData.length && !openForm && <BuyerDataEmptyState toggleForm={toggleForm} />}
        {!isLoading && buyerData && !openForm && (
          <BuyerDataDetails readOnlyButtonHandler={readOnlyButtonHandler} buyerData={buyerData} />
        )}
      </div>
    </div>
  );
};

export default BuyerData;
