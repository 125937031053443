import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useDashboard } from '../../helpers/dashboard';
import { useNotification } from '../../../../shared/helpers/notification';
import {
  dashboardPromisesData,
  fetchKeywordsData,
  fetchPerformanceData,
  fetchUserChangeHistory,
  parseDashboardData,
  parseDataOnPeriodChange,
} from './utils';
import Header from '../../modules/Dashboard/Header';
import Statistics from '../../modules/Dashboard/Statistics';
import ViewsChart from '../../modules/Dashboard/ViewsChart';
import BusinessCardsMetadata from '../../modules/Dashboard/BusinessCardsMetadata';
import BusinessKeywordsWithOpinions from '../../modules/Dashboard/BusinessKeywordsWithOpinions';
import { PROFILE_STATUS_ENUM } from '../../helpers/enums';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { useMultipleFetch } from '../../../../shared/helpers/hooks';
import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';

const Dashboard = ({
  businessProfileId,
  adminPreview = false,
  userId,
  businessVerificationStatus,
  businessProfile,
}) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const {
    businessProfile: { id, subscription },
  } = useDashboard();

  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartToDisplay, setChartToDisplay] = useState(t('dashboard.viewsNumber'));
  const [period, setPeriod] = useState({
    displayPeriod: t('dashboard.last30Days'),
    period: { dateFrom: null, dateTo: null },
  });
  const status = getLocalStorageProfileStatus(id || businessProfileId);

  const isNotVerified = ![PROFILE_STATUS_ENUM.verified, PROFILE_STATUS_ENUM.pending].includes(status);

  const placeId = dashboardData?.formData?.metadata?.placeId;

  const dashboardPromises = useMemo(
    () => dashboardPromisesData({
      businessProfileId: id || businessProfileId,
      businessVerificationStatus: status || businessVerificationStatus,
      adminPreview,
      subscription,
      userId,
      businessProfile,
    }),
    [id, businessProfileId, status, businessVerificationStatus, adminPreview, subscription, userId, businessProfile],
  );

  const { data: responses, loading } = useMultipleFetch(dashboardPromises);

  const onPeriodSelectHandler = async (value) => {
    const { dateFrom, dateTo } = value;
    const payload = {
      businessProfileId: id || businessProfileId,
      dateTo,
      dateFrom,
    };
    setIsLoading(true);
    try {
      const [perfomanceData, keywordsData, userChangeHistory] = await Promise.all([
        fetchPerformanceData(api, payload, userId),
        fetchKeywordsData(api, payload, userId),
        fetchUserChangeHistory(api, payload, userId),
      ]);

      const parsed = parseDataOnPeriodChange(
        {
          perfomanceData,
          keywordsData,
          userChangeHistory,
        },
        status,
      );

      const {
        userChangeHistory: historyWithPeriodChange,
        parsedStatistic,
        additionalInteractions,
        additionalSearchStatistic,
        chartData,
        interactionsChartData,
        performance,
        parsedKeywords,
      } = parsed;

      setDashboardData((prev) => ({
        ...prev,
        additionalSearchStatistic,
        userChangeHistory: historyWithPeriodChange,
        additionalInteractions,
        generalSearchData: chartData,
        statistic: { ...prev.statistic, ...parsedStatistic },
        keywordWithOpinions: { ...prev.keywordWithOpinions, ...parsedKeywords },
        performance,
        interactionsChartData,
      }));
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const onPeriodChange = async (value) => {
    const { data } = value;
    setPeriod(value);
    onPeriodSelectHandler(data);
  };

  useLayoutEffect(() => {
    if (responses && !loading) {
      const businessProfileVerificationStatus = adminPreview ? businessVerificationStatus : status;
      setDashboardData(parseDashboardData(responses, businessProfileVerificationStatus, businessProfile));
    }
  }, [responses, loading, status, businessVerificationStatus, adminPreview, businessProfile]);

  useEffect(() => {
    const shouldShowNotification = isNotVerified && !isLoading && status;
    if (shouldShowNotification) {
      showNotification({ message: t('dashboard.cannotDisplayFullData'), type: 'warning' });
    }
  }, [isNotVerified, isLoading, status]);

  if (isLoading || loading || !dashboardData) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }
  return (
    <div className={classes.wrapper}>
      <Header status={status} onPeriodSelect={onPeriodChange} period={period.displayPeriod} />
      <div className={classes.body}>
        <div className={classes.statistic}>
          <Statistics
            status={status}
            data={dashboardData.statistic}
            searchDataDetails={dashboardData.additionalSearchStatistic}
            additionalInteractions={dashboardData.additionalInteractions}
            onChartToDisplayChange={setChartToDisplay}
          />
        </div>
        <div className={classes.viewsChart}>
          <ViewsChart
            chartToDisplay={chartToDisplay}
            selectedPeriod={period.displayPeriod}
            status={status}
            data={dashboardData.generalSearchData}
            additionalInteractions={dashboardData.interactionsChartData}
            performance={dashboardData.performance}
            searchDataDetails={dashboardData.additionalSearchStatistic}
            userChangeHistory={dashboardData.userChangeHistory}
          />
        </div>
        <div className={clsx(classes.businessCardsMetadata, isNotVerified && classes.noData)}>
          <BusinessCardsMetadata
            status={status}
            data={dashboardData.formData}
            placeId={placeId}
            adminPreview={adminPreview}
          />
        </div>
        <div className={classes.businessKeywordsWithOpinions}>
          <BusinessKeywordsWithOpinions
            data={dashboardData.keywordWithOpinions}
            status={status}
            adminPreview={adminPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
