import React from 'react';
import Button from '../../../../../shared/components/Button';
import { renderBusinessProfile, renderPaymentMethod, renderPlanInformation, renderStatusPill } from '../SubscriptionSettings/utils';
import classes from './styles.module.css';

const isSmallScreen = window.innerWidth < 1024;
export const renderDate = (date) => {
  const [label, value] = date.split('\n');

  return (
    <div className={classes.dateWrapper}>
      <div className={classes.dateLabel}>{label}</div>
      <div className={classes.dateValue}>{value}</div>
    </div>
  );
};
export const subscriptionListConfig = (t) => [
  {
    key: 'subscription',
    header: 'settings.plan',
    render: (val) => renderPlanInformation(val),
    sort: 'subscription',
    width: isSmallScreen ? '100px' : 1,
  },
  {
    key: 'payment_type',
    header: 'settings.payment',
    render: (val) => renderPaymentMethod(t(val)),
    sort: 'payment_type',
    width: isSmallScreen ? '210px' : 1,
  },
  {
    key: 'connectedBusinessProfile',
    header: 'settings.connectedBusinessProfile',
    render: (val) => renderBusinessProfile(val),
    width: isSmallScreen ? '210px' : 2,
  },
  {
    key: 'status',
    header: 'settings.subscriptionStatus',
    render: (val) => renderStatusPill(val, t),
    width: isSmallScreen ? '210px' : 1,
  },
  {
    key: 'subscription_date',
    header: 'settings.date',
    render: (val) => renderDate(val),
    width: isSmallScreen ? '150px' : 0.75,
    sort: 'subscription_date',
  },
  {
    key: 'manage',
    action: true,
    render: (value) => (value ? <Button className={classes.mangeBtn} label={t('settings.manage')} /> : null),
    width: isSmallScreen ? '200px' : 0.75,
  },
];
