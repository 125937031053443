import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowResize } from '../../../../shared/helpers/hooks';
import DashboardSidebarRWDBusinessCards from '../DashboardRWDBusinessCards';
import classes from './styles.module.css';
import DashboardSidebarSections from '../DashboardSidebarSections';
import { useDashboard } from '../../helpers/dashboard';
import { MAX_TABLET_WIDTH } from '../../helpers/constants';
import SidebarHeader from '../../../../shared/components/SidebarHeader';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const { createBusinessProfile, reportList } = seoRoutes;
const DashboardSidebar = ({
  businessProfiles,
  onBusinessProfileSelect,
  showBusinessCards,
  showList,
  onMobileMenuClick,
  mobileNavVisible,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { businessProfile } = useDashboard();
  const innerWidth = useWindowResize();

  const { externalBusinessProfile } = businessProfile;
  const shouldHideSidebar = (pathname === createBusinessProfile && innerWidth > MAX_TABLET_WIDTH) || !businessProfiles.length;

  const navigateToCreateBusinessProfile = () => {
    navigate(createBusinessProfile);
    showList();
  };

  useEffect(() => {
    if (externalBusinessProfile) {
      navigate(reportList);
    }
  }, [externalBusinessProfile]);

  return (
    <div className={clsx(classes.wrapper, shouldHideSidebar && classes.hidden)}>
      <SidebarHeader
        businessProfile={businessProfile}
        onMobileMenuClick={onMobileMenuClick}
        showBusinessCards={showBusinessCards}
        showList={showList}
        mobileNavVisible={mobileNavVisible}
      />
      {showBusinessCards && (
        <DashboardSidebarRWDBusinessCards
          businessProfiles={businessProfiles}
          onBusinessProfileSelect={onBusinessProfileSelect}
          navigateToCreateBusinessProfile={navigateToCreateBusinessProfile}
        />
      )}

      <DashboardSidebarSections onMobileMenuClick={onMobileMenuClick} mobileNavVisible={mobileNavVisible} />
    </div>
  );
};

export default DashboardSidebar;
