import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import CreateCardClientDataForm from '../CreateCardClientDataForm';
import CreateCardDataForm from '../CreateCardDataForm';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { trimValues } from '../../../../../shared/helpers/helpers';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const CreateSeoCardModal = ({ onClose, onListFetch }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [form, setForm] = useState({
    email: '',
    lastName: '',
    firstName: '',
    phone: '',
    nip: '',
  });

  const isArrowVisible = Boolean(formStep);
  const isClientDataFormReadyToDisplay = formStep === 0 && !loading;
  const isCardDataFormReadyToDisplay = formStep === 1 && !loading;

  const inputChangeHandler = (key) => (value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const formStepHandler = (step) => {
    setFormStep(step);
  };

  const saveCardHandler = async ({ cardData, linkForm }) => {
    const parsedForm = trimValues({
      ...form,
      ...cardData,
      links: [...linkForm],
    });
    setLoading(true);
    try {
      await api.post(adminApiRoutes.card.addCard, parsedForm);
      await onListFetch();
      onClose();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setLoading(false);
    }
  };

  const renderLoader = () => <LoaderOverlay customStyle={classes.loaderWrapper} isAdmin />;

  const renderClientDataForm = () => (
    <CreateCardClientDataForm onInputChange={inputChangeHandler} form={form} onSave={formStepHandler} />
  );

  const renderCardDataForm = () => <CreateCardDataForm onSave={saveCardHandler} />;

  return (
    <Modal
      contentStyle={classes.modalContent}
      titleStyle={classes.titleStyle}
      onCancel={onClose}
      modalTitle={t('adminControlPanel.createCard')}
      customIconStyle={classes.customCloseStyle}
      showArrowBack={isArrowVisible}
      onBack={() => formStepHandler(formStep - 1)}
      backdropLocked
    >
      <>
        {loading && renderLoader()}
        {isClientDataFormReadyToDisplay && renderClientDataForm()}
        {isCardDataFormReadyToDisplay && renderCardDataForm()}
      </>
    </Modal>
  );
};

export default CreateSeoCardModal;
