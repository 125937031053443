import React from 'react';
import UserSeoCardList from '../../modules/UserDetails/UserSeoCardList';
import BasicDataInformation from '../../components/BasicDataDetailsView';
import ManagerBusinessCardList from '../../modules/Manager/ManagerBusinessCardList';

export const USER_DETAILS_NAVIGATION_KEY_ENUM = {
  basicData: 'basicData',
  businessCards: 'businessCards',
  seoCards: 'seoCards',
};

export const generateDetailsTabConfiguration = (t, businessProfiles, cardDataTotalNumber) => {
  const basicData = 'basicData';
  const businessCards = 'businessCards';
  const seoCards = 'seoCards';

  return [
    { label: t('adminControlPanel.basicData'), key: basicData },
    { label: t('adminControlPanel.businessCards', { value: businessProfiles }), key: businessCards },
    { label: t('adminControlPanel.SEOcards', { value: cardDataTotalNumber }), key: seoCards },
  ];
};

export const componentMap = (data, fetchData) => {
  const { basicData, businessCards, seoCards } = USER_DETAILS_NAVIGATION_KEY_ENUM;
  return {
    [basicData]: <BasicDataInformation data={data} fetchData={fetchData} isManager />,
    [businessCards]: <ManagerBusinessCardList userId={data.id} />,
    [seoCards]: <UserSeoCardList userData={data} />,
  };
};

export const calculateFullName = (userData) => (userData.name && userData.lastName ? `${userData.name} ${userData.lastName}` : userData.login);
