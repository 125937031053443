import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import mapImage from '../../../../../shared/assets/map.jpg';
import Range from '../../../../../shared/components/Range';

const renderLabel = (val) => `${val} x ${val}`;

const max = 9;
const min = 3;
const step = 2;

const parseToNumber = (value) => {
  const parsed = Number(value);
  return Number.isNaN(parsed) ? 0 : parsed;
};

const KeywordNet = ({ onChange, value, rwd }) => {
  const { t } = useTranslation();
  const [areaWidth, setAreaWidth] = useState(parseToNumber(value));

  const handleChange = (val) => {
    setAreaWidth(parseToNumber(val));
  };

  const handleBlur = (val) => {
    if (rwd) return;
    onChange(parseToNumber(val));
  };

  useEffect(() => {
    if (rwd) {
      onChange(areaWidth);
    }
  }, [areaWidth]);

  const points = useMemo(() => {
    const iterator = [...new Array(areaWidth)];
    const width = 180;
    const diameter = width / areaWidth;
    const margin = diameter * 0.1;
    return iterator.reduce((acc, el, index) => {
      const array = iterator.map((item, id) => ({
        x: diameter * id + margin,
        y: diameter * index + margin,
        diameter: diameter - 2 * margin,
      }));
      return [...acc, ...array];
    }, []);
  }, [areaWidth]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>{t('generateReport.selectGridSize')}</div>
      {!rwd && (
        <>
          <div className={classes.subInfo}>
            Lorem ipsum dolor sit amet consectetur. Malesuada tellus aliquam vel nisi porttitor vel faucibus ultricies.
            {' '}
          </div>
          <div className={classes.imageWrapper}>
            <img alt="map preview" className={classes.image} src={mapImage} />
            <div className={classes.net}>
              {points.map((el) => (
                <div
                  key={`${el.x}-${el.y}-${areaWidth}`}
                  style={{ width: `${el.diameter}px`, top: `${el.y}px`, left: `${el.x}px` }}
                  className={classes.point}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <Range
        labelClassName={classes.rangeLabel}
        step={step}
        min={min}
        max={max}
        value={areaWidth}
        onChange={handleChange}
        renderLabel={renderLabel}
        onBlur={handleBlur}

      />
    </div>
  );
};

export default KeywordNet;
