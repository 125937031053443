import React from 'react';
import UserBusinessCardList from '../../modules/UserDetails/UserBusinessCardList';
import UserSeoCardList from '../../modules/UserDetails/UserSeoCardList';
import BasicDataInformation from '../../components/BasicDataDetailsView';
import UserPaymentHistory from '../../modules/UserDetails/UserPaymentHistory';
import UserSubscriptions from '../../modules/UserDetails/UserSubscriptions';
import UserPaymentData from '../../modules/UserDetails/UserPaymentData';

export const USER_DETAILS_NAVIGATION_KEY_ENUM = {
  basicData: 'basicData',
  businessCards: 'businessCards',
  seoCards: 'seoCards',
  paymentData: 'paymentData',
  paymentHistory: 'paymentHistory',
  subscriptions: 'subscriptions',
};

export const USER_LIST_TABS = (t, businessProfiles, cardDataTotalNumber) => {
  const basicData = 'basicData';
  const businessCards = 'businessCards';
  const seoCards = 'seoCards';
  const paymentData = 'paymentData';
  const paymentHistory = 'paymentHistory';
  const subscriptions = 'subscriptions';

  return [
    { label: t('adminControlPanel.basicData'), key: basicData },
    { label: t('adminControlPanel.businessCards', { value: businessProfiles }), key: businessCards },
    { label: t('adminControlPanel.SEOcards', { value: cardDataTotalNumber }), key: seoCards },
    { label: t('adminControlPanel.paymentData'), key: paymentData },
    { label: t('adminControlPanel.paymentHistory'), key: paymentHistory },
    { label: t('adminControlPanel.subscriptions'), key: subscriptions },
  ];
};

const { basicData, businessCards, seoCards, paymentData, paymentHistory, subscriptions } = USER_DETAILS_NAVIGATION_KEY_ENUM;

export const componentMap = (userData, fetchData) => ({
  [basicData]: <BasicDataInformation data={userData} fetchData={fetchData} />,
  [businessCards]: <UserBusinessCardList userId={userData.id} />,
  [seoCards]: <UserSeoCardList userData={userData} />,
  [paymentData]: <UserPaymentData data={userData} />,
  [paymentHistory]: <UserPaymentHistory userData={userData} />,
  [subscriptions]: <UserSubscriptions userData={userData} />,
});

export const calculateFullName = (userData) => (userData.name && userData.lastName ? `${userData.name} ${userData.lastName}` : userData.login);
