import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { subscriptionListConfig } from './utils';
import Button from '../../../../../shared/components/Button';
import { paymentMethodEnum } from '../../Subscription/PaymentMethodItem/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const SubscriptionItem = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectBusinessProfile } = useDashboard();

  const onManageBtnClick = () => {
    selectBusinessProfile(item.bpId);
    navigate(seoRoutes.subscription);
  };

  const isSubscriptionRecurring = item?.paymentType === paymentMethodEnum.recurring;

  return (
    <div key={item.id} className={classes.wrapper}>
      {subscriptionListConfig(t).filter((el) => el.key !== 'manage').map((config) => (
        <div className={classes.dataItemWrapper} key={config.name}>
          {!['subscription_date', 'connectedBusinessProfile'].includes(config.key) && <p className={classes.key}>{t(config.header)}</p>}
          <div className={classes.value}>{config.render(item[config.key])}</div>
        </div>
      ))}
      {isSubscriptionRecurring && <Button className={classes.actionButton} onClick={onManageBtnClick} label={t('settings.manage')} />}
    </div>
  );
};

export default SubscriptionItem;
