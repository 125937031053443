import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.css';

const Tab = ({ key, label, isSelected, onClick, children, subTab }) => (
  <div
    key={key}
    className={clsx(classes.tab, isSelected && classes.activeTab, subTab && classes.subTab)}
    onClick={onClick}
  >
    {label}
    {children}
  </div>
);

export default Tab;
