import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import { ReactComponent as Arrow } from '../../assets/arrowAlternative.svg';
import Button from '../Button';
import { getBusinessProfileFromUrl } from './utils';
import { useIsMobile } from '../../helpers/hooks';
import { MAX_MOBILE_WIDTH } from '../../../app/SEO/helpers/constants';
import BetaSign from '../BetaSign';
import { seoRoutes } from '../../helpers/innerRoutes';

const Topbar = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const isMobileScreen = useIsMobile(MAX_MOBILE_WIDTH);
  const shouldRenderNavigateBtn = pathname === '/report';
  const btnLabel = isMobileScreen ? t('global.goBack') : t('setFilters.goToReportList');

  const goToReportlist = () => {
    navigate(seoRoutes.reportList, { state: { businessProfileId: getBusinessProfileFromUrl(search) } });
  };

  return (
    <div className={classes.wrapper}>
      <Link to={seoRoutes.dashboard}>
        <div className={classes.logoWrapper}>
          <LogoIcon className={classes.logo} />
          <BetaSign className={classes.logoSign} />
        </div>
      </Link>
      {process.env.REACT_APP_VERSION && <div className={classes.version}>{process.env.REACT_APP_VERSION}</div>}
      {shouldRenderNavigateBtn && (
        <Button
          onClick={goToReportlist}
          className={classes.reportList}
          label={btnLabel}
          Icon={Arrow}
          iconStyle={classes.arrowStyle}
        />
      )}
    </div>
  );
};

export default Topbar;
