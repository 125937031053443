import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { mobileListConfig } from './utils';
import Checkbox from '../../../../../shared/components/Checkbox';
import { actionsEnum } from '../BusinessCardsSettings/utils';

const BusinessBoxItem = ({ item, selectAllowed, isItemSelected, singleItemCheckboxClick, onActionClick }) => {
  const { t } = useTranslation();

  const onButtonClick = (action) => {
    onActionClick([action, null, item]);
  };

  return (
    <div key={item.id} className={clsx(classes.wrapper, isItemSelected && classes.selectedItem)}>
      {mobileListConfig.map((box, index) => (
        <div className={classes.dataItemWrapper} key={mobileListConfig.header}>
          <p className={classes.key}>{t(box.header)}</p>
          {Boolean(selectAllowed && index === 0) && (
            <Checkbox
              checkboxStyle={classes.checkbox}
              checkIconStyle={classes.checkIcon}
              value={isItemSelected}
              onClick={() => singleItemCheckboxClick(item.id)}
            />
          )}
          <p className={classes.value}>{box.render(item[box.key])}</p>
        </div>
      ))}
      <div className={classes.actions}>
        <Button label={t('global.delete')} className={classes.btn} onClick={() => onButtonClick(actionsEnum.deletion)}/>
        <Button label={t('global.edit')} className={classes.btn} onClick={() => onButtonClick(actionsEnum.edit)}/>
      </div>
    </div>
  );
};

export default BusinessBoxItem;
