import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import ReplyTextArea from '../ReplyTextArea';
import OpinionActions from '../OpinionActions';
import { opinionsNativeReplyActionsArray } from '../OpinionItem/utils';
import { useModal } from '../../../../../shared/helpers/hooks';
import AnswerWithAiModal from '../AnswerWithAiModal';
import { ReactComponent as PlaceHolderImage } from '../../../../../shared/assets/noImage.svg';
import { subscriptionsConfig } from '../../../helpers/constants';

const OpinionItemText = ({
  opinion,
  onEditClick,
  deleteReplyHandler,
  activeOpinionId,
  onReplyClick,
  modalHandler,
  selectedTemplate,
  textareaVisibilityHandler,
  fetchOpinions,
  replyEditMode,
  businessProfile,
  adminPreview,
  profileImage
}) => {
  const { t } = useTranslation();
  const { subscription } = businessProfile;

  const actions = opinionsNativeReplyActionsArray(onEditClick, deleteReplyHandler, t);
  const { modalHandler: aiModalHandler, showModal } = useModal();
  const shouldRenderReply = !opinion.nativeReply && !adminPreview;
  const businessProfileImage = profileImage || businessProfile.image;
  const isFreePlan = subscription === subscriptionsConfig.free;

  return (
    <div className={classes.opinionText}>
      {showModal && <AnswerWithAiModal onCancel={aiModalHandler} opinion={opinion} />}
      {opinion.nativeOpinion}
      {opinion.nativeReply && (
        <div>
          <div className={classes.opinionReply}>
            {businessProfileImage ? (
              <img src={businessProfileImage} alt="Business Profile" className={classes.image} />
            ) : (
              <PlaceHolderImage className={classes.image} />
            )}
            {opinion.nativeReply}
          </div>
          {(!adminPreview && !isFreePlan) && (
          <div className={classes.nativeReplyActions}>
            {actions.map(({ text, Icon, onClick, id }) => (
              <div key={id} onClick={() => onClick(opinion)} className={classes.replyAction}>
                <Icon />
                {text}
              </div>
            ))}
          </div>
          )}
        </div>
      )}
      {shouldRenderReply && (
        <OpinionActions
          modalHandler={modalHandler}
          onReplyClick={onReplyClick}
          opinion={opinion}
          aiModalHandler={aiModalHandler}
        />
      )}
      {activeOpinionId === opinion.id && (
        <ReplyTextArea
          businessProfile={businessProfile}
          value={selectedTemplate}
          fetchOpinions={fetchOpinions}
          opinionId={opinion.id}
          textareaVisibilityHandler={textareaVisibilityHandler}
          edit={replyEditMode}
        />
      )}
    </div>
  );
};
export default OpinionItemText;
