import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const Button = ({ className, label, readOnly, onClick, Icon, iconWidth, iconStyle, idName }) => (
  <button type="button" disabled={readOnly} className={clsx(classes.button, className)} onClick={onClick} id={idName}>
    {Icon && (
      <div className={clsx(classes.icon, iconStyle)}>
        <Icon style={!label ? { marginRight: 0 } : { width: iconWidth }} />
      </div>
    )}
    <div className={classes.label}>{label}</div>
  </button>
);
export default Button;
