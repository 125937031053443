import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ViewTitle from '../../components/ViewTitle';
import { componentMap, getTitleFromCardData, SEO_CARD_DETAIL_TABS } from './utils';
import classes from './styles.module.css';
import TabNavigator from '../../components/TabNavigator';
import { useFetchData } from '../../../../shared/helpers/hooks';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const SeoCardDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: cardData, fetchData, isLoading } = useFetchData();
  const fullName = cardData ? getTitleFromCardData(cardData) : '';
  const tabs = SEO_CARD_DETAIL_TABS(t);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };
  const fetchCardData = async (controller = new AbortController()) => {
    fetchData(adminApiRoutes.card.details, { cardId: Number(id) }, { signal: controller.signal });
  };

  useEffect(() => {
    const controller = new AbortController();
    if (id) {
      fetchCardData();
    }
    return () => {
      controller.abort();
    };
  }, [id]);

  if (isLoading) {
    return <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />;
  }
  return (
    <div className={classes.wrapper}>
      <ViewTitle withArrowBack title={fullName} />
      <TabNavigator tabs={tabs} activeTab={activeTab.label} onTabClick={tabClickHandler} />
      {componentMap(cardData, fetchCardData)[activeTab.key]}
    </div>
  );
};

export default SeoCardDetails;
