export const LEARN_MORE_ABOUT_OPINIONS = 'https://support.google.com/business/answer/3474122?hl=pl&visit_id=638222530611529628-3504288608&p=search_more_reviews&rd=1#best_practice';
export const POSITION_ABOVE_20 = '20+';
export const DEFAULT_GMAIL = 'https://mail.google.com/mail/u/0/#inbox';
export const DEFAULT_GMAIL_DOMAIN = '@gmail.com';
export const CODE_HASH = 'code_hash';
export const GOOGLE_BUSINESS_TITLE_MAX_LENGTH = 125;
export const GOOGLE_POST_URL_MAX_LENGTH = 75;
export const POLISH_PHONE_PREFIX = '+48';
export const POLISH_CURRENCY = 'PLN';
export const ERROR_LIST_ENUM = {
  BUSINESS_PROFILE_ALREADY_EXISTS: 'BUSINESS_PROFILE_ALREADY_EXISTS',
  RESET_PASSWORD_TOKEN_EXPIRED: 'RESET_PASSWORD_TOKEN_EXPIRED',
  NOT_FOUND_REQUESTED_ENTITY: 'NOT_FOUND_REQUESTED_ENTITY',
};
export const DEFAULT_ERROR_MESSAGE = 'errors.somethingWentWrong';
export const KEYWORD_MAX_LENGTH = 50;
export const SMALL_MOBILE_WIDTH = 450;
export const MAX_MOBILE_WIDTH = 600;
export const MIN_TABLET_WIDTH = 768;
export const MAX_TABLET_WIDTH = 1024;
export const GOOGLE_VERIFY_URL = 'https://business.google.com/locations?hl=pl';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_WITH_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DD_MMMM_YYYY_HOUR_FORMAT = 'DD MMMM YYYY HH:mm';
export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_FULL_MONTH = 'DD MMMM YYYY';
export const DATE_FORMAT_SHORT_YEAR = 'DD/MM/YY';
export const DATE_FORMAT_DD_MM_YYYY_HH_MM = 'DD/MM/YYYY HH:mm';
export const SEARCH_TYPES = ['dashboard.desktop', 'dashboard.desktopMap', 'dashboard.mobile', 'dashboard.mobileMap'];

export const SEARCH_KEYS = {
  'dashboard.desktop': 'businessImpressionsDesktopSearch',
  'dashboard.desktopMap': 'businessImpressionsDesktopMaps',
  'dashboard.mobile': 'businessImpressionsMobileSearch',
  'dashboard.mobileMap': 'businessImpressionsMobileMaps',
};

export const INTERACTION_TYPES = [
  'dashboard.callClicks',
  'dashboard.businessDirectionRequests',
  'dashboard.websiteClicks',
  'dashboard.dailyMetricUnknown',
  'dashboard.businessBookings',
  'dashboard.businessConversations',
  'dashboard.businessFoodMenuClicks',
  'dashboard.businessFoodOrders',
];

export const INTERACTION_KEYS = {
  'dashboard.callClicks': 'callClicks',
  'dashboard.businessDirectionRequests': 'businessDirectionRequests',
  'dashboard.websiteClicks': 'websiteClicks',
  'dashboard.dailyMetricUnknown': 'dailyMetricUnknown',
  'dashboard.businessBookings': 'businessBookings',
  'dashboard.businessConversations': 'businessConversations',
  'dashboard.businessFoodMenuClicks': 'businessFoodMenuClicks',
  'dashboard.businessFoodOrders': 'businessFoodOrders',
};

export const COUNTRIES = [
  { id: 1, name: 'Afganistan', code: 'AF' },
  { id: 2, name: 'Albania', code: 'AL' },
  { id: 3, name: 'Algieria', code: 'DZ' },
  { id: 4, name: 'Andora', code: 'AD' },
  { id: 5, name: 'Angola', code: 'AO' },
  { id: 6, name: 'Antigua i Barbuda', code: 'AG' },
  { id: 7, name: 'Arabia Saudyjska', code: 'SA' },
  { id: 8, name: 'Argentyna', code: 'AR' },
  { id: 9, name: 'Armenia', code: 'AM' },
  { id: 10, name: 'Australia', code: 'AU' },
  { id: 11, name: 'Austria', code: 'AT' },
  { id: 12, name: 'Azerbejdżan', code: 'AZ' },
  { id: 13, name: 'Bahamy', code: 'BS' },
  { id: 14, name: 'Bahrajn', code: 'BH' },
  { id: 15, name: 'Bangladesz', code: 'BD' },
  { id: 16, name: 'Barbados', code: 'BB' },
  { id: 17, name: 'Białoruś', code: 'BY' },
  { id: 18, name: 'Belgia', code: 'BE' },
  { id: 19, name: 'Belize', code: 'BZ' },
  { id: 20, name: 'Benin', code: 'BJ' },
  { id: 21, name: 'Bhutan', code: 'BT' },
  { id: 22, name: 'Boliwia', code: 'BO' },
  { id: 23, name: 'Bośnia i Hercegowina', code: 'BA' },
  { id: 24, name: 'Botswana', code: 'BW' },
  { id: 25, name: 'Brazylia', code: 'BR' },
  { id: 26, name: 'Brunei', code: 'BN' },
  { id: 27, name: 'Bułgaria', code: 'BG' },
  { id: 28, name: 'Burkina Faso', code: 'BF' },
  { id: 29, name: 'Burundi', code: 'BI' },
  { id: 30, name: 'Chile', code: 'CL' },
  { id: 31, name: 'Chiny', code: 'CN' },
  { id: 32, name: 'Chorwacja', code: 'HR' },
  { id: 33, name: 'Cypr', code: 'CY' },
  { id: 34, name: 'Czad', code: 'TD' },
  { id: 35, name: 'Czarnogóra', code: 'ME' },
  { id: 36, name: 'Czechy', code: 'CZ' },
  { id: 37, name: 'Dania', code: 'DK' },
  { id: 38, name: 'Dominika', code: 'DM' },
  { id: 39, name: 'Dominikana', code: 'DO' },
  { id: 40, name: 'Egipt', code: 'EG' },
  { id: 41, name: 'Ekwador', code: 'EC' },
  { id: 42, name: 'Erytrea', code: 'ER' },
  { id: 43, name: 'Estonia', code: 'EE' },
  { id: 44, name: 'Eswatini', code: 'SZ' },
  { id: 45, name: 'Etiopia', code: 'ET' },
  { id: 46, name: 'Fidżi', code: 'FJ' },
  { id: 47, name: 'Filipiny', code: 'PH' },
  { id: 48, name: 'Finlandia', code: 'FI' },
  { id: 49, name: 'Gabon', code: 'GA' },
  { id: 50, name: 'Gambia', code: 'GM' },
  { id: 51, name: 'Ghana', code: 'GH' },
  { id: 52, name: 'Grecja', code: 'GR' },
  { id: 53, name: 'Grenada', code: 'GD' },
  { id: 54, name: 'Gruzja', code: 'GE' },
  { id: 55, name: 'Gujana', code: 'GY' },
  { id: 56, name: 'Gwatemala', code: 'GT' },
  { id: 57, name: 'Gwinea', code: 'GN' },
  { id: 58, name: 'Gwinea Bissau', code: 'GW' },
  { id: 59, name: 'Gwinea Równikowa', code: 'GQ' },
  { id: 60, name: 'Haiti', code: 'HT' },
  { id: 61, name: 'Hiszpania', code: 'ES' },
  { id: 62, name: 'Holandia', code: 'NL' },
  { id: 63, name: 'Honduras', code: 'HN' },
  { id: 64, name: 'Indie', code: 'IN' },
  { id: 65, name: 'Indonezja', code: 'ID' },
  { id: 66, name: 'Irak', code: 'IQ' },
  { id: 67, name: 'Iran', code: 'IR' },
  { id: 68, name: 'Irlandia', code: 'IE' },
  { id: 69, name: 'Islandia', code: 'IS' },
  { id: 70, name: 'Izrael', code: 'IL' },
  { id: 71, name: 'Jamajka', code: 'JM' },
  { id: 72, name: 'Japonia', code: 'JP' },
  { id: 73, name: 'Jemen', code: 'YE' },
  { id: 74, name: 'Jordania', code: 'JO' },
  { id: 75, name: 'Kambodża', code: 'KH' },
  { id: 76, name: 'Kamerun', code: 'CM' },
  { id: 77, name: 'Kanada', code: 'CA' },
  { id: 78, name: 'Katar', code: 'QA' },
  { id: 79, name: 'Kazachstan', code: 'KZ' },
  { id: 80, name: 'Kenia', code: 'KE' },
  { id: 81, name: 'Kirgistan', code: 'KG' },
  { id: 82, name: 'Kiribati', code: 'KI' },
  { id: 83, name: 'Kolumbia', code: 'CO' },
  { id: 84, name: 'Komory', code: 'KM' },
  { id: 85, name: 'Kongo', code: 'CG' },
  { id: 86, name: 'Korea Południowa', code: 'KR' },
  { id: 87, name: 'Korea Północna', code: 'KP' },
  { id: 88, name: 'Kostaryka', code: 'CR' },
  { id: 89, name: 'Kuba', code: 'CU' },
  { id: 90, name: 'Kuwejt', code: 'KW' },
  { id: 91, name: 'Laos', code: 'LA' },
  { id: 92, name: 'Lesotho', code: 'LS' },
  { id: 93, name: 'Liban', code: 'LB' },
  { id: 94, name: 'Liberia', code: 'LR' },
  { id: 95, name: 'Libia', code: 'LY' },
  { id: 96, name: 'Liechtenstein', code: 'LI' },
  { id: 97, name: 'Litwa', code: 'LT' },
  { id: 98, name: 'Luksemburg', code: 'LU' },
  { id: 99, name: 'Łotwa', code: 'LV' },
  { id: 100, name: 'Macedonia Północna', code: 'MK' },
  { id: 101, name: 'Madagaskar', code: 'MG' },
  { id: 102, name: 'Malawi', code: 'MW' },
  { id: 103, name: 'Malediwy', code: 'MV' },
  { id: 104, name: 'Malezja', code: 'MY' },
  { id: 105, name: 'Mali', code: 'ML' },
  { id: 106, name: 'Malta', code: 'MT' },
  { id: 107, name: 'Maroko', code: 'MA' },
  { id: 108, name: 'Mauretania', code: 'MR' },
  { id: 109, name: 'Mauritius', code: 'MU' },
  { id: 110, name: 'Meksyk', code: 'MX' },
  { id: 111, name: 'Mikronezja', code: 'FM' },
  { id: 112, name: 'Mołdawia', code: 'MD' },
  { id: 113, name: 'Monako', code: 'MC' },
  { id: 114, name: 'Mongolia', code: 'MN' },
  { id: 115, name: 'Mozambik', code: 'MZ' },
  { id: 116, name: 'Namibia', code: 'NA' },
  { id: 117, name: 'Nauru', code: 'NR' },
  { id: 118, name: 'Nepal', code: 'NP' },
  { id: 119, name: 'Niemcy', code: 'DE' },
  { id: 120, name: 'Niger', code: 'NE' },
  { id: 121, name: 'Nigeria', code: 'NG' },
  { id: 122, name: 'Nikaragua', code: 'NI' },
  { id: 123, name: 'Norwegia', code: 'NO' },
  { id: 124, name: 'Nowa Zelandia', code: 'NZ' },
  { id: 125, name: 'Oman', code: 'OM' },
  { id: 126, name: 'Pakistan', code: 'PK' },
  { id: 127, name: 'Palau', code: 'PW' },
  { id: 128, name: 'Panama', code: 'PA' },
  { id: 129, name: 'Papua-Nowa Gwinea', code: 'PG' },
  { id: 130, name: 'Paragwaj', code: 'PY' },
  { id: 131, name: 'Peru', code: 'PE' },
  { id: 132, name: 'Polska', code: 'PL' },
  { id: 133, name: 'Portugalia', code: 'PT' },
  { id: 134, name: 'Republika Południowej Afryki', code: 'ZA' },
  { id: 135, name: 'Rosja', code: 'RU' },
  { id: 136, name: 'Rumunia', code: 'RO' },
  { id: 137, name: 'Rwanda', code: 'RW' },
  { id: 138, name: 'Saint Kitts i Nevis', code: 'KN' },
  { id: 139, name: 'Saint Lucia', code: 'LC' },
  { id: 140, name: 'Saint Vincent i Grenadyny', code: 'VC' },
  { id: 141, name: 'Salwador', code: 'SV' },
  { id: 142, name: 'Samoa', code: 'WS' },
  { id: 143, name: 'San Marino', code: 'SM' },
  { id: 144, name: 'Senegal', code: 'SN' },
  { id: 145, name: 'Serbia', code: 'RS' },
  { id: 146, name: 'Seszele', code: 'SC' },
  { id: 147, name: 'Sierra Leone', code: 'SL' },
  { id: 148, name: 'Singapur', code: 'SG' },
  { id: 149, name: 'Słowacja', code: 'SK' },
  { id: 150, name: 'Słowenia', code: 'SI' },
  { id: 151, name: 'Somalia', code: 'SO' },
  { id: 152, name: 'Sri Lanka', code: 'LK' },
  { id: 153, name: 'Stany Zjednoczone', code: 'US' },
  { id: 154, name: 'Sudan', code: 'SD' },
  { id: 155, name: 'Surinam', code: 'SR' },
  { id: 156, name: 'Syria', code: 'SY' },
  { id: 157, name: 'Szwajcaria', code: 'CH' },
  { id: 158, name: 'Szwecja', code: 'SE' },
  { id: 159, name: 'Tadżykistan', code: 'TJ' },
  { id: 160, name: 'Tajlandia', code: 'TH' },
  { id: 161, name: 'Tajwan', code: 'TW' },
  { id: 162, name: 'Tanzania', code: 'TZ' },
  { id: 163, name: 'Timor Wschodni', code: 'TL' },
  { id: 164, name: 'Togo', code: 'TG' },
  { id: 165, name: 'Tonga', code: 'TO' },
  { id: 166, name: 'Trynidad i Tobago', code: 'TT' },
  { id: 167, name: 'Tunezja', code: 'TN' },
  { id: 168, name: 'Turcja', code: 'TR' },
  { id: 169, name: 'Turkmenistan', code: 'TM' },
  { id: 170, name: 'Tuvalu', code: 'TV' },
  { id: 171, name: 'Uganda', code: 'UG' },
  { id: 172, name: 'Ukraina', code: 'UA' },
  { id: 173, name: 'Urugwaj', code: 'UY' },
  { id: 174, name: 'Uzbekistan', code: 'UZ' },
  { id: 175, name: 'Vanuatu', code: 'VU' },
  { id: 176, name: 'Watykan', code: 'VA' },
  { id: 177, name: 'Wenezuela', code: 'VE' },
  { id: 178, name: 'Węgry', code: 'HU' },
  { id: 179, name: 'Wielka Brytania', code: 'GB' },
  { id: 180, name: 'Wietnam', code: 'VN' },
  { id: 181, name: 'Włochy', code: 'IT' },
  { id: 182, name: 'Wybrzeże Kości Słoniowej', code: 'CI' },
  { id: 183, name: 'Wyspy Marshalla', code: 'MH' },
  { id: 184, name: 'Wyspy Salomona', code: 'SB' },
  { id: 185, name: 'Wyspy Świętego Tomasza i Książęca', code: 'ST' },
  { id: 186, name: 'Zambia', code: 'ZM' },
  { id: 187, name: 'Zimbabwe', code: 'ZW' },
  { id: 188, name: 'Francja', code: 'FR' },
];

export const customErrorMessages = {
  'category requires localization': 'errors.categoryRequiresLocalization',
  'change locations': 'errors.changeLocations',
};

export const tokenEnum = {
  refreshToken: 'refreshToken',
  token: 'token',
  adminToken: 'adminToken',
  refreshAdminToken: 'refreshAdminToken',
};

export const aiModels = ['gpt-3.5-turbo', 'gpt-4', 'gpt-4-turbo-preview'];
export const subscriptionsConfig = {
  free: 'free',
  mini: 'mini',
  basic: 'basic',
  pro: 'pro',
  trial: 'trial',
};
const { free, mini } = subscriptionsConfig;

export const readOnlySubList = [free, mini];
