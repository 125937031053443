import React, { useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NotificationContext } from '../../../../shared/helpers/notification';
import Notification from '../../../../shared/components/Notification';
import Topbar from '../../../../shared/components/Topbar';
import classes from './styles.module.css';
import { ReactComponent as Welcome } from '../../../../shared/assets/welcome.svg';
import { ReactComponent as RWDFooter } from '../../../../shared/assets/RWDFooter.svg';
import { useWindowResize } from '../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH } from '../../helpers/constants';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const { login, setPassword } = seoRoutes;
const WelcomeLayout = ({ adminLogin }) => {
  const [notificationQueue, setNotificationQueue] = useState([]);
  const { pathname } = useLocation();

  const innerWidth = useWindowResize();
  const handleClose = () => {
    setNotificationQueue((prev) => {
      const [, ...rest] = prev;
      return rest;
    });
  };

  const providerValue = useMemo(() => {
    const showNotification = (notification) => {
      setNotificationQueue((prev) => [...prev, { ...notification, key: Math.random() }]);
    };
    return { showNotification };
  }, []);
  const excludedPaths = [login, setPassword];
  const showFooter = !excludedPaths.includes(pathname) || innerWidth >= MIN_TABLET_WIDTH;

  return (
    <div className={classes.wrapper}>
      <NotificationContext.Provider value={providerValue}>
        <div className={classes.leftSide}>
          <div className={classes.topbarWrapper}>
            <Topbar />
          </div>
          <div className={classes.outletWrapper}>
            <Outlet />
          </div>
        </div>

        {!adminLogin && (
          <div className={classes.rightSide}>
            <Welcome width="100%" height="100%" className={classes.welcomeSVG} />
          </div>
        )}
        {showFooter && <RWDFooter className={classes.footer} />}

        {notificationQueue[0] && (
          <Notification
            label={notificationQueue[0].label}
            message={notificationQueue[0].message}
            type={notificationQueue[0].type}
            payload={notificationQueue[0].payload}
            key={notificationQueue[0].key}
            onClose={handleClose}
          />
        )}
      </NotificationContext.Provider>
    </div>
  );
};

export default WelcomeLayout;
