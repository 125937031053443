import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { activePillConfig } from '../../../views/AdministratorList/utils';
import Button from '../../../../../shared/components/Button';
import { USER_LIST_NAVIGATION_KEY_ENUM } from '../../../views/UserList/utils';
import DesactivateAccountModal from '../../UserDetails/DesactivateAccountModal';
import { useModal } from '../../../../../shared/helpers/hooks';
import SubscriptionToggler from '../../../components/SubscriptionToggle';

const { managers } = USER_LIST_NAVIGATION_KEY_ENUM;

const ManagerExpandedRow = ({ rowData, onListFetch, tab }) => {
  const { t } = useTranslation();

  const { businessProfiles, cardTotalNumber, id, subscriptionStatus, active } = rowData;
  const { modalHandler, showModal } = useModal();

  return (
    <>
      {showModal && <DesactivateAccountModal onClose={modalHandler} userData={rowData} fetchData={onListFetch} />}
      <tr id={classes.expandedUserRowWrapper} className={clsx(classes.wrapper, classes.expandedUserRowWrapper)}>
        <td className={classes.cell}>
          <span className={classes.label}>{t('adminControlPanel.businessCardsNumber')}</span>
          <span className={classes.value}>{businessProfiles}</span>
        </td>

        <td className={classes.cell}>
          <span className={classes.label}>{t('adminControlPanel.opinieoCardsNumber')}</span>
          <span className={classes.value}>{cardTotalNumber}</span>
        </td>
        <td className={classes.cell}>
          <span className={classes.label}>{t('adminControlPanel.subscritpionState')}</span>
          <Pill
            className={clsx(classes.pill, classes[subscriptionStatus?.toLowerCase()])}
            label={activePillConfig(subscriptionStatus, t)}
          />
        </td>
      </tr>
      {tab === managers && (
        <td className={classes.managerActions}>
          <Button
            className={classes.actionBtn}
            label={active ? t('adminControlPanel.desactivateAccount') : t('adminControlPanel.activateAccount')}
            onClick={modalHandler}
          />
          <SubscriptionToggler id={id} onSuccess={onListFetch} subscriptionStatus={subscriptionStatus} />
        </td>
      )}
    </>
  );
};

export default ManagerExpandedRow;
