import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import classes from './styles.module.css';
import UserBusinessCard from '../UserBusinessCard';
import SearchInput from '../../../../../shared/components/SearchInput';
import EmptyList from '../../../../../shared/components/EmptyList';
import AdminPagination from '../../../components/AdminPagination';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH } from '../../../views/AdministratorList/utils';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { useFetchData } from '../../../../../shared/helpers/hooks';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { LIMIT, OFFSET, SEARCH } = FILTERS_ENUM;

const UserBusinessCardList = ({ userId }) => {
  const { t } = useTranslation();
  const { fetchData, data, isLoading } = useFetchData();
  const [results, setResults] = useState(data?.results || []);
  const [total, setTotal] = useState(data?.total || 0);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, userId });

  const renderBusinessCardList = (businessProfile) => (
    <UserBusinessCard key={businessProfile.id} businessProfile={businessProfile} userId={userId} />
  );

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  useEffect(() => {
    setResults(data?.results || []);
    setTotal(data?.total || 0);
  }, [data]);

  useEffect(() => {
    const filtersWithNoNullValue = removeNullValues(filters);
    fetchData(adminApiRoutes.client.businessProfileList, { filters: filtersWithNoNullValue });
  }, [userId, filters]);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      <SearchInput
        className={classes.searchInput}
        onSearch={handleFiltersChange(SEARCH)}
        label={t('global.search')}
        value={filters.search}
      />
      {results.length ? (
        <div className={classes.listWrapper}>{results.map(renderBusinessCardList)}</div>
      ) : (
        <EmptyList title={t('adminControlPanel.noBusnessCardsToDisplay')} />
      )}
      {Boolean(results.length) && (
        <AdminPagination
          className={classes.paginationWrapper}
          total={total}
          limit={filters.limit}
          offset={filters.offset}
          onPageChange={handleFiltersChange(OFFSET)}
          onLimitChange={handleFiltersChange(LIMIT)}
          noExport
        />
      )}
    </div>
  );
};

export default UserBusinessCardList;
