import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import EmailSendInformation from '../../modules/RemindAdminPassword/EmailSendInformation';
import AdminLoader from '../../components/AdminLoader';
import RemindPasswordForm from '../../modules/RemindAdminPassword/RemindPasswordForm';
import { adminRoutes } from '../../../../shared/helpers/innerRoutes';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const RemindAdminPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [userMail, setUserMail] = useState('');
  const emailChangeHandler = (value) => {
    setUserMail(value);
  };

  const navigateToHome = () => {
    setTimeout(() => {
      navigate(adminRoutes.admin);
    }, 3000);
  };

  const submitRestetPasswordForm = async () => {
    setIsLoading(true);
    try {
      await api.post(adminApiRoutes.user.sendChangePasswordEmail, { login: userMail });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      setEmailSent(true);
      navigateToHome();
    }
  };

  if (isLoading) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      {emailSent ? (
        <EmailSendInformation />
      ) : (
        <RemindPasswordForm
          userMail={userMail}
          submitRestetPasswordForm={submitRestetPasswordForm}
          onMailChange={emailChangeHandler}
        />
      )}
    </div>
  );
};

export default RemindAdminPassword;
