import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Select from '../../../../../shared/components/Select';
import { useAuth } from '../../../../../shared/helpers/auth';
import Loader from '../../../components/Loader';
import Button from '../../../../../shared/components/Button';
import LinkInformation from '../../../../Admin/modules/SeoCardDetails/LinkInformation';
import { useModal } from '../../../../../shared/helpers/hooks';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import DesactivateCardModal from '../../../../../shared/components/DesactivateCardModal';
import DeleteCardModal from '../../../../../shared/components/DeleteCardModal';
import { getProperActivationText } from '../../../../Admin/modules/SeoCardDetails/CardInformationData/utils';
import { urlValidator } from '../../../../../shared/components/Input/validators';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const CardDetails = ({ cardData, fetchData }) => {
  const { businessProfiles } = useAuth();
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  const [clientCardDataDetails, setClientCardDataDetails] = useState({
    cardName: '',
    cardCode: '',
    bpName: '',
    createdAtCardDetails: '',
    activePill: '',
  });
  const [linkDataForm, setLinkDataForm] = useState([]);
  const { showModal: desactivateModal, modalHandler: toggleDesactivateModal } = useModal();
  const { showModal: deleteModal, modalHandler: toggleDeleteModal } = useModal();

  const cardDataExists = cardData && cardData.length > 0;
  const linkIsValid = linkDataForm.every(({ link }) => !urlValidator(link, true));
  const linkDataDifferencesCollection = cardDataExists
    ? linkDataForm.filter((link, index) => Object.entries(link)?.some(([key, value]) => value !== cardData[0].links[index][key]),)
    : [];
  const cardDataDifferencesExist = cardDataExists
    ? Object.entries(clientCardDataDetails).some(([key, value]) => value !== cardData[0][key])
    : false;

  const isReadOnlySaveBtn = (!cardDataDifferencesExist && !linkDataDifferencesCollection.length) || !linkIsValid;
  const links = clientCardDataDetails?.links;
  const { activationBtnLabel, notificationMessage } = getProperActivationText(clientCardDataDetails.active, t);

  const handleLinkFormChange = (index) => (key) => (value) => {
    setLinkDataForm((prev) => {
      const newLinks = [...prev];
      newLinks[index] = { ...newLinks[index], [key]: value };
      return newLinks;
    });
  };

  const handleChange = (name) => (value) => {
    setClientCardDataDetails((prev) => ({ ...prev, [name]: value }));
  };

  const saveCardDetailChange = async () => {
    try {
      const { id: cardId, cardName, bpId, active } = clientCardDataDetails;
      const payload = {
        cardId,
        cardName,
        businessProfileId: bpId,
        active,
      };

      if (cardDataDifferencesExist) {
        await api.post(apiRoutes.card.updateCardDetails, payload);
      }

      if (linkDataDifferencesCollection.length) {
        linkDataDifferencesCollection.forEach(async (singleLink) => {
          const { id: linkId, linkName, link } = singleLink;
          const linkPayload = {
            linkId,
            linkName,
            link,
          };
          await api.post(apiRoutes.card.updateLink, linkPayload);
        });
      }
      await fetchData();
      showNotification({ type: 'success', message: t('global.saveWithSuccess') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  const handleActiveChangeHandler = async () => {
    try {
      const { id: cardId, cardName, bpId, active } = clientCardDataDetails;
      const payload = {
        cardId,
        cardName,
        active: !active,
      };
      if (bpId) {
        payload.businessProfileId = bpId;
      }
      await api.post(apiRoutes.card.updateCardDetails, payload);
      await fetchData();
      showNotification({ type: 'success', message: notificationMessage });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  const activationBtnHandler = async () => {
    if (clientCardDataDetails.active) {
      toggleDesactivateModal();
    } else {
      await handleActiveChangeHandler();
    }
  };

  const deleteCardHandler = async () => {
    try {
      await api.post(apiRoutes.card.cardDelete, { cardId: clientCardDataDetails.id });
      showNotification({ type: 'success', message: t('seoCard.deleteCardSuccess') });
      navigate(seoRoutes.opinieoCards);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  useEffect(() => {
    if (cardData) {
      setClientCardDataDetails(cardData[0]);
      setLinkDataForm(cardData[0]?.links);
    }
  }, [cardData]);

  if (!cardData) {
    return <Loader />;
  }
  return (
    <div className={classes.wrapper}>
      {desactivateModal && (
        <DesactivateCardModal onClose={toggleDesactivateModal} client activationHandler={handleActiveChangeHandler} />
      )}
      {deleteModal && <DeleteCardModal onClose={toggleDeleteModal} client deleteCardHandler={deleteCardHandler} />}
      <div className={classes.basicInformationTitle}>
        {t('seoCard.cardInformation')}
        <Button label={t('seoCard.saveChanges')} readOnly={isReadOnlySaveBtn} onClick={saveCardDetailChange} />
      </div>
      <div className={classes.basicInformation}>
        <Input
          className={classes.input}
          label={t('seoCard.cardName')}
          value={clientCardDataDetails?.cardName}
          onChange={handleChange('cardName')}
          maxLength={50}
        />
        <Input className={classes.input} label={t('seoCard.cardID')} value={clientCardDataDetails?.cardCode} readOnly />
        <Select
          onSelect={handleChange('bpId')}
          label={t('seoCard.connectedBusinessProfile')}
          list={businessProfiles}
          value={clientCardDataDetails?.bpId}
        />
        <div>
          <div className={classes.label}>{t('seoCard.cardCreatedAt')}</div>
          <div className={classes.value}>{clientCardDataDetails?.createdAtCardDetails}</div>
        </div>
        <div>
          <div className={classes.label}>{t('seoCard.cardStatus')}</div>
          <div className={classes.value}>{cardData[0]?.activePill}</div>
        </div>
        <div className={classes.actions}>
          <Button label={activationBtnLabel} onClick={activationBtnHandler} />
          <Button label={t('seoCard.deleteCard')} onClick={toggleDeleteModal} />
        </div>
      </div>
      <div className={classes.linkInformation}>
        <div className={classes.basicInformationTitle}>
          {t('seoCard.redirectLinks', { number: cardData[0]?.links?.length })}
        </div>
        {links?.map((link, index) => (
          <LinkInformation key={link.id} link={link} onLinkFormChange={handleLinkFormChange(index)} admin={false} />
        ))}
      </div>
    </div>
  );
};

export default CardDetails;
