import React, { useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import EmptyList from '../EmptyList';
import TableHeader from '../TableHeader';
import TableRow from '../TableRow';

const Table = ({
  data,
  columns,
  editHandler,
  expandableRow,
  onSortClick,
  orderBy,
  emptyStateTitle,
  tableBodyStyle,
  className,
  onExpandRowRender,
  stickyCells,
  edit = true,
  admin = true,
}) => {
  const [expandedRowId, setExpandedRowId] = useState([]);

  const onChevronClick = (item) => {
    setExpandedRowId((prev) => {
      if (prev.includes(item.id)) {
        return prev.filter((id) => id !== item.id);
      }
      return [...prev, item.id];
    });
  };

  const sortHandler = (sort) => {
    if (!sort) {
      return;
    }
    onSortClick(sort);
  };

  return (
    <div className={clsx(classes.tableWrapper, className)}>
      {data.length ? (
        <div className={classes.tableScrollWrapper}>
          <table>
            <TableHeader
              columns={columns}
              sortHandler={sortHandler}
              orderBy={orderBy}
              expandableRow={expandableRow}
              stickyCells={stickyCells}
              edit={edit}
              admin={admin}
            />
            <tbody className={clsx(classes.tableBody, tableBodyStyle)}>
              {data.map((item, index) => (
                <TableRow
                  key={item.countedHour || item.id}
                  item={item}
                  columns={columns}
                  expandableRow={expandableRow}
                  expandedRowId={expandedRowId}
                  onChevronClick={onChevronClick}
                  editHandler={editHandler}
                  index={index}
                  onExpandRowRender={onExpandRowRender}
                  edit={edit}
                  admin={admin}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyList title={emptyStateTitle} admin={admin} />
      )}
    </div>
  );
};

export default Table;
