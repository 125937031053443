import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { config, OPEN_FOR_BUSINESS, parseBackRegularHours } from './utils';
import Radio from '../../../../../shared/components/Radio';
import Checkbox from '../../../../../shared/components/Checkbox';
import HourList from '../HourList';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import ActionBox from '../ActionBox';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const OpenHoursForm = ({
  className,
  onError,
  form,
  setForm,
  businessProfileId,
  setState,
  readOnlyAction,
  isEdit,
  editHandler,
  adminPreview,
  isReadOnlySubPlan,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [openHours, setOpenHours] = useState([]);
  const [errors, setErrors] = useState([]);
  const [openForBusinessType, setOpenForBusinessType] = useState(config[0]);
  const handleEffect = () => {
    setOpenHours(form.regularHours);
    const isAnyDayWithHours = form.regularHours.some((el) => !el.value);
    if (!isAnyDayWithHours && form.openInfo.status === OPEN_FOR_BUSINESS.open) {
      setOpenForBusinessType(config[1]);
    } else if (isAnyDayWithHours && form.openInfo.status === OPEN_FOR_BUSINESS.open) {
      setOpenForBusinessType(config[0]);
    } else {
      setOpenForBusinessType(config.find((el) => el.id === form.openInfo.status));
    }
  };

  useEffect(() => {
    handleEffect();
  }, [isEdit, form.regularHours, form.openInfo.status]);

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);

      await api.post(apiRoutes.businessProfile.updateBusinessProfileHours, {
        businessProfileId,
        openInfo: { status: openForBusinessType.value },
        regularHours: parseBackRegularHours(openHours, openForBusinessType.id),
      });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({
      ...prev,
      openInfo: { ...prev.openInfo, status: openForBusinessType.value },
      regularHours: openHours,
    }));
    editHandler();
  };
  const handleCancel = () => {
    editHandler();
  };
  const handleError = (index) => (value) => {
    setErrors((prev) => {
      const iterator = new Array(Math.max(prev.length, index + 1)).fill(false);
      return iterator.map((el, id) => {
        if (index === id) {
          return value;
        }
        return el;
      });
    });
  };

  useEffect(() => {
    onError(errors.some((el) => el));
  }, [errors]);

  const handleOpenForBusinessType = (type) => () => {
    if (isEdit) {
      setOpenForBusinessType(type);
    }
  };

  const handleCheckbox = (index) => () => {
    if (!isEdit) return;
    setOpenHours((prev) => prev.map((el, idx) => {
      if (idx === index) {
        return { ...el, value: !el.value, hours: [{ key: Math.random(), from: null, to: null }] };
      }
      return el;
    }),);
  };

  const handleDelete = (index) => (hourIndex) => {
    setOpenHours((prev) => prev.map((el, idx) => {
      if (idx === index) {
        return { ...el, hours: el.hours.filter((_, id) => id !== hourIndex) };
      }
      return el;
    }),);
  };

  const handleAdd = (index) => () => {
    setOpenHours((prev) => prev.map((el, idx) => {
      if (idx === index) {
        return { ...el, hours: [...el.hours, { key: Math.random(), from: null, to: null }] };
      }
      return el;
    }),);
  };

  const handleChange = (index) => ({ hourIndex, key, value }) => {
    setOpenHours((prev) => prev.map((el, idx) => {
      if (idx === index) {
        const currentHours = el.hours.map((item, id) => {
          if (id === hourIndex) {
            return { ...item, [key]: value };
          }
          return item;
        });
        return { ...el, hours: currentHours };
      }
      return el;
    }),);
  };
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.openTypesWrapper}>
        {config.map((el) => (
          <div key={Math.random()} className={classes.row}>
            <Radio isEdit={isEdit} value={el?.id === openForBusinessType?.id} onClick={handleOpenForBusinessType(el)} />
            <div className={classes.info}>
              <span className={classes.name}>{el.name}</span>
              <span className={classes.description}>{el.description}</span>
            </div>
          </div>
        ))}
      </div>
      {openForBusinessType.id === OPEN_FOR_BUSINESS.open && (
        <div className={classes.dayWrapper}>
          {openHours.map((el, index) => (
            <div key={`week-day-${el.id}`}>
              <div className={classes.dayName}>{el.name}</div>
              <div className={classes.form}>
                <Checkbox
                  checkboxStyle={clsx(classes.checkboxStyle, isEdit && classes.pointerEventsOn)}
                  disabled={!isEdit}
                  label={t('businessProfileEdit.closed')}
                  onClick={handleCheckbox(index)}
                  value={el.value}
                  labelPosition="right"
                  className={classes.checkbox}
                />
                {!el.value && (
                  <HourList
                    adminPreview={adminPreview}
                    isEdit={isEdit}
                    className={classes.hours}
                    hours={el.hours}
                    onAdd={handleAdd(index)}
                    onDelete={handleDelete(index)}
                    onChange={handleChange(index)}
                    onError={handleError(index)}
                    isReadOnlySubPlan={isReadOnlySubPlan}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {isEdit && (
        <ActionBox
          handleCancel={handleCancel}
          handleSave={handleSave}
          onError={onError}
          readOnlyAction={readOnlyAction}
        />
      )}
    </div>
  );
};

export default OpenHoursForm;
