import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import SettingsHeader from '../../modules/Settings/SettingsHeader';
import { PATH_ENUM, TAB_COMPONENTS } from './utils';
import { useAuth } from '../../../../shared/helpers/auth';

const Settings = () => {
  const navigate = useNavigate();
  const { businessProfiles } = useAuth();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(PATH_ENUM.PROFILE);

  const handleTabClick = useCallback(
    (path) => {
      if (path === activeTab) return;
      setActiveTab(`${path}`);
      navigate(`/dashboard${path}`);
    },
    [activeTab, navigate],
  );

  useEffect(() => {
    const tabFromPath = pathname.split('/').pop();

    if (Object.values(PATH_ENUM).includes(`/settings/${tabFromPath}`)) {
      setActiveTab(`/settings/${tabFromPath}`);
    }
  }, [pathname]);

  useEffect(() => {
    if (!businessProfiles.length && activeTab === PATH_ENUM.MY_BUSINESS_CARDS) {
      handleTabClick(PATH_ENUM.PROFILE);
    }
  }, [activeTab, businessProfiles]);

  return (
    <div className={classes.container}>
      <SettingsHeader onTabSelect={handleTabClick} activeTab={activeTab} />
      <div>{TAB_COMPONENTS[activeTab]}</div>
    </div>
  );
};

export default Settings;
