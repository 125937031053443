import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CategoryCard from '../CategoryCard';
import Modal from '../../../../../shared/components/Modal';
import { useApi } from '../../../../../shared/helpers/api';
import {
  CategoryEnum,
  ServicesTypeEnum,
  parseServices,
  parseStructured,
  parseFreeForm,
  parseAdditionalCategories,
  parseAdditionalCategoriesServices,
  parsePrimaryCategoryWithService,
  serviceDetailSetter,
  serviceDeleteSetter,
  saveServicesSetter,
  StateEnum,
} from './utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import ModalServicesForCategory from '../ModalServicesForCategory';
import ActionBox from '../ActionBox';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const ServicesForm = ({ form, businessProfileId, setState, editHandler, isEdit, adminPreview, isReadOnlySubPlan }) => {
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editCategory, setEditCategory] = useState([]);
  const [modal, setModal] = useState(false);
  const [initialCategories, setInitialCategories] = useState({});

  const onSave = async () => {
    try {
      setState(StateEnum.loading);
      if (categories) {
        const selectedPrimaryCategory = categories.primaryCategory.serviceTypes.filter((service) => Boolean(service.selected),);
        const selectedAdditionalCategories = categories.additionalCategories
          .map((category) => category.serviceTypes.filter((el) => Boolean(el.selected)))
          .flat();
        const selectedServices = [...selectedPrimaryCategory, ...selectedAdditionalCategories];
        const parsedServices = parseServices(selectedServices);
        await api.post(apiRoutes.businessProfile.updateBusinessProfileServiceItems, {
          businessProfileId,
          serviceItems: parsedServices,
        });
        showNotification({
          label: t('global.success'),
          message: t('businessProfileEdit.servicesSaved'),
          type: 'success',
        });
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setEditCategory([]);
    editHandler(null);
  };
  const handleSaveServicesToCategory = (data) => {
    setCategories((prev) => saveServicesSetter(prev, data));
  };

  const onServiceDetailChangeHandler = (service, category) => {
    setCategories((prev) => serviceDetailSetter(prev, service, category));
  };

  const onAddServiceHandler = (category) => {
    setModal(true);
    setSelectedCategory(category);
  };

  const onServiceDelete = (serviceID, categoryID) => {
    if (!isEdit) return;
    setCategories((prev) => serviceDeleteSetter(prev, serviceID, categoryID));
  };
  const categoriesReferenceIDList = useMemo(() => {
    const { primaryCategory, additionalCategories } = categories;
    const primaryCategoryReferenceID = primaryCategory?.categoryReferenceId;
    const additionalCategoriesReferenceID = additionalCategories?.map((el) => el.categoryReferenceId) || [];
    return [primaryCategoryReferenceID, ...additionalCategoriesReferenceID];
  }, [categories]);

  const categoryEditHandler = (referenceID) => {
    const categoryToEdit = categoriesReferenceIDList.find((el) => el === referenceID);
    setEditCategory((prev) => {
      if (!prev.includes(categoryToEdit)) {
        return [...prev, categoryToEdit];
      }
      return [...prev];
    });
    editHandler(t('businessProfileEdit.services'));
  };

  const cancelEditHandler = () => {
    setCategories(initialCategories);
    setEditCategory([]);
    editHandler();
  };

  useEffect(() => {
    const { primaryCategory, additionalCategories } = form.categories;
    const { serviceItems } = form;

    if (!primaryCategory) return;

    const parsedPrimaryCategory = {
      ...primaryCategory,
      serviceTypes:
        primaryCategory.serviceTypes?.map((el) => ({
          ...el,
          selected: false,
          type: ServicesTypeEnum.STRUCTURED_SERVICE_ITEM,
        })) || [],
      type: CategoryEnum.primary,
    };
    const parsedAdditionalCategories = parseAdditionalCategories(additionalCategories);
    const parsedCategoryWithNoServices = {
      primaryCategory: parsedPrimaryCategory,
      additionalCategories: parsedAdditionalCategories,
    };
    if (!serviceItems?.length) {
      setCategories(parsedCategoryWithNoServices);
      setInitialCategories(parsedCategoryWithNoServices);
    } else {
      const structured = serviceItems.filter((service) => service.structuredServiceItem);
      const freeForm = serviceItems.filter((service) => service.freeFormServiceItem);
      const parsedStructured = parseStructured(structured);
      const parsedFreeForm = parseFreeForm(freeForm);
      const parsedPrimaryCategoryWithServices = parsePrimaryCategoryWithService({
        parsedPrimaryCategory,
        parsedFreeForm,
        parsedStructured,
      });
      const additionalCategoriesWithServices = parseAdditionalCategoriesServices({
        parsedAdditionalCategories,
        parsedFreeForm,
        parsedStructured,
      });
      const parsedCategoriesWithServices = {
        primaryCategory: parsedPrimaryCategoryWithServices,
        additionalCategories: additionalCategoriesWithServices,
      };
      setCategories(parsedCategoriesWithServices);
      setInitialCategories(parsedCategoriesWithServices);
    }
  }, [form.categories]);

  return (
    <div>
      {modal && (
        <div className={classes.modalWrapper}>
          <Modal
            onCancel={() => setModal(false)}
            modalTitle={t('businessProfileEdit.manageServices')}
            contentStyle={classes.content}
            rwdBackTitle="modalMobile.goBackToBusinessProfileEdit"
          >
            <ModalServicesForCategory
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              onSave={handleSaveServicesToCategory}
              onClose={() => setModal(false)}
            />
          </Modal>
        </div>
      )}

      <div className={classes.categoriesWrapper}>
        <CategoryCard
          onServiceDetailChange={onServiceDetailChangeHandler}
          categories={categories?.primaryCategory}
          showModal={() => onAddServiceHandler(categories.primaryCategory)}
          onServiceDelete={onServiceDelete}
          onCategoryEdit={categoryEditHandler}
          categoryToEdit={editCategory}
          isEdit={isEdit}
          adminPreview={adminPreview}
          isReadOnlySubPlan={isReadOnlySubPlan}
        />
        {categories?.additionalCategories?.map((category) => (
          <CategoryCard
            key={category.categoryReferenceId}
            onServiceDetailChange={onServiceDetailChangeHandler}
            categories={category}
            showModal={() => onAddServiceHandler(category)}
            onServiceDelete={onServiceDelete}
            onCategoryEdit={categoryEditHandler}
            categoryToEdit={editCategory}
            isEdit={isEdit}
            adminPreview={adminPreview}
            isReadOnlySubPlan={isReadOnlySubPlan}
          />
        ))}
      </div>
      {isEdit && <ActionBox handleSave={onSave} handleCancel={cancelEditHandler} />}
    </div>
  );
};
export default ServicesForm;
