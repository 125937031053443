import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../shared/components/Input';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { useKeyDown } from '../../../../../shared/helpers/hooks';
import TextHelper from '../../../../../shared/components/TextHelper';
import { replaceWhitespace } from '../../../../../shared/components/Input/transformers';
import { KEYWORD_INPUT_MAX_LENGTH } from '../Questionnaire/utils';
import { ReactComponent as CloseIcon } from '../../../../../shared/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/plus.svg';

const KeywordsSection = ({ handleAddKeyword, handleDelete, value = [] }) => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');

  const isKeywordListVisible = Boolean(value.length);
  const isLimitReached = value.length === 5;
  const onKeywordAdd = () => {
    if (!keyword || value.includes(keyword)) {
      setKeyword('');
      return;
    }
    handleAddKeyword(keyword);
    setKeyword('');
  };

  useKeyDown({ key: 'Enter', callback: onKeywordAdd });

  return (
    <div className={classes.questionnaireSection}>
      <div className={classes.question}>{t('aiConfig.keywordsLabel')}</div>
      <div className={classes.keywordsWrapper}>
        <Input
          label={t('aiConfig.keywordsPlaceholder')}
          className={classes.input}
          value={keyword}
          onChange={setKeyword}
          transformer={replaceWhitespace}
          maxLength={KEYWORD_INPUT_MAX_LENGTH}
        />
        <Button
          onClick={onKeywordAdd}
          iconStyle={classes.addIconStyle}
          className={classes.addBtn}
          Icon={PlusIcon}
          readOnly={isLimitReached}
        />
      </div>
      {isLimitReached && <TextHelper message={t('aiConfig.keywordsLimitReached')} customClass={classes.limitReached} />}
      {isKeywordListVisible && (
        <div className={classes.labelsList}>
          {value.map((item) => (
            <PillWithIcon key={item} className={classes.pill}>
              {item}
              <CloseIcon onClick={() => handleDelete(item)} className={classes.closeIcon} />
            </PillWithIcon>
          ))}
        </div>
      )}
    </div>
  );
};

export default KeywordsSection;
