import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useAuth } from '../../../../shared/helpers/auth';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { ReactComponent as Notification } from '../../../../shared/assets/bell.svg';
import { ReactComponent as SEOCard } from '../../../../shared/assets/credit-card.svg';
import { ReactComponent as Chevron } from '../../../../shared/assets/chevronDown.svg';
import { useDashboard } from '../../helpers/dashboard';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import SearchInput from '../../../../shared/components/SearchInput';
import Button from '../../../../shared/components/Button';
import DashboardTopbarSubmenu from '../DashboardTopbarSubmenu';
import { useCloseOnOutsideClick, useModal, useSSENotifications } from '../../../../shared/helpers/hooks';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import BadgeComponent from '../../../../shared/components/Badge';
import NotificationFeed from '../NotificationFeed';
import { NOTIFCATION_STATUS_ENUM } from '../../views/Notifications/utils';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';
import { showOnListHandler } from './utils';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const { notViewed } = NOTIFCATION_STATUS_ENUM;
const notViewedRequestPayload = {
  filters: {
    limit: 5,
    offset: 0,
    status: [notViewed],
  },
};
const DashboardTopbar = ({ className }) => {
  const { t } = useTranslation();
  const subMenuRef = useRef(null);

  const { showNotification } = useNotification();
  const { selectBusinessProfile, businessProfile } = useDashboard();
  const { api } = useApi();
  const { businessProfiles, clearAuth } = useAuth();
  const navigate = useNavigate();
  const sseNotifications = useSSENotifications();
  const userDetailsFromLocalStorage = JSON.parse(sessionStorage.getItem('userDetails'));
  const { modalHandler: toggleNotificationFeed, setShowModal: setIsFeedOpen, showModal: isFeedOpen } = useModal();

  const [userNotification, setUserNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [userDetails, setUserDetails] = useState(userDetailsFromLocalStorage);

  const notificationNotSeen = userNotification?.filter((notification) => notification.status === notViewed).length;

  useCloseOnOutsideClick(subMenuRef, () => setShowSubMenu(false));

  const toggleSubMenu = (e) => {
    e.stopPropagation();
    setShowSubMenu((prevState) => !prevState);
  };

  const onSelectProfileHandler = (profile) => {
    selectBusinessProfile(profile.id);
  };

  const navigateToCardList = () => {
    navigate(seoRoutes.opinieoCards);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.post(apiRoutes.auth.logout, {});
      Object.keys(localStorage).forEach((key) => {
        if (!key.includes('admin')) {
          localStorage.removeItem(key);
        }
      });
      sessionStorage.clear();
      clearAuth();
    } catch (error) {
      handleApiError(error, showNotification);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const {
        data: { results },
      } = await api.post(apiRoutes.notification.notificationList, notViewedRequestPayload);

      setUserNotification((prev) => {
        const combinedNotifications = [...prev, ...results];
        const uniqueNotifications = combinedNotifications.filter(
          (notification, index, self) => index === self.findIndex((n) => n.id === notification.id),
        );
        return uniqueNotifications.sort((a, b) => b.id - a.id);
      });
    } catch (error) {
      handleApiError({ error, t, showNotification });
    }
  };
  const onCloseFeed = async () => {
    setUserNotification([]);
    setIsFeedOpen(false);
    await fetchNotifications();
  };
  useEffect(() => {
    const controller = new AbortController();

    const fetchUserDetails = async () => {
      const firstInternalBp = businessProfiles.find((bp) => !bp.externalBusinessProfile);
      setIsLoading(true);
      try {
        const { data } = await api.post(
          apiRoutes.auth.accountInfo,
          { businessProfileId: firstInternalBp.id || businessProfile.id },
          { signal: controller.signal },
        );
        sessionStorage.setItem('userDetails', JSON.stringify(data));
        setUserDetails(data);
      } catch (err) {
        handleApiError({ err, t, showNotification });
      } finally {
        setIsLoading(false);
      }
    };
    if (businessProfile.id && !userDetailsFromLocalStorage) {
      fetchUserDetails();
    }

    return () => controller.abort();
  }, [businessProfile.id, userDetailsFromLocalStorage]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (sseNotifications.length) {
      setUserNotification((prev) => {
        const combinedNotifications = [...prev, ...sseNotifications];
        const uniqueNotifications = combinedNotifications.filter(
          (notification, index, self) => index === self.findIndex((n) => n.id === notification.id),
        );
        return uniqueNotifications.sort((a, b) => b.id - a.id);
      });
    }
  }, [sseNotifications]);

  useEffect(() => {
    const controller = new AbortController();
    const markNotificationsAsViewed = async () => {
      try {
        await api.post(
          apiRoutes.notification.updateIsViewed,
          {
            status: 'VIEWED',
            ids: userNotification.slice(0, 5).map((notification) => notification.id),
          },
          { signal: controller.signal },
        );
        await fetchNotifications();
      } catch (err) {
        handleApiError({ err, t, showNotification });
      }
    };

    if (isFeedOpen && Boolean(notificationNotSeen)) {
      markNotificationsAsViewed();
    }

    return () => controller.abort();
  }, [isFeedOpen]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      <Button
        label={t('dashboard.opinieoCard')}
        Icon={SEOCard}
        className={classes.seoCardsBtn}
        onClick={navigateToCardList}
      />
      <SearchInput
        showOnList={showOnListHandler}
        onSelect={onSelectProfileHandler}
        list={businessProfiles}
        label={t('settings.searchForCard')}
        className={classes.searchInput}
        showList
      />
      <div className={classes.notificationIconWrapper}>
        <Notification onClick={toggleNotificationFeed} className={classes.icon} />
        {Boolean(userNotification.length) && (
          <BadgeComponent
            label={userNotification.length >= 5 ? '+5' : notificationNotSeen}
            className={classes.notificationBadge}
          />
        )}
        {isFeedOpen && (
          <NotificationFeed className={classes.notificationFeed} closeFeed={onCloseFeed} data={userNotification} />
        )}
      </div>
      <div className={classes.accountDetails} onClick={toggleSubMenu}>
        {userDetails?.pictureUrl ? (
          <img src={userDetails?.pictureUrl} className={classes.userPicture} alt="Business profile" />
        ) : (
          <div className={classes.userPicture} />
        )}
        <div className={classes.textWrapper}>
          <div className={classes.accountName}>{userDetails?.accountName}</div>
          <div className={classes.mail}>{userDetails?.email}</div>
        </div>
        <Chevron className={classes.chevron} />
      </div>
      {showSubMenu && (
        <div ref={subMenuRef} className={classes.subMenu}>
          <DashboardTopbarSubmenu onLogout={handleLogout} onClose={toggleSubMenu} />
        </div>
      )}
    </div>
  );
};

export default DashboardTopbar;
