import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import classes from './styles.module.css';
import PaymentDataItem from '../PaymentDataItem';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const UserPaymentData = ({ data }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [userPaymentData, setUserPaymentData] = useState(null);

  const fetchUserPaymentData = async () => {
    try {
      const {
        data: { userInvoiceDetails },
      } = await api.post(adminApiRoutes.invoice.getUserInvoiceDetails, { userId: data.id });
      setUserPaymentData(userInvoiceDetails);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  useEffect(() => {
    fetchUserPaymentData();
  }, []);

  if (!userPaymentData) return null;
  return (
    <div className={classes.userPaymentData}>
      {userPaymentData.map((paymentData) => (
        <PaymentDataItem
          className={classes.dataItem}
          data={paymentData}
        />
      ))}
    </div>
  );
};

export default UserPaymentData;
