import React from 'react';
import { useModal } from '../../../../shared/helpers/hooks';
import RecordBasicData from '../RecordBasicData';
import classes from './styles.module.css';
import DesactivateAccountModal from '../../modules/UserDetails/DesactivateAccountModal';
import AccountInformation from '../AccountInformation';

const BasicDataInformation = ({ data, isManager, fetchData }) => {
  const { modalHandler, showModal } = useModal();

  return (
    <div className={classes.wrapper}>
      {showModal && <DesactivateAccountModal onClose={modalHandler} userData={data} fetchData={fetchData} />}
      <RecordBasicData data={data} isManager={isManager} fetchData={fetchData} />
      <AccountInformation data={data} isManager={isManager} fetchData={fetchData} />
    </div>
  );
};

export default BasicDataInformation;
