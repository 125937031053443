import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import i18next from 'i18next';
import classes from './styles.module.css';
import Day from '../Day';
import { dayNames } from '../Calendar/utils';
import CalendarNavigation from '../MonthCalendarNavigation';

const Month = ({
  month = dayjs(),
  selectedStartDate,
  selectedEndDate,
  handleDayClick,
  handleMonthChange,
  isSingleMonth,
  isFirstMonth,
  futureDaysNotAvailable,
  admin,
  minDate,
  maxDate,
  messageForDateAfterMaxDate = i18next.t('global.noFutureDays'),
}) => {
  const { t } = useTranslation();
  const weekdays = useMemo(() => dayNames.map((day) => t(day)), [t]);
  const firstDayOfMonth = (month.startOf('month').day() + 6) % 7;
  const daysInMonth = month.endOf('month').date();
  const monthDays = Array(daysInMonth + firstDayOfMonth).fill(0);

  const renderMonthDay = (_, index) => {
    const day = index + 1 - firstDayOfMonth;
    if (day < 1) return <div key={Math.random()} />;
    const date = month.date(day);
    return (
      <Day
        key={day}
        date={date}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        handleDayClick={handleDayClick}
        futureDaysNotAvailable={futureDaysNotAvailable}
        minDate={minDate}
        admin={admin}
        maxDate={maxDate}
        messageForDateAfterMaxDate={messageForDateAfterMaxDate}
      />
    );
  };

  return (
    <div className={classes.month}>
      <CalendarNavigation
        isFirstMonth={isFirstMonth}
        isSingleMonth={isSingleMonth}
        periodName={month}
        period="month"
        handleMonthChange={handleMonthChange}
      />
      <div className={classes.calendarGrid}>
        {weekdays.map((day) => (
          <div key={day} className={classes.weekday}>
            {day}
          </div>
        ))}
        {monthDays.map(renderMonthDay)}
      </div>
    </div>
  );
};
export default Month;
