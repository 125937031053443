import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import HeaderWithPill from '../../../../../shared/components/HeaderWithPill';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { TAB_ENUM } from '../../../views/AddPost/utils';
import { useGetSubscriptionData, useIsMobile } from '../../../../../shared/helpers/hooks';
import { SMALL_MOBILE_WIDTH } from '../../../helpers/constants';

const MediaHeading = ({
  publishedMediaCount,
  onTabSelect,
  selectedTab,
  adminPreview,
  toggleFormModal,
  selectedItems = [],
  setSelectedItems,
  toggleConfirmationModal,
  plannedMediaCount,
}) => {
  const { t } = useTranslation();

  const { PLANNED, PUBLISHED } = TAB_ENUM;
  const isMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const { isReadOnlySubPlan } = useGetSubscriptionData();
  const isAddMediaButtonDisplayed = Boolean(publishedMediaCount) && !adminPreview && !isReadOnlySubPlan;

  const renderButton = (tab, label) => (
    <Button
      className={clsx(classes.tabBtn, selectedTab === tab && classes.activeTabStyle)}
      label={t(label)}
      onClick={() => onTabSelect(tab)}
    />
  );

  return (
    <>
      <div className={classes.header}>
        <HeaderWithPill
          className={classes.header}
          pillLabel={t('media.mediaPill', { count: publishedMediaCount })}
          title={t('media.mediaHeader')}
          total={publishedMediaCount}
        />
        {isAddMediaButtonDisplayed && (
          <Button
            onClick={toggleFormModal}
            className={clsx(classes.buttonSmall, isMobile && classes.buttonSmallMobile)}
            Icon={!isMobile && AddCircle}
            iconStyle={classes.iconStyle}
            label={t('media.addMedia')}
          />
        )}
      </div>
      <div className={classes.subHeader}>{t('media.mediaSubheader')}</div>
      {Boolean(plannedMediaCount || publishedMediaCount) && (
        <div className={classes.postType}>
          <div className={classes.tabWrapper}>
            {renderButton(PUBLISHED, 'posts.published')}
            {renderButton(PLANNED, 'posts.planned')}
          </div>
          {Boolean(selectedItems.length) && (
            <div className={classes.actionWrapper}>
              <Button onClick={() => setSelectedItems([])} className={classes.clearBtn} label={t('global.clear')} />
              <Button
                onClick={toggleConfirmationModal}
                className={classes.deleteBtn}
                label={t('media.deleteSelected')}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MediaHeading;
