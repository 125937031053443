import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../../../../shared/components/List';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { fetchInvoice, paymentListConfig } from './utils';
import classes from './styles.module.css';
import { actionEnum } from '../PaymentHistory/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH } from '../../../helpers/constants';
import PaymentHistoryItem from '../PaymentHistoryItem';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { getInvoice } = actionEnum;

const PaymentHistoryList = ({ data, onSort, sortKey, sortOrder }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const isMobileLayout = useIsMobile(MIN_TABLET_WIDTH);

  const handleListAction = useCallback(async (props) => {
    const [_, key, row] = props;
    const { redirectUrl, id } = row;
    const apiPath = apiRoutes.invoice.getUrl;
    if (key.value === getInvoice) {
      try {
        setIsLoading(true);
        await fetchInvoice({ id, api, apiPath, showNotification });
      } catch (err) {
        showNotification(err.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      window.location.href = redirectUrl;
    }
  }, []);

  const renderBoxItemListHandler = (item) => <PaymentHistoryItem item={item} />;

  if (isLoading) {
    return <LoaderOverlay />;
  }
  return (
    <div className={classes.listWrapper}>
      {isMobileLayout ? data.map(renderBoxItemListHandler) : (
        <List
          className={classes.list}
          data={data}
          config={paymentListConfig(t)}
          onAction={(...props) => handleListAction(props)}
          sortKey={sortKey}
          sortOrder={sortOrder}
          onSort={onSort}
        />
      )}
    </div>
  );
};

export default PaymentHistoryList;
