import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { parseStyle } from '../../../../../shared/components/List/utils';
import { StateEnum } from '../../../../../shared/helpers/state';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Loader from '../../../components/Loader';
import Button from '../../../../../shared/components/Button';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const RWDTable = ({ data, config, onClick }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const [state, setState] = useState(StateEnum.initializing);

  const parsedConfig = useMemo(
    () => config.map((el) => {
      const style = parseStyle(el.width, el.bold);
      if (el.key === 'download') {
        return {
          ...el,
          render: () => <Button label="Pobierz" />,
          renderHeader: typeof el.header === 'function' ? el.header : () => t(el.header),
          renderCell: typeof el.render === 'function' ? el.render : <Button label="Pobierz" />,
        };
      }

      return {
        ...el,
        renderHeader: typeof el.header === 'function' ? el.header : () => t(el.header),
        renderCell: typeof el.render === 'function' ? el.render : (val) => val,
        style,
      };
    }),
    [data, config],
  );

  const generateReport = async ({ id, controller }) => {
    const { data: result } = await api.post(
      apiRoutes.report.generateReportPdf,
      { reportId: id, businessProfileId: businessProfile.id },
      { signal: controller.signal },
    );
    const { date, fileName } = result;
    return { date, fileName };
  };

  const getReportFileAndDownload = async ({ id, date, fileName }) => {
    const { data: pdf } = await api.get(apiRoutes.getReportPdf(date, fileName), {
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });
    const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Raport_${id}`);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async (id) => {
    const controller = new AbortController();
    try {
      setState(StateEnum.loading);
      const { date, fileName } = await generateReport({ id, controller });
      await getReportFileAndDownload({ id, date, fileName });
      setState(StateEnum.success);
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    }
  };

  const handleAction = (key, value, row) => {
    if (key === 'download') {
      handleDownload(row.id);
    }
  };

  const handleCell = (cellConfig, row) => (e) => {
    if (cellConfig.action) {
      handleAction(cellConfig.key, row[cellConfig.key], row, data);
      e.stopPropagation();
    }
  };

  return (
    <div>
      {StateEnum.loading === state && (
        <div className={classes.overlay}>
          <Loader />
        </div>
      )}
      <div className={classes.row}>
        {data.map((row) => (
          <div className={classes.container} key={`listRow_${row.id}`} onClick={() => onClick(row)}>
            {parsedConfig.map((el) => (
              <div className={classes.reportCard}>
                <div key={row.key} className={classes.cell} onClick={handleCell(el, row)}>
                  <div className={classes.name}>{el.renderHeader(row[el.key], row, data)}</div>
                  <div className={classes.value}>{el.renderCell(row[el.key], row, data)}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RWDTable;
