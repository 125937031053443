import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { debounce } from '../../../../../shared/helpers/debounce';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useAuth } from '../../../../../shared/helpers/auth';
import Loader from '../../../components/Loader';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const SearchBusiness = ({ onChange }) => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useApi();
  const { setAuth } = useAuth();

  const [foundBusinessProfile, setFoundBusinessProfile] = useState('');

  const fetchNewBusinessProfile = async () => {
    try {
      const { data } = await api.post(apiRoutes.auth.getSession, {});
      const { session } = data;
      setAuth({
        isAuth: true,
        id: session.id,
        login: session.login,
        businessProfiles: session.businessProfiles,
      });
    } catch (err) {
      if (err.message !== 'canceled') {
        handleApiError({ err, showNotification, t });
      }
    }
  };

  const handleGetProfilesList = useCallback(
    debounce(async (payload) => {
      if (!payload.value) return [];
      try {
        const { data } = await api.post(apiRoutes.businessProfile.searchGoogleLocation, {
          query: payload.value,
          pageSize: 5,
        });
        return data;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      return [];
    }, 500),
    [],
  );

  const showOnListHandler = (data) => {
    if (!data) return '';
    const undefinedRegex = /undefined/g;
    if (undefinedRegex.test(data.address)) {
      return `${data.title} ${data.address.replace(undefinedRegex, '')}`;
    }
    return `${data.title} ${data.address}`;
  };

  const handleGetProfile = useCallback(
    debounce(async (payload) => {
      setIsLoading(true);
      try {
        const { data } = await api.post(apiRoutes.businessProfile.attachExternalBusinessProfile, {
          placeId: payload.placeId,
        });
        setFoundBusinessProfile(data);
        onChange(data.businessProfileId);
        showNotification({
          label: t('global.success'),
          message: t('setFilters.externalBusinessCardAdded'),
          type: 'success',
        });
        await fetchNewBusinessProfile();
        return data;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }

      return null;
    }, 500),
    [],
  );

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.overlay}>
          <Loader />
        </div>
      )}
      <div className={classes.title}>{t('setFilters.searchForCompany')}</div>
      <AsyncSelect
        value={foundBusinessProfile.title}
        onSelect={handleGetProfile}
        className={classes.asyncSelectStyle}
        onListRead={handleGetProfilesList}
        label={t('setFilters.typeNameOfCompany')}
        showOnList={showOnListHandler}
      />
      <div className={classes.horizontalLine} />
    </div>
  );
};
export default SearchBusiness;
