import FormBusinessLocation from '../../modules/BusinessProfileCreate/FormBusinessLocation';
import FormContactData from '../../modules/BusinessProfileCreate/FormContactData';
import FormCountryLocation from '../../modules/BusinessProfileCreate/FormCountryLocation';
import FormBusinessDelivery from '../../modules/BusinessProfileCreate/FormBusinessDelivery';
import FormBasicInformation from '../../modules/BusinessProfileCreate/FormBasicInformation';
import WelcomeScreenCreate from '../../modules/BusinessProfileCreate/WelcomeScreenCreate';
import FormShareLocation from '../../modules/BusinessProfileCreate/FormShareLocation';
import FormServiceArea from '../../modules/BusinessProfileCreate/FormServiceArea';
import { businessTypeEnum } from '../../modules/BusinessProfileCreate/FormShareLocation/utils';
import { formEnum } from '../../modules/BusinessProfileCreate/WelcomeScreenCreate/utils';
import { DEFAULT_ERROR_MESSAGE, POLISH_PHONE_PREFIX, customErrorMessages } from '../../helpers/constants';
import VerificationView from '../Verification';

export const ERROR_ENUM = {
  categoryRequiresLocalization: 'category requires localization',
};

export const initialFormData = {
  title: '',
  categories: { primaryCategory: { name: '', categoryReferenceId: '' } },
  languageCode: '',
  serviceArea: {
    businessType: '',
    regionCode: '',
    places: {
      placeInfos: [],
    },
  },
  phoneNumbers: {
    primaryPhone: '',
  },
  storefrontAddress: {
    postalCode: '',
    addressLines: [],
    locality: '',
    regionCode: '',
  },
  latlng: {
    latitude: null,
    longitude: null,
  },
};

export const changeFormDataHandler = ({ name, value, setter }) => {
  setter((prev) => {
    const updatedData = (() => {
      switch (name) {
        case formEnum.regionCode: {
          return {
            ...prev,
            serviceArea: { ...prev.serviceArea, regionCode: value },
          };
        }
        case formEnum.phoneNumbers: {
          return {
            ...prev,
            phoneNumbers: { primaryPhone: `${POLISH_PHONE_PREFIX}${value}` },
          };
        }
        case formEnum.categories: {
          return {
            ...prev,
            categories: { primaryCategory: { categoryReferenceId: value?.categoryReferenceId, name: value?.name } },
          };
        }
        case formEnum.shareLocation: {
          return {
            ...prev,
            serviceArea: { ...prev.serviceArea, businessType: value },
          };
        }
        case formEnum.serviceArea: {
          return {
            ...prev,
            serviceArea: { ...prev.serviceArea, places: { placeInfos: value } },
          };
        }
        case formEnum.latlng: {
          return {
            ...prev,
            latlng: {
              latitude: Number(value.lat),
              longitude: Number(value.lng),
            },
          };
        }
        default: {
          return { ...prev, [name]: value };
        }
      }
    })();

    return updatedData;
  });
};

export const STEPPER_ENUM = {
  welcome: 'createBusinessProfileWelcome',
  basicInformation: 'basicInformation',
  locationAndAreaForm: 'locationAndAreaForm',
  locationForm: 'locationFormCreateBusiness',
  deliveryForm: 'deliveryForm',
  serviceAreaForm: 'serviceAreaForm',
  contactDataForm: 'contactDataForm',
  countryForm: 'countryForm',
  verification: 'verification',
};

export const stepsOrderHandler = ({ metadata, pickedAreas, businessCreated }) => {
  const userWantToShareLocation = metadata.shareLocation;
  const userWantToShareDeliveryArea = metadata.deliveryArea;
  const userServesInMultipleCountries = metadata.multipleCountries;
  const businessLocationOnly = userWantToShareLocation && !userWantToShareDeliveryArea;
  const userLocationNotClear = !userWantToShareLocation && (!pickedAreas.length || userServesInMultipleCountries);

  return [
    {
      name: STEPPER_ENUM.welcome,
      Form: WelcomeScreenCreate,
      next: STEPPER_ENUM.basicInformation,
      stepperValue: 0,
    },
    {
      name: STEPPER_ENUM.basicInformation,
      Form: FormBasicInformation,
      next: STEPPER_ENUM.locationAndAreaForm,
      previous: STEPPER_ENUM.welcome,
      stepperValue: 1,
    },
    {
      name: STEPPER_ENUM.locationAndAreaForm,
      Form: FormShareLocation,
      next: userWantToShareLocation ? STEPPER_ENUM.locationForm : STEPPER_ENUM.serviceAreaForm,
      previous: STEPPER_ENUM.basicInformation,
      stepperValue: 2,
    },
    {
      name: STEPPER_ENUM.deliveryForm,
      Form: FormBusinessDelivery,
      next: userWantToShareDeliveryArea ? STEPPER_ENUM.serviceAreaForm : STEPPER_ENUM.contactDataForm,
      previous: userWantToShareLocation ? STEPPER_ENUM.locationForm : STEPPER_ENUM.locationAndAreaForm,
      stepperValue: 2,
    },
    {
      name: STEPPER_ENUM.locationForm,
      next: STEPPER_ENUM.deliveryForm,
      previous: STEPPER_ENUM.locationAndAreaForm,
      Form: FormBusinessLocation,
      stepperValue: 2,
    },
    {
      name: STEPPER_ENUM.serviceAreaForm,
      next: userLocationNotClear ? STEPPER_ENUM.countryForm : STEPPER_ENUM.contactDataForm,
      previous: !userWantToShareLocation ? STEPPER_ENUM.locationAndAreaForm : STEPPER_ENUM.deliveryForm,
      Form: FormServiceArea,
      stepperValue: 2,
    },
    {
      name: STEPPER_ENUM.countryForm,
      Form: FormCountryLocation,
      next: STEPPER_ENUM.contactDataForm,
      previous: STEPPER_ENUM.serviceAreaForm,
      stepperValue: 2,
    },
    {
      name: STEPPER_ENUM.contactDataForm,
      Form: FormContactData,
      previous: businessLocationOnly ? STEPPER_ENUM.deliveryForm : STEPPER_ENUM.serviceAreaForm,
      next: businessCreated ? STEPPER_ENUM.verification : null,
      stepperValue: 3,
    },
    {
      name: STEPPER_ENUM.verification,
      Form: VerificationView,
      next: null,
      previous: null,
      stepperValue: 4,
    },
  ];
};

export const getNavigationText = (activeStepName) => {
  const firstStep = activeStepName === STEPPER_ENUM.welcome;
  return firstStep ? 'businessProfileCreate.goBackToDashboard' : 'businessProfileCreate.goBackOneStep';
};

export const scrollOnFocus = (event) => {
  const element = event?.target;
  setTimeout(() => {
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
};

export const parseCreateBusinessForm = ({ createBusinessForm }) => {
  const parsedData = { ...createBusinessForm };
  const { serviceArea, title } = parsedData;
  const parsedTitle = title.trim();
  if (serviceArea.businessType === businessTypeEnum.CUSTOMER_LOCATION_ONLY) {
    const parsedDataWithoutStoreFront = { ...parsedData };
    delete parsedDataWithoutStoreFront.storefrontAddress;
    delete parsedDataWithoutStoreFront.latlng;
    parsedDataWithoutStoreFront.title = parsedTitle;
    return parsedDataWithoutStoreFront;
  }

  parsedData.title = parsedTitle;
  if (!parsedData.latlng.latitude || !parsedData.latlng.longitude) {
    delete parsedData.latlng;
  }

  return parsedData;
};

export const handleApiErrors = ({ error, setActiveStep }) => {
  const errorMessage = customErrorMessages?.[error.response?.data?.message] || DEFAULT_ERROR_MESSAGE;
  if (error.response && customErrorMessages?.[error.response.data.message]) {
    setActiveStep(STEPPER_ENUM.basicInformation);
  }
  return errorMessage;
};

export const createCurrentState = ({ currentStepName }) => {
  const createIsNotFinished = currentStepName !== STEPPER_ENUM.verification;
  const createInitialized = currentStepName !== STEPPER_ENUM.welcome;

  const createInProgress = createInitialized && createIsNotFinished;

  return { createIsNotFinished, createInitialized, createInProgress };
};
