import i18next from 'i18next';

export const loadPayUScript = () => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = process.env.REACT_APP_PAYU_LINK;
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => resolve();
  script.onerror = () => reject(new Error('Failed to load PayU SDK'));
  document.body.appendChild(script);
});

export const optionsForms = {
  style: {
    basic: {
      fontSize: '16px',
      fontFamily: 'Poppins, sans-serif',
      color: '#000',
    },
    placeholder: {
      color: '#000',
    },
  },
  placeholder: {
    number: i18next.t('subscription.cardNumber'),
    date: i18next.t('subscription.cardExpirationDate'),
    cvv: 'CVV',
  },
  lang: 'pl',
};

export const getErrorMessages = (obj) => Object.values(obj)
  .flatMap((error) => error)
  .map((error) => error?.message)
  .filter((message) => message !== undefined);
