import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

import Input from '../../../../shared/components/Input';
import Button from '../../../../shared/components/Button';
import Checkbox from '../../../../shared/components/Checkbox';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import DualActionButtons from '../../components/DualActionButtons';

import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { useKeyDown } from '../../../../shared/helpers/hooks';
import { emailValidator } from '../../../../shared/components/Input/validators';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';

import { businessTypeParser, saveClientTokensLocalStorage } from './utils';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const { remindPassword, dashboard } = seoRoutes;
const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { setAuth } = useAuth();
  const { setTokens, api } = useApi();
  const [inputErrors, setInputErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({ login: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  const readOnly = Object.values(form).some((value) => !value) || inputErrors || isLoading;

  const handleForm = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const navigateHandler = (path = -1) => {
    navigate(path);
  };

  const remindPasswordNavigateHandler = () => {
    navigateHandler(remindPassword);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(apiRoutes.auth.login, { ...form, login: form.login.toLowerCase() });
      const { token, refreshToken, session } = data;
      const { id, login, businessProfiles, subscriptions, isManager } = session;
      setTokens({ token, refreshToken });
      const profileWithBusinessType = businessTypeParser(businessProfiles, subscriptions);
      setAuth({ isAuth: true, id, login, businessProfiles: profileWithBusinessType, isAdmin: false, isManager });
      saveClientTokensLocalStorage({ token, refreshToken });
      navigateHandler(dashboard);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const loginBtnConfig = {
    label: t('login.signIn'),
    disabled: readOnly,
    onClick: handleLogin,
    className: classes.signInButton,
  };

  const negativeBtnConfig = {
    label: t('login.goBack'),
    onClick: () => navigateHandler(),
    className: classes.goBack,
  };

  useKeyDown({ key: 'Enter', callback: handleLogin, canInvoke: !readOnly });

  const handleInputError = (value) => {
    setInputErrors(Boolean(value));
  };

  return (
    <main className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loader} />}
      <section className={classes.form}>
        <h1 className={classes.text}>{t('login.signIn')}</h1>
        <form>
          <div className={classes.inputWrapper}>
            <Input
              className={classes.input}
              label={t('login.login')}
              value={form.login}
              onChange={handleForm('login')}
              onError={handleInputError}
              showErrorAfterBlur
              validator={emailValidator}
              transformer={(v) => v.toLowerCase()}
              autoCompleteName="email"
            />
            <p className={classes.subInfoBox}>{t('login.subInfo')}</p>
            <Input
              className={classes.input}
              label={t('login.password')}
              value={form.password}
              onChange={handleForm('password')}
              showErrorAfterBlur
              type={showPassword ? 'text' : 'password'}
            />
          </div>
          <Button
            className={classes.remindPassword}
            label={t('login.remindPassword')}
            onClick={remindPasswordNavigateHandler}
          />
          <Checkbox
            className={classes.checkbox}
            checkIconStyle={classes.checkIconStyle}
            label={t('login.showPassword')}
            value={showPassword}
            onClick={() => setShowPassword((prev) => !prev)}
            checkboxStyle={classes.checkboxStyle}
            labelPosition="right"
          />
          <DualActionButtons
            containerClassName={classes.actionBoxContainer}
            positiveButton={loginBtnConfig}
            negativeButton={negativeBtnConfig}
          />
        </form>
      </section>
    </main>
  );
};

export default Login;
