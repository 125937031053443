import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../../../shared/helpers/debounce';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const ServiceAreaSelect = ({ className, onAreaChange, isEdit, value, areas }) => {
  const uuid = useRef(Math.random().toString(36));
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { t } = useTranslation();

  const handleGetPlaceList = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        return [];
      }
      onAreaChange(null);

      try {
        const { data } = await api.post(apiRoutes.place.getServiceAreaAutocompleteList, {
          input: payload.value,
          sessionId: uuid.current,
        });
        const filteredData = data.filter((el) => !areas.some((area) => area.placeId === el.placeReferenceId));
        return filteredData.map((el) => ({ placeName: el.name, placeId: el.placeReferenceId }));
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }

      return [];
    }, 500),
    [areas],
  );

  const handleGetPlace = useCallback(
    debounce(async (payload) => {
      try {
        const payloadData = typeof payload.value === 'string'
          ? { placeReferenceId: payload.value }
          : { placeReferenceId: payload.value.placeId };
        const { data } = await api.post(apiRoutes.place.getPlaceNameByPlaceReferenceId, payloadData);
        return data;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      return null;
    }, 500),
    [],
  );

  return (
    <AsyncSelect
      className={className}
      onSelect={onAreaChange}
      label={t('businessProfileEdit.searchArea')}
      readOnly={!isEdit}
      value={value || ''}
      onElementGet={handleGetPlace}
      onListRead={handleGetPlaceList}
      showOnList={(el) => (el ? el.placeName : '')}
      withChevron
    />
  );
};

export default ServiceAreaSelect;
