import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Checkbox from '../../../../shared/components/Checkbox';
import Button from '../../../../shared/components/Button';
import { ReactComponent as Chevron } from '../../../../shared/assets/chevronDown.svg';
import { notificationDataFeedParser } from './utils';
import { useAuth } from '../../../../shared/helpers/auth';
import { useCloseOnOutsideClick } from '../../../../shared/helpers/hooks';
import NotificationFeedItem from '../NotificationFeedItem';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const NotificationFeed = ({ className, closeFeed, data = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessProfiles } = useAuth();
  const feedRef = useRef(null);

  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const dataToDisplay = notificationDataFeedParser({ notifications: data.slice(0, 5), list: businessProfiles });

  const selectAllHandler = () => {
    setSelectedNotifications((prev) => (prev.length ? [] : dataToDisplay.map((notification) => notification.id)));
  };

  const selectSingleItem = (id) => {
    setSelectedNotifications((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const navigateNotifications = () => {
    navigate(seoRoutes.notifications);
    closeFeed();
  };

  useCloseOnOutsideClick(feedRef, closeFeed);

  return (
    <div ref={feedRef} className={clsx(classes.wrapper, className)}>
      <div className={classes.notificationTopbar}>
        {t('notifications.title')}
        {Boolean(dataToDisplay.length) && (
          <Checkbox
            className={classes.checkbox}
            label={t('global.selectAll')}
            checkboxStyle={classes.checkboxStyle}
            checkIconStyle={classes.checkIconStyle}
            labelPosition="right"
            onClick={selectAllHandler}
            value={selectedNotifications.length === dataToDisplay.length}
          />
        )}
      </div>
      {dataToDisplay.length ? (
        dataToDisplay.map((notification) => (
          <NotificationFeedItem
            notification={notification}
            selectedItems={selectedNotifications}
            onItemSelect={selectSingleItem}
          />
        ))
      ) : (
        <p className={classes.noData}>{t('notifications.noNotifications')}</p>
      )}
      <Button
        Icon={Chevron}
        iconStyle={classes.btnIcon}
        className={classes.showAllBtn}
        label="Zobacz wszystkie"
        onClick={navigateNotifications}
      />
    </div>
  );
};

export default NotificationFeed;
