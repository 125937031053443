import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { subscriptionsConfig } from '../../../helpers/constants';
import { ReactComponent as PlanLogoIcon } from '../../../../../shared/assets/plan-logo.svg';
import { ReactComponent as CheckCircle } from '../../../../../shared/assets/checkCircle.svg';

const UserData = ({ businessProfile }) => {
  const { t } = useTranslation();
  const isFreeSubscription = businessProfile?.subscription === subscriptionsConfig.free;

  return (
    <div className={classes.userData}>
      <div className={classes.leftContainer}>
        <div className={classes.iconWrapper}>
          <PlanLogoIcon className={classes.icon} />
        </div>
        <div className={classes.plan}>
          <div className={classes.type}>
            <div className={classes.typeText}>{t('aiConfig.yourPlan')}</div>
            <div className={classes.typeIcon}>
              {businessProfile?.subscription}
            </div>
          </div>
          {!isFreeSubscription && (
          <div className={classes.statusWrapper}>
            <div className={classes.statusIconWrapper}>
              <CheckCircle className={classes.statusIcon} />
            </div>
            <span className={classes.statusText}>
              {t('subscription.activePlanStatus')}
            </span>
          </div>
          )}
        </div>
      </div>
      <div className={classes.rightContainer}>
        <span className={classes.title}>{businessProfile?.name}</span>
        <span className={classes.address}>{businessProfile?.address}</span>
      </div>
    </div>
  );
};
export default UserData;
