import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { useModal } from '../../helpers/hooks';
import LinkStatisticModal from '../../../app/Admin/modules/SeoCardDetails/LinkStatisticModal';
import { ReactComponent as CircleInfo } from '../../assets/circleInfo.svg';

const CardSingleLinkWrapper = ({ link, cardData, admin = true }) => {
  const { t } = useTranslation();
  const { showModal, modalHandler } = useModal();
  const parsedLinkLastUsed = link.lastUsed ? dayjs(link.lastUsed).format('DD.MM.YY') : '-';

  return (
    <div className={clsx(classes.linkWrapper, !admin && classes.clientLinkWrapper)}>
      {showModal && <LinkStatisticModal onClose={modalHandler} link={link} admin={admin} cardData={cardData} />}
      <div className={classes.leftSide}>
        <div className={classes.linkName}>{link.linkName}</div>
        <div className={classes.linkCode}>{link.linkCode}</div>
        <div className={classes.link}>{link.link}</div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.columnWrapper}>
          <div className={classes.lastUsedTitle}>{t('adminControlPanel.lastInteraction')}</div>
          <div className={classes.lastUsedValue}>{parsedLinkLastUsed}</div>
        </div>
        <div className={classes.columnWrapper}>
          <div className={classes.lastUsedTitle}>
            {t('adminControlPanel.redirectAmount')}
            <Tooltip text={t('adminControlPanel.tooltipLinkStatisticStyle')} tooltipStyle={classes.tooltipStyle}>
              <CircleInfo className={classes.circleInfoIcon} />
            </Tooltip>
          </div>
          <div className={classes.lastUsedValue}>{link.linkInteractions || '-'}</div>
        </div>
        <div className={classes.btnWrapper}>
          <Button
            label={t('adminControlPanel.showStatistics')}
            className={clsx(classes.statisticBtn, !admin && classes.clientBtn)}
            onClick={modalHandler}
            readOnly={!link.linkInteractions}
          />
        </div>
      </div>
    </div>
  );
};

export default CardSingleLinkWrapper;
