import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import Button from '../../../../../shared/components/Button';
import { handleApiError } from '../ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useModal } from '../../../../../shared/helpers/hooks';
import ConfirmDefaultInvoice from '../ConfirmDefaultInvoice';
import InvoiceModalForm from '../InvoiceModalForm';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { ReactComponent as Edit } from '../../../../../shared/assets/edit.svg';
import { ReactComponent as Bin } from '../../../../../shared/assets/bin.svg';
import Tooltip from '../../../../../shared/components/Tooltip';
import RelatedSubscriptions from '../RelatedSubscriptions';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const InvoiceDataItem = ({ invoiceData, onSuccess, itemsLength }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { showModal: isOpenMakeDefaultModal, modalHandler: toggleMakeDefaultInvoiceModal } = useModal();
  const { showModal: isOpenEditModal, modalHandler: toggleEditModal } = useModal();
  const {
    name,
    lastName,
    email,
    secondEmail,
    country,
    address,
    city,
    postalCode,
    defaultInvoice,
    nip,
    companyName,
    phone = '',
    id,
    relatedSubscriptions,
  } = invoiceData;
  const isPersonal = !nip && !companyName;
  const isRelatedDataWithReccurringSubscription = Boolean(relatedSubscriptions?.length);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await api.post(apiRoutes.invoice.delete, { id });
      onSuccess();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={clsx(classes.wrapper, defaultInvoice && classes.whiteBg)}>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      {isOpenMakeDefaultModal && (
        <ConfirmDefaultInvoice onClose={toggleMakeDefaultInvoiceModal} data={invoiceData} onSuccess={onSuccess} />
      )}
      {isOpenEditModal && (
        <InvoiceModalForm
          onClose={toggleEditModal}
          onSuccess={onSuccess}
          editData={invoiceData}
          dataExist={itemsLength}
        />
      )}
      <div className={classes.row}>
        <div>
          {isPersonal ? (
            <div className={classes.invoiceTitle}>{`${name} ${lastName}`}</div>
          ) : (
            <div className={classes.invoiceTitle}>
              {`${companyName} `}
              <div>{`NIP ${nip}`}</div>
            </div>
          )}

          <div className={classes.address}>
            <div>{country}</div>
            <div>{`${postalCode} ${city}`}</div>
            <div>{address}</div>
          </div>
          <div className={classes.contactData}>
            <div>{email}</div>
            <div>{secondEmail}</div>
            <div>{phone}</div>
            <Checkbox
              checkboxStyle={clsx(classes.checkboxStyle, defaultInvoice && classes.defaultInvoiceCheckbox)}
              checkIconStyle={classes.checkIconStyle}
              className={clsx(classes.checkbox)}
              label={t(`invoices.${defaultInvoice ? 'defaultInvoice' : 'setAsDefaultInvoiceData'}`)}
              labelPosition="right"
              onClick={!defaultInvoice && toggleMakeDefaultInvoiceModal}
              value={defaultInvoice || isOpenMakeDefaultModal}
              disabled={defaultInvoice}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button
            className={classes.editBtn}
            label={t('global.edit')}
            iconWidth={16}
            Icon={Edit}
            onClick={toggleEditModal}
          />
          {relatedSubscriptions?.length ? (
            <Tooltip
              text="Nie można usunąć adresu, który jest powiązany z płatnością za subskrypcję"
              className={classes.toolWrapper}
              tooltipStyle={classes.categoryTooltipStyle}
            >
              <Button
                className={classes.deleteBtn}
                label={t('global.delete')}
                iconWidth={16}
                Icon={Bin}
                readOnly={relatedSubscriptions.length}
                onClick={handleDelete}
              />
            </Tooltip>
          ) : (
            <Button
              className={classes.deleteBtn}
              label={t('global.delete')}
              iconWidth={16}
              Icon={Bin}
              readOnly={relatedSubscriptions?.length}
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
      {isRelatedDataWithReccurringSubscription && <RelatedSubscriptions data={relatedSubscriptions} />}
    </div>
  );
};

export default InvoiceDataItem;
