import React from 'react';
import clsx from 'clsx';
import i18next from 'i18next';
import { handleApiError } from '../ChangePassword/utils';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/edit.svg';
import { ReactComponent as BinIcon } from '../../../../../shared/assets/bin.svg';
import Pill from '../../../../../shared/components/Pill';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

export const fetchNewData = async ({ api, showNotification, t }) => {
  const controller = new AbortController();
  try {
    await api.post(apiRoutes.account.getAccountDetails, {}, { controller: controller.signal });
  } catch (err) {
    handleApiError({ err, showNotification, t });
  }
  return () => {
    controller.abort();
  };
};

export const searchHandler = (filtredValue, setImportedProfiles, businessProfiles) => {
  if (!filtredValue.length) {
    setImportedProfiles(businessProfiles);
  } else {
    setImportedProfiles(filtredValue);
  }
};

export const onSelectFilterHandler = (
  value,
  selectList,
  setImportedProfiles,
  businessProfiles,
  setSelectFilterValue,
) => {
  const foundSelect = selectList.find((item) => item.id === value);
  setSelectFilterValue(value);
  if (foundSelect.value === 'all') {
    setImportedProfiles(businessProfiles);
    return;
  }
  setImportedProfiles(businessProfiles.filter((item) => item.status?.id === foundSelect.value));
};

export const checkboxClickHandler = (
  id,
  selectedBusinessProfiles,
  setSelectedBusinessProfiles,
  selectAllowed,
  toggleSelectAll,
) => {
  if (selectedBusinessProfiles.includes(id)) {
    setSelectedBusinessProfiles((prev) => {
      const updatedProfiles = prev.filter((item) => item !== id);
      if (updatedProfiles.length === 0) {
        toggleSelectAll();
      }
      return updatedProfiles;
    });
  } else {
    setSelectedBusinessProfiles((prev) => [...prev, id]);
    if (!selectAllowed) {
      toggleSelectAll();
    }
  }
};

export const paginationLimitList = [5, 10, 15];
export const actionsEnum = {
  deletion: 'deletion',
  edit: 'edit',
};
export const businessProfileListconfig = ({
  isSelectAllowed,
  selectAll,
  selectedItems,
  singleItemCheckboxClick,
  totalProfilesLength,
}) => {
  const data = [
    {
      key: 'id',
      render: (val) => (
        <Checkbox
          checkboxStyle={classes.checkbox}
          checkIconStyle={classes.checkIcon}
          value={selectedItems.includes(val)}
          onClick={() => singleItemCheckboxClick(val)}
        />
      ),
      width: '50px',
      action: true,
      header: () => (
        <Checkbox
          value={selectedItems.length === totalProfilesLength}
          checkboxStyle={classes.checkbox}
          checkIconStyle={classes.checkIcon}
          onClick={selectAll}
        />
      ),
    },
    {
      key: 'name',
      header: 'settings.businessCard',
      render: (val) => <div className={classes.businessCard}>{val}</div>,
      width: 0.75,
    },
    {
      key: 'businessCardStatus',
      header: 'settings.businessCardStatus',
      render: (val) => <Pill label={i18next.t(`settings.${val}`)} className={clsx(classes.pill, classes[val])} />,
      width: 0.5,
    },
    {
      key: 'subscription',
      header: 'settings.plan',
      render: (val) => <div className={classes.cell}>{val}</div>,
      width: 0.25,
    },
    {
      key: 'opinieoCount',
      header: 'settings.opinieoCards',
      render: (val) => <div className={classes.cell}>{val}</div>,
      width: 0.25,
    },
    {
      key: 'edit',
      action: true,
      render: () => (
        <div className={classes.iconWrapper}>
          <EditIcon className={classes.icon} />
        </div>
      ),
      header: '',
      width: 0.1,
    },
    {
      key: 'deletion',
      action: true,
      render: () => (
        <div className={classes.iconWrapper}>
          <BinIcon className={classes.icon} />
        </div>
      ),
      header: '',
      width: 0.1,
    },
  ];

  if (!isSelectAllowed) {
    data.shift();
  }
  return data;
};
