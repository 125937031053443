import React, { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import FormSuccessIndicator from '../../../../shared/components/FormSuccessIndicator';
import {
  SectionEnum,
  businessProfileDataParser,
  checkFormStatus,
  checkStatus,
  createFormSectionList,
  createFormSectionListWithFilledStatus,
  initialFormState,
  sections,
} from './utils';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import { StateEnum } from '../../../../shared/helpers/state';
import { useDashboard } from '../../helpers/dashboard';
import FormWrapper from '../../modules/BusinessProfileEdit/FormWrapper';
import { useFetch } from '../../../../shared/helpers/hooks';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const { businessProfile: { getBusinessProfile } } = apiRoutes;
const BusinessProfileEdit = ({ className, businessProfileData, adminPreview, userId, businessProfileId }) => {
  const { t } = useTranslation();
  const { businessProfile } = useDashboard();
  const sectionRef = useRef(null);
  const observer = useRef(null);
  const list = useRef(null);
  const [state, setState] = useState(StateEnum.initializing);
  const [formState, setFormState] = useState(StateEnum.initializing);
  const [section, setSection] = useState(SectionEnum.name);
  const [edit, setEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [autoScrollEnable, setAutoScrollEnable] = useState(true);
  const [formFilledStatus, setFormFilledStatus] = useState({});
  const [sectionStatus, setSectionStatus] = useState([]);
  const [form, setForm] = useState(initialFormState);

  const sectionComponents = useMemo(() => sections.map((el) => el.components).flat(), []);

  const { responseData: businessProfileResponse } = useFetch({
    url: getBusinessProfile,
    payload: { businessProfileId: businessProfile.id },
    config: { fetchOnMount: !businessProfileData, useCache: false },
  });

  const editHandler = (editSection) => {
    if (businessProfileData) {
      return;
    }
    const sectionToEdit = sectionComponents.find((el) => t(el.label) === editSection);
    setEdit(sectionToEdit?.key || null);
  };

  const handleObserver = (ref) => {
    if (observer.current && ref) {
      observer.current.observe(ref);
    }
  };

  const handleSection = (id) => () => {
    setAutoScrollEnable(false);
    const elements = Array.from(list.current.children);
    const found = elements.find((el) => el.ariaLabel === id);
    if (found) {
      found.scrollIntoView({ behavior: 'smooth' });
    }
    setTimeout(() => setAutoScrollEnable(true), 1000);
  };

  const handleError = (key) => (value) => {
    setErrors((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (sectionRef.current && autoScrollEnable) {
      const elements = Array.from(sectionRef.current?.children);
      const focused = elements.find((el) => el.classList.contains(classes.focused));
      if (focused) {
        sectionRef.current.scrollTo({
          left:
            focused.offsetLeft
            - sectionRef.current.offsetLeft
            + focused.offsetWidth / 2
            - sectionRef.current.offsetWidth / 2,
          behavior: 'smooth',
        });
      }
    }
  }, [section, autoScrollEnable]);

  useEffect(() => {
    let intersectingElements = [];
    const onIntersect = async (entries) => {
      const intersecting = entries.filter((el) => el.isIntersecting).map((el) => el.target.ariaLabel);
      const notIntersecting = entries.filter((el) => !el.isIntersecting).map((el) => el.target.ariaLabel);
      intersectingElements = Array.from(new Set([...intersectingElements, ...intersecting])).filter(
        (el) => !notIntersecting.includes(el),
      );

      const firstItemIntersecting = sections.find((el) => intersectingElements.some((item) => item === el.id));
      if (firstItemIntersecting) {
        setSection(firstItemIntersecting.id);
      }
    };
    observer.current = new IntersectionObserver(onIntersect, {
      root: list.current,
      threshold: 0.1,
    });
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    if (businessProfileResponse || businessProfileData) {
      const data = businessProfileData || businessProfileResponse;
      const parsedData = businessProfileDataParser(data, []);
      setForm({
        ...parsedData,
      });
      setState(StateEnum.success);
    }
  }, [businessProfile.id, businessProfileData, businessProfileResponse]);

  useEffect(() => {
    setFormFilledStatus(checkFormStatus(sectionComponents, form));
  }, [form, sectionComponents]);

  useEffect(() => {
    if (formFilledStatus.length) {
      const formSectionList = createFormSectionList();
      const formSectionListWithFilledStatus = createFormSectionListWithFilledStatus(formSectionList, formFilledStatus);
      setSectionStatus(formSectionListWithFilledStatus);
    }
  }, [formFilledStatus]);

  if (state === StateEnum.initializing || !sectionStatus.length) {
    return <LoaderOverlay customStyle={classes.center} />;
  }
  if (state === StateEnum.error) {
    return (
      <div className={classes.center}>
        <ErrorMessage message={t('global.error')} />
      </div>
    );
  }
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={clsx(classes.header, adminPreview && classes.admimPreview)}>
        <div className={classes.title}>{t('businessProfileEdit.header')}</div>
        <div ref={sectionRef} className={classes.subHeader}>
          {sections.map(({ id, name }) => (
            <div key={id} onClick={handleSection(id)} className={clsx(classes.cell, section === id && classes.focused)}>
              <FormSuccessIndicator radius={20} disabled={section !== id} status={checkStatus(id, sectionStatus)} />
              <div className={clsx(classes.name, section === id && classes.focused)}>{t(name)}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={classes.body}>
        {formState === StateEnum.loading && <LoaderOverlay customStyle={classes.overlay} />}
        <div className={clsx(classes.formList)} ref={list}>
          {sections.map((el) => (
            <div className={classes.form} key={el.id} aria-label={el.id} ref={handleObserver}>
              <div className={classes.sectionHeader}>{t(el.name)}</div>
              {el.components.map(({ key, Form, label, suggestions, labelPill }) => (
                <FormWrapper
                  key={key}
                  editHandler={editHandler}
                  className={classes.section}
                  label={t(label)}
                  status={checkStatus(key, formFilledStatus)}
                  suggestions={suggestions}
                  labelPill={labelPill}
                  adminPreview={adminPreview}
                >
                  <Form
                    isEdit={edit === key}
                    editHandler={editHandler}
                    readOnlyAction={errors[key]}
                    key={`form-${el.id}-${key}`}
                    setState={setFormState}
                    setForm={setForm}
                    onError={handleError(key)}
                    form={form}
                    adminPreview={adminPreview}
                    userId={userId}
                    businessProfileId={businessProfileId || businessProfile.id}
                  />
                </FormWrapper>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessProfileEdit;
