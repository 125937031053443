import React from 'react';
import classes from './styles.module.css';

const BusinessBasicDataSettings = ({ title, address }) => (
  <div className={classes.wrapper}>
    <div className={classes.title}>{title}</div>
    <div className={classes.address}>{address}</div>
  </div>
);

export default BusinessBasicDataSettings;
