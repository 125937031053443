import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { SUSPENDED_PROFILE_ACTION_URL, statusConfig } from './utils';
import Button from '../../../../../shared/components/Button';
import Loader from '../../../components/Loader';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const BusinessStatus = ({ status = 'verified', forDashboard = true, adminPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [statusToDisplay, setStatusToDisplay] = useState();

  const { duplicate, suspended, notVerified } = PROFILE_STATUS_ENUM;
  const translatedStatusConfig = statusConfig(t, adminPreview);
  const shouldRenderLinkForm = [duplicate, suspended].includes(status) && !adminPreview;
  const shouldRenderVerifyButton = status === notVerified && !adminPreview;

  const navigateVerify = () => {
    navigate(seoRoutes.verification);
  };

  useEffect(() => {
    if (!status) return;
    setStatusToDisplay(translatedStatusConfig[status]);
  }, [status]);

  if (!status) {
    return <Loader />;
  }

  return (
    <div className={classes.businessCardStatusBox}>
      <div className={clsx(classes.title, !forDashboard && classes.flexRow)}>
        {forDashboard && t('dashboard.businessCardStatusBoxTitle')}
        <div className={classes.box}>
          <div className={clsx(classes.status, classes[status])}>{statusToDisplay?.status}</div>
          <div className={clsx(classes.iconWrapper, classes[status])}>
            {statusToDisplay && statusToDisplay.Icon && (
              <statusToDisplay.Icon className={clsx(classes.icon, classes[status])} />
            )}
          </div>
          <div className={classes.subInfo}>{statusToDisplay?.subInfo}</div>
          {shouldRenderLinkForm && (
            <a
              target="_blank"
              referrerPolicy="no-referrer"
              href={SUSPENDED_PROFILE_ACTION_URL}
              className={classes.suspendedForm}
              rel="noreferrer"
            >
              {t('dashboard.suspendedForm')}
            </a>
          )}
          {shouldRenderVerifyButton && (
            <Button
              onClick={navigateVerify}
              label={t('businessProfileCreate.verify')}
              className={classes.verifyButton}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default BusinessStatus;
