import React from 'react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../../SEO/helpers/constants';
import {
  getConnectedBusinessProfile,
  getPayment,
  getSubscriptionDateLabel,
  getSubscriptionWithPrice,
  pillStatusConfig,
} from '../../../../SEO/modules/Settings/SubscriptionSettings/utils';
import { paymentMethodEnum } from '../../../../SEO/modules/Subscription/PaymentMethodItem/utils';
import { capitalizeFirstLetter } from '../../../../../shared/components/Input/transformers';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../../../shared/helpers/enums';
import { formatCurrency } from '../../../../../shared/helpers/helpers';
import { ReactComponent as DeleteIcon } from '../../../../../shared/assets/bin.svg';

const renderStatusPill = (status) => {
  const lowerCaseVal = status.toLowerCase();
  const subStatus = pillStatusConfig[lowerCaseVal];
  return <Pill label={i18next.t(subStatus)} className={clsx(classes.pill, classes[lowerCaseVal])} />;
};

export const userSubscriptionListConfig = (isSuperAdmin) => {
  const config = [
    {
      key: 'subscription',
      header: 'adminControlPanel.plan',
      render: capitalizeFirstLetter,
      sort: 'subscription',
      width: 0.35,
      rowClassName: classes.cell,
    },
    {
      key: 'amount',
      header: 'adminControlPanel.amount',
      render: (val) => formatCurrency(val.price),
      width: 0.45,
      rowClassName: classes.cell,
    },
    {
      key: 'payment_type',
      header: 'settings.payment',
      sort: 'payment_type',
      width: 0.75,
      rowClassName: classes.cell,
    },
    {
      key: 'connectedBusinessProfile',
      header: 'settings.businessProfile',
      render: (val) => val.name,
      width: 1,
      rowClassName: classes.cell,
    },
    {
      key: 'status',
      header: 'settings.subscriptionStatus',
      render: renderStatusPill,
      width: 0.75,
      rowClassName: classes.cell,
    },
    {
      key: 'subscription_date',
      header: 'settings.date',
      width: 1,
      sort: 'subscription_date',
      rowClassName: classes.cell,
    },
    {
      key: 'manage',
      render: (value) => (value ? <Button readOnly className={classes.mangeBtn} label={i18next.t('settings.manage')} /> : null),
      width: 0.5,
    },
  ];

  if (isSuperAdmin) {
    config.push({
      action: true,
      key: 'delete',
      header: '',
      render: (val) => <Button Icon={DeleteIcon} className={classes.deleteBtn} iconStyle={classes.deleteIconStyle} />,
      width: 0.25,
      rowClassName: classes.cell,
    });
  }

  return config;
};

export const parseUserSubscriptionDataToListColumns = (data) => data.map((item) => {
  const subscriptionDate = dayjs(item.subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY);
  const subscriptionDateLabel = getSubscriptionDateLabel(item.paymentType, item.status);
  const subscriptionWithPrice = getSubscriptionWithPrice(item.subscription, item.paymentType);
  return {
    ...item,
    id: item.bpId,
    manage: item.paymentType === paymentMethodEnum.recurring && item.status === SUBSCRIPTION_STATUS_ENUM.ACTIVE,
    connectedBusinessProfile: getConnectedBusinessProfile(item.name, item.address),
    payment_type: i18next.t(getPayment(item.paymentType)),
    amount: subscriptionWithPrice,
    subscription_date: `${subscriptionDateLabel}: ${subscriptionDate}`,
  };
});
