import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import FilterTools from '../../../../SEO/modules/Settings/FilterTools';
import {
  DEFAULT_FILTERS,
  getApiPayload,
  subscriptionFilterConfig,
} from '../../../../SEO/modules/Settings/SubscriptionSettings/utils';
import { filterChangeHandler } from '../../../../../shared/helpers/helpers';
import List from '../../../../../shared/components/List';
import AdminPagination from '../../../components/AdminPagination';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import { payloadEnum } from '../../../../SEO/views/AddPost/utils';
import { parseUserSubscriptionDataToListColumns, userSubscriptionListConfig } from './utils';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import EmptyState from '../../../../SEO/components/EmptyState';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { useAdmin } from '../../../helpers/admin';
import { useModal } from '../../../../../shared/helpers/hooks';
import DeleteSubscriptionConfirmationModal from '../DeleteSubscriptionConfirmationModal';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const { LIMIT, OFFSET } = payloadEnum;
const { ASC, DESC } = SORT_DIRECTION_ENUM;

const UserSubscriptions = ({ userData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { isSuperAdmin } = useAdmin();

  const { showModal: isDeleteSubscriptionOpen, modalHandler: toggleDeleteSubscriptionModal } = useModal();
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, userId: userData.id });
  const [order, setOrder] = useState({ column: '', direction: '' });
  const [subscriptions, setSubscriptions] = useState({ data: [], totalRecords: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const { data, totalRecords } = subscriptions;
  const isFiltersUsed = (filters.subscription || filters.paymentType || filters.status || filters.search) && !isLoading;
  const isNoData = !isLoading && !data.length && !error;

  const fetchSubscriptions = async (controller = new AbortController()) => {
    const payload = getApiPayload({ filters, order });
    try {
      const { data: apiData } = await api.post(adminApiRoutes.subscription.subscriptionList, payload, {
        signal: controller.signal,
      });
      return { results: apiData.results, total: apiData.total };
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    return { results: [], total: 0 };
  };

  const updateSubscriptions = ({ results, total }) => {
    setSubscriptions({ data: parseUserSubscriptionDataToListColumns(results), totalRecords: total });
  };

  const fetchSubscriptionList = useCallback(
    async (controller) => {
      setIsLoading(true);
      setError(null);

      try {
        const { results, total } = await fetchSubscriptions(controller);
        updateSubscriptions({ results, total });
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }
    },
    [api, filters, order, showNotification, t],
  );
  const handleFiltersChange = useCallback(filterChangeHandler(setFilters), []);

  const handleOrderClick = (column) => {
    setOrder((prev) => ({
      column,
      direction: prev.column === column && prev.direction === ASC ? DESC : ASC,
    }));
  };

  const handleListAction = (key, row, rowData) => {
    if (key === 'delete') {
      setSelectedSubscription(rowData);
      toggleDeleteSubscriptionModal();
    }
  };

  const handleDeleteSubscription = async () => {
    setIsLoading(true);
    try {
      await api.post(
        adminApiRoutes.subscription.deleteClientSubscription,
        { userId: userData.id, businessProfileId: selectedSubscription.bpId }
      );
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      toggleDeleteSubscriptionModal();
      fetchSubscriptionList();
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchSubscriptionList(controller);

    return () => controller.abort();
  }, [filters, order, fetchSubscriptionList]);

  if (isLoading) {
    return <LoaderOverlay isAdmin customStyle={classes.overlay} />;
  }

  return (
    <div className={classes.wrapper}>
      {isDeleteSubscriptionOpen && (
      <DeleteSubscriptionConfirmationModal
        user={userData}
        onClose={toggleDeleteSubscriptionModal}
        onConfirm={handleDeleteSubscription}
      />
      )}
      <FilterTools
        admin
        label={t('global.search')}
        config={subscriptionFilterConfig}
        onChange={handleFiltersChange}
        subscription={filters.subscription}
        paymentType={filters.paymentType}
        status={filters.status}
        search={filters.search}
      />
      {isNoData ? (
        <EmptyState
          admin
          title={t('settings.noData')}
          subTitle={isFiltersUsed ? t('settings.forSelectedFiltersNoData') : t('settings.noSubscriptionData')}
        />
      ) : (
        <>
          <List
            className={classes.list}
            config={userSubscriptionListConfig(isSuperAdmin)}
            data={data}
            headerClassName={classes.listHeader}
            sortOrder={order.direction}
            sortKey={order.column}
            onSort={handleOrderClick}
            onAction={handleListAction}
          />
          <AdminPagination
            noExport
            limit={filters.limit}
            total={totalRecords}
            offset={filters.offset}
            onLimitChange={handleFiltersChange(LIMIT)}
            onPageChange={handleFiltersChange(OFFSET)}
          />
        </>
      )}
    </div>
  );
};

export default UserSubscriptions;
