import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import classes from './styles.module.css';
import {
  actionChangeHandlerHelper,
  getParsedUniqueTokens,
  initRequestPayload,
  limitOpinionList,
  parsingOpinionsData,
  sortOpinionList,
  getEmptyStateText,
  getPayload,
} from './utils';
import { useDashboard } from '../../helpers/dashboard';
import OpinionsBusinessHeader from '../../modules/Opinions/OpinionsBusinessHeader';
import OpinionsItems from '../../modules/Opinions/OpinionItems';
import { useNotification } from '../../../../shared/helpers/notification';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import Pagination from '../../../../shared/components/Pagination';
import OpinionSortActions from '../../modules/Opinions/OpinionSortActions';
import Button from '../../../../shared/components/Button';
import GetMoreOpinionsModal from '../../modules/Opinions/GetMoreOpinionsModal';
import { PROFILE_STATE_ENUM } from '../../components/DashboardLayout/utils';
import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';
import BusinessStatus from '../../modules/Dashboard/BusinessStatus';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import { useFetch, useModal } from '../../../../shared/helpers/hooks';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';

const Opinions = ({ adminPreview, userId, businessProfileId, profileImage }) => {
  const { businessProfile, selectBusinessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [businessOpinions, setBusinessOpinions] = useState([]);
  const [businessScore, setBusinessScore] = useState({});
  const [pageToken, setPageToken] = useState([{ id: 1, value: '' }]);
  const [requestPayload, setRequestPayload] = useState({
    ...initRequestPayload,
    businessProfileId: businessProfile.id || businessProfileId,
  });

  const { showModal: isModalVisible, modalHandler: toggleModal } = useModal();

  const limitValue = limitOpinionList.find((el) => el.value === requestPayload.limit).id;
  const orderValue = Number(sortOpinionList.find((el) => el.value === requestPayload.orderBy).id);
  const pageValue = pageToken.find((el) => el.value === requestPayload.pageToken).id || 1;
  const paginationList = limitOpinionList.map((el) => el.value);
  const renderEmptyStateCondition = !businessOpinions?.length;

  const [selectAllowed, setSelectedAllowed] = useState(false);
  const [selectedReviews, setSelectedReviews] = useState(null);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);

  const profileStatusInStorage = getLocalStorageProfileStatus(businessProfile.id);
  const { emptyStateText, emptySateTitle } = getEmptyStateText(adminPreview, t);
  const { apiPath, apiPayload } = getPayload({ adminPreview, businessProfileId, userId, requestPayload });

  const { isLoading, responseData, executeRequest, error } = useFetch({
    url: apiPath,
    payload: apiPayload,
    currentPage: pageValue,
  });
  const { executeRequest: answerWithTemplate } = useFetch({
    url: apiRoutes.review.answerWithTemplate,
    payload: { businessProfileId: businessProfile.id, reviewIds: selectedReviews },
    config: { useCache: false, fetchOnMount: false },
  });

  const selectAllowedHandler = () => {
    setSelectedReviews([]);
    setSelectedAllowed((prev) => !prev);
  };

  const onSelectReviewHandler = (id) => {
    setHasMadeSelection(true);
    const foundReview = selectedReviews.find((el) => el === id);
    if (foundReview) {
      setSelectedReviews((prev) => prev.filter((el) => el !== id));
    } else {
      setSelectedReviews((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    if (responseData?.nextPageToken) {
      const parsedUniqueTokens = getParsedUniqueTokens(pageToken, responseData.nextPageToken);
      setPageToken(parsedUniqueTokens);
    }
    const { parsedBusinessScore, parsedBusinessOpinions } = parsingOpinionsData(responseData);
    setBusinessOpinions(parsedBusinessOpinions);
    setBusinessScore(parsedBusinessScore);
  }, [responseData]);

  const actionChangeHandler = (name) => (id) => {
    setPageToken([{ id: 1, value: '' }]);
    const newPayload = actionChangeHandlerHelper(
      name,
      id,
      businessProfile.id,
      requestPayload.orderBy,
      requestPayload.limit,
    );
    setRequestPayload(newPayload);
  };

  const pageChangeHandler = (page) => {
    const pageFound = pageToken.find((el) => el.id === page).value;
    setRequestPayload((prev) => ({ ...prev, pageToken: pageFound }));
  };

  const paginationLimitChangeHandler = (limit) => {
    setPageToken([{ id: 1, value: '' }]);
    setRequestPayload((prev) => ({ ...prev, limit, pageToken: '' }));
  };

  const answerSelectedReviews = async (templateId) => {
    await answerWithTemplate({ templateId });
    showNotification({ label: t('global.success'), message: t('opinions.selectedAnswerSuccess'), type: 'success' });
    setSelectedReviews([]);
    setSelectedAllowed(false);
  };

  useEffect(() => {
    if (hasMadeSelection && selectedReviews.length === 0) {
      setSelectedAllowed(false);
      setHasMadeSelection(false);
    }
  }, [selectedReviews, hasMadeSelection]);

  useEffect(() => {
    if (state?.businessProfile?.id) {
      selectBusinessProfile(state.businessProfile.id);
    }
  }, [state?.businessProfile.id]);

  if (error) {
    return (
      <div className={classes.center}>
        <ErrorMessage message={t('global.error')} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.overlay}>
        <Loader />
      </div>
    );
  }
  if (![PROFILE_STATE_ENUM.verified, PROFILE_STATE_ENUM.processing].includes(profileStatusInStorage) && !adminPreview) {
    return <BusinessStatus status={profileStatusInStorage} forDashboard={false} />;
  }

  return (
    <div className={classes.wrapper}>
      <OpinionsBusinessHeader businessScore={businessScore} adminPreview={adminPreview} />
      <div className={clsx(classes.body, adminPreview && classes.adminPreview)}>
        {isModalVisible && (
          <GetMoreOpinionsModal modalHandler={toggleModal} rwdModalBackTitle={t('modalMobile.goBackToOpinions')} />
        )}
        {renderEmptyStateCondition ? (
          <div className={classes.empty}>
            <EmptyState title={emptySateTitle} subTitle={emptyStateText} />
          </div>
        ) : (
          <>
            <OpinionSortActions
              onClick={actionChangeHandler}
              limitValue={limitValue}
              orderValue={orderValue}
              onSelect={selectAllowedHandler}
              selectedReviews={selectedReviews}
              answerSelectedReviews={answerSelectedReviews}
              adminPreview={adminPreview}
            />
            <div className={classes.opinionList}>
              <OpinionsItems
                businessProfileId={businessProfile.id}
                businessOpinions={businessOpinions}
                fetchOpinions={executeRequest}
                businessProfile={businessProfile}
                selectAllowed={selectAllowed}
                selectedReviews={selectedReviews}
                onSelectReviewHandler={onSelectReviewHandler}
                answerSelectedReviews={answerSelectedReviews}
                adminPreview={adminPreview}
                profileImage={profileImage}
              />
            </div>
            <Pagination
              onLimitChange={paginationLimitChangeHandler}
              className={classes.pagination}
              page={pageValue}
              onPageChange={pageChangeHandler}
              limit={requestPayload.limit}
              total={businessScore.totalReviewCount}
              paginationLimitList={paginationList}
            />
          </>
        )}
      </div>
      <div className={classes.rwdFooterAction}>
        <Button onClick={toggleModal} className={classes.rwdGetMore} label={t('opinions.getMoreOpinions')} />
      </div>
    </div>
  );
};

export default Opinions;
