import React, { useContext } from 'react';

const defaultNotification = {
  showNotification: () => {
    throw 'Notifications are not initialiezed yet';
  }
};

export const NotificationContext = React.createContext(defaultNotification);

export const useNotification = () => useContext(NotificationContext);
