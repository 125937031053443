import dayjs from 'dayjs';
import {
  POST_STATE_ENUM,
  formEnum,
  initialDataEvent,
  initialDataOffer,
  initialDataUpdate,
} from '../../modules/AddPost/ModalForm/utils/utils';
import { parseTime } from '../../../../shared/helpers/parsers';
import AddEventForm from '../../modules/AddPost/EventForm';
import AddUpdateForm from '../../modules/AddPost/UpdateForm';
import AddOfferForm from '../../modules/AddPost/OfferForm';
import { adminApiRoutes, apiRoutes } from '../../../../shared/helpers/apiRoutes';

export const TAB_ENUM = {
  PUBLISHED: 'published',
  PLANNED: 'planned',
};

export const formOptions = [
  {
    id: formEnum.STANDARD,
    type: 'Update',
    title: 'posts.addUpdate',
    description: 'posts.addUpdateDescription',
    Form: AddUpdateForm,
    initialData: initialDataUpdate,
  },
  {
    id: formEnum.OFFER,
    title: 'posts.addOffer',
    description: 'posts.addOfferDescription',
    Form: AddOfferForm,
    type: 'Offer',
    initialData: initialDataOffer,
  },
  {
    id: formEnum.EVENT,
    title: 'posts.addEvent',
    description: 'posts.addEventDescription',
    Form: AddEventForm,
    type: 'Event',
    initialData: initialDataEvent,
  },
];

const sortEnum = {
  desc: 'DESC',
  asc: 'ASC',
};

export const sortList = [
  {
    id: 1,
    name: 'posts.newest',
    value: 1,
    sort: sortEnum.desc,
  },
  {
    id: 2,
    name: 'posts.oldest',
    value: 2,
    sort: sortEnum.asc,
  },
];
export const postStateConfig = {
  LIVE: {
    label: 'posts.live',
    color: 'var(--green500)',
  },
  REJECTED: {
    label: 'posts.rejected',
    color: 'var(--red500)',
  },
  PROCESSING: {
    label: 'posts.processing',
    color: 'var(--yellow500)',
  },
  PLANNED: {
    label: 'posts.planned',
    color: 'var(--grey600)',
  },
};

export const previewTypes = {
  [formEnum.STANDARD]: `posts.${formEnum.previewUpdate}`,
  [formEnum.OFFER]: `posts.${formEnum.previewOffer}`,
  [formEnum.EVENT]: `posts.${formEnum.previewEvent}`,
};

const timesFromAllDayEvent = ['00:00', '23:59', '0:0'];

const modalTitles = formOptions.map((option) => option.title);
const previewTitles = Object.values(previewTypes);

export const modalWithArrowBack = [...modalTitles, ...previewTitles];

export const getPostID = (name) => {
  if (name) {
    const parts = name.split('/');
    return parts[parts.length - 1];
  }
  return null;
};

const orderEnum = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const payloadEnum = {
  DATE: 'date',
  OFFSET: 'offset',
  LIMIT: 'limit',
  SORT: 'sort',
  ORDER: 'order',
};

export const initialFilters = { limit: 10, offset: 0, orderBy: 'date', order: orderEnum.DESC };

const parseDate = (date) => dayjs(date).format('DD.MM.YYYY');

export const parseDateFromObject = (dateObj) => dayjs(Object.values(dateObj).join('-'));

export const parseTimeFromObject = (timeObj) => {
  const modifiedTimeObj = { ...timeObj, minutes: timeObj.minutes === undefined ? '00' : timeObj.minutes };
  const timeString = Object.values(modifiedTimeObj).join(':');
  return parseTime(timeString);
};

const formatSchedule = (startDate, endDate, startTime, endTime) => {
  const formattedStartDate = parseDate(startDate);
  const formattedEndDate = parseDate(endDate);

  if (startTime && endTime && !timesFromAllDayEvent.includes(startTime) && !timesFromAllDayEvent.includes(endTime)) {
    const oneDayWithTime = `${formattedStartDate} ${startTime} - ${endTime}`;
    const moreDaysWithTime = `${formattedStartDate} ${startTime} - ${formattedEndDate} ${endTime}`;

    return dayjs(startDate).isSame(endDate) ? oneDayWithTime : moreDaysWithTime;
  }

  return dayjs(startDate).isSame(endDate) ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;
};

const getScheduleOfferValid = (startDate, endDate, startTime, endTime) => formatSchedule(startDate, endDate, startTime, endTime);

export const getScheduleFromSinglePost = (schedule) => {
  const { startDate, endDate, startTime, endTime } = schedule;
  const inputStartDate = startDate && parseDateFromObject(startDate);
  const inputEndDate = endDate && parseDateFromObject(endDate);
  const inputStartTime = startTime && parseTimeFromObject(startTime);
  const inputEndTime = endTime && parseTimeFromObject(endTime);

  return formatSchedule(inputStartDate, inputEndDate, inputStartTime, inputEndTime);
};
export const getSchedule = (data = { localPost: { event: { schedule: {} } } }) => {
  if (!data.localPost || !data.localPost.event || !data.localPost.event.schedule) return null;
  const { schedule } = data.localPost.event;
  const inputStartDate = schedule.startDate && parseDateFromObject(schedule.startDate);
  const inputEndDate = schedule.endDate && parseDateFromObject(schedule.endDate);
  const inputStartTime = schedule.startTime && parseTimeFromObject(schedule.startTime);
  const inputEndTime = schedule.endTime && parseTimeFromObject(schedule.endTime);
  const offerValid = getScheduleOfferValid(inputStartDate, inputEndDate, inputStartTime, inputEndTime);

  return offerValid;
};
export const parsePosts = (posts, planned = false) => posts.map((post) => {
  if (!planned) {
    return {
      ...post,
      type: post.type || post.topicType,
      state: post.state || POST_STATE_ENUM.PLANNED,
      localPost: {
        languageCode: post.languageCode || 'pl',
        summary: post.summary || '',
        topicType: post.topicType || formEnum.STANDARD,
        media:
            Array.from({ length: post.media?.length }, (_, index) => ({
              ...post.media[index],
              id: index,
              mediaSource: post.media[index].sourceUrl || post.media[index].googleUrl,
            })) || [],
      },
    };
  }
  return {
    ...post,
    publishDate: post.publishDate,
    type: post.type || post.topicType,
    state: post.state || POST_STATE_ENUM.PLANNED,
    localPost: {
      languageCode: post.languageCode || 'pl',
      summary: post.summary || '',
      topicType: post.topicType || formEnum.STANDARD,
      media:
          Array.from({ length: post.media?.length }, (_, index) => ({
            ...post.media[index],
            id: index,
            mediaSource: post.media[index].sourceUrl || post.media[index].googleUrl,
          })) || [],
    },
  };
});

export const getSortedByPublishDate = (posts, { dateFormat = '', order = 'asc' } = {}) => posts.sort((a, b) => {
  const dateA = dayjs(a.publishDate, dateFormat);
  const dateB = dayjs(b.publishDate, dateFormat);
  return order === 'asc' ? dateA - dateB : dateB - dateA;
});

export const fetchPostListHandler = async ({ payload, api, businessProfileName, userId, controller }) => {
  const apiPath = userId ? adminApiRoutes.client.localPostList : apiRoutes.localPost.localPostList;
  const apiPayload = userId ? { ...payload, userId } : payload;

  const { data } = await api.post(apiPath, apiPayload, { signal: controller.signal });

  const { localPosts = [], postponedLocalPosts = [] } = data;

  const parsedPublishedData = parsePosts(localPosts, businessProfileName);
  const parsedPlannedData = parsePosts(postponedLocalPosts, businessProfileName, true);
  const plannedPostsWithOrder = getSortedByPublishDate(parsedPlannedData);
  const totalNumber = data.countAllPosts;

  return { published: parsedPublishedData, planned: plannedPostsWithOrder, totalNumber };
};

export const profileStatus = {
  duplicate: 'dashboard.duplicate',
  notVerified: 'dashboard.notVerified',
  processing: 'dashboard.processing',
  suspended: 'dashboard.suspended',
  verified: 'dashboard.verified',
};
