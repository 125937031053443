import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EventSource } from 'eventsource';
import { useApi } from './api';
import { useNotification } from './notification';
import { handleApiError } from '../../app/SEO/modules/Settings/ChangePassword/utils';
import { parseProfileStatus, PROFILE_STATE_ENUM } from '../../app/SEO/components/DashboardLayout/utils';
import {
  ERROR_LIST_ENUM,
  MIN_TABLET_WIDTH,
  subscriptionsConfig,
} from '../../app/SEO/helpers/constants';
import { apiRoutes } from './apiRoutes';
import { useDashboard } from '../../app/SEO/helpers/dashboard';

export const useModal = (initialValue = false) => {
  const [showModal, setShowModal] = useState(initialValue);
  const modalHandler = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  return { showModal, setShowModal, modalHandler };
};

export const useWindowResize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const onResizeHandler = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return innerWidth;
};

export const useKeyDown = ({ key, callback, canInvoke = true }) => {
  useEffect(() => {
    const onKeyDownHandler = (e) => {
      const triggerCallback = key === e.key && canInvoke;
      if (triggerCallback) {
        callback();
      }
    };

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [key, callback, canInvoke]);
};

export const useFetchData = () => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async (url, body = null, config = null) => {
    setIsLoading(true);
    try {
      const { data: apiData } = await api.post(url, body, config);
      setData(apiData);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchData, isLoading, data };
};

export const useCloseOnOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export const useIsMobile = (minWidth) => {
  const innerWidth = useWindowResize();
  return innerWidth <= minWidth;
};
export const useGetVerificationStatus = (selectedBusinessProfile) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [requestStatus, setRequestStatus] = useState({ loading: false, error: false });
  const statusInLocalStorage = localStorage.getItem(`status-${selectedBusinessProfile.id}`);
  const [businessProfileVerificationStatus, setBusinessProfileVerificationStatus] = useState(statusInLocalStorage);

  const fetchStatus = async () => {
    setRequestStatus({ loading: true, error: false });
    try {
      const { data } = await api.post(apiRoutes.getVerificationStatus, {
        businessProfileId: selectedBusinessProfile.id,
      });
      const parsedStatus = parseProfileStatus(data, t);
      setBusinessProfileVerificationStatus(parsedStatus.id);
      localStorage.setItem(`status-${selectedBusinessProfile.id}`, parsedStatus.id);
      setRequestStatus({ loading: false, error: false });
    } catch (err) {
      if (err.name !== 'AbortError') {
        setRequestStatus({ loading: false, error: true });
        handleApiError({ err, showNotification, t });
      }
    }
  };
  useEffect(() => {
    if (!selectedBusinessProfile || !selectedBusinessProfile.id) {
      setRequestStatus({ loading: false, error: true });
      return;
    }

    if (!statusInLocalStorage && !selectedBusinessProfile.externalBusinessProfile) {
      fetchStatus();
    }
  }, [selectedBusinessProfile, api, t, showNotification, statusInLocalStorage]);

  return { requestStatus, businessProfileVerificationStatus };
};

export const useVerificationStatuses = (profiles, t, showNotification) => {
  const { api } = useApi();
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();

    const fetchStatus = async (item) => {
      if (item.externalBusinessProfile) {
        return PROFILE_STATE_ENUM.external;
      }
      try {
        const { data: verificationData } = await api.post(
          apiRoutes.businessProfile.getVerificationStatus,
          {
            businessProfileId: item.id,
          },
          { signal: controller.signal },
        );
        const parsedStatus = parseProfileStatus(verificationData, t);
        localStorage.setItem(`status-${item.id}`, parsedStatus.id);
        return parsedStatus.id;
      } catch (err) {
        if (err.name !== 'AbortError') {
          handleApiError({ err, showNotification, t });
        }
        return null;
      }
    };

    const fetchStatuses = async () => {
      setIsLoading(true);
      try {
        const statusesArray = await Promise.all(
          profiles.map(async (profile) => {
            const statusInStorage = localStorage.getItem(`status-${profile.id}`);
            return {
              id: profile.id,
              status: statusInStorage || (await fetchStatus(profile)),
            };
          }),
        );

        const reducedStatuses = statusesArray.reduce((acc, { id, status }) => {
          acc[id] = status;
          return acc;
        }, {});

        setStatuses(reducedStatuses);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatuses();
    return () => {
      controller.abort();
    };
  }, [profiles, api, t, showNotification]);

  return { statuses, statusLoading: isLoading };
};

export const useDraggable = (isDraggable) => {
  const [position, setPosition] = useState({
    x: isDraggable ? window.innerWidth / 24 : 16,
    y: isDraggable ? window.innerHeight / 24 : 16,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const isMinTabletWidth = useIsMobile(MIN_TABLET_WIDTH);

  const onMouseDown = (e) => {
    if (!isDraggable) return;
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const onMouseMove = (e) => {
    if (isDragging && isDraggable) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isMinTabletWidth) {
      setPosition({ x: 0, y: '40px' });
    }
  }, [isMinTabletWidth]);

  useEffect(() => {
    if (isDraggable) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, offset]);

  return { onMouseDown, position };
};

export const useSSENotifications = () => {
  const [notificationData, setNotificationData] = useState([]);
  const token = localStorage.getItem('token');
  const eventSourceRef = useRef(null);

  useEffect(() => {
    if (!eventSourceRef.current && token) {
      eventSourceRef.current = new EventSource(`${process.env.REACT_APP_URL}notification/sseConnection`, {
        fetch: (input, init) => fetch(input, {
          ...init,
          headers: {
            ...init.headers,
            'x-auth-token': token,
          },
        }),
      });

      eventSourceRef.current.addEventListener('notification', (event) => {
        const data = JSON.parse(event.data);
        setNotificationData((prev) => [...prev, data]);
      });

      eventSourceRef.current.onerror = () => {
        eventSourceRef.current.close();
      };
    }

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, [token]);

  return notificationData;
};

const cache = new Map();
const fetchData = async ({
  url,
  payload,
  useCacheFlag,
  cacheKey,
  cacheDuration,
  controller,
  api,
  t,
  showNotification,
}) => {
  if (useCacheFlag && cache.has(cacheKey)) {
    const { timestamp, value } = cache.get(cacheKey);
    if (Date.now() - timestamp < cacheDuration) {
      return { data: value, fromCache: true };
    }
    cache.delete(cacheKey);
  }

  try {
    const { data } = await api.post(url, payload, { signal: controller.signal });
    if (useCacheFlag) {
      cache.set(cacheKey, { timestamp: Date.now(), value: data });
    }
    return { data, fromCache: false };
  } catch (err) {
    if (err?.response?.data && err.response.data.code === ERROR_LIST_ENUM.NOT_FOUND_REQUESTED_ENTITY) {
      showNotification({
        message: t('dashboard.businessProfileNotFoundActionDelete'),
        type: 'error',
      });
      await api.post(apiRoutes.businessProfile.detachBusinessProfiles, {
        businessProfilesId: localStorage.getItem('selectedProfile'),
      });
    }
    handleApiError({ err, showNotification, t });
    throw err;
  }
};

export const useFetch = ({
  url,
  payload = {},
  currentPage = 1,
  config = { useCache: true, cacheDuration: 5 * 60 * 1000, fetchOnMount: true },
}) => {
  const controller = new AbortController();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { useCache = true, cacheDuration = 5 * 60 * 1000, fetchOnMount = true } = config;
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cacheKey = `${url}-${currentPage}-${JSON.stringify(payload)}`;

  const executeFetch = async (dynamicPayload = {}, useCacheFlag = useCache) => {
    setIsLoading(true);
    setError(null);

    const finalPayload = { ...payload, ...dynamicPayload };

    try {
      const { data, fromCache } = await fetchData({
        url,
        payload: finalPayload,
        useCacheFlag,
        cacheKey,
        cacheDuration,
        controller,
        api,
        t,
        showNotification,
      });
      setResponseData(data);
      if (fromCache) {
        setIsLoading(false);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fetchOnMount) {
      executeFetch();
    }
    return () => {
      controller.abort();
    };
  }, [url, currentPage, JSON.stringify(payload)]);

  return { responseData, error, isLoading, executeRequest: executeFetch };
};

export const useMultipleFetch = (promises, config = { useCache: true, cacheDuration: 5 * 60 * 1000 }) => {
  const { api } = useApi();
  const { useCache = true, cacheDuration = 5 * 60 * 1000 } = config;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      const keys = Object.keys(promises);
      const results = await Promise.all(
        keys.map(async (key) => {
          const { path, body } = promises[key];
          const cacheKey = `${path}-${JSON.stringify(body)}`;

          try {
            const { data: responseData } = await fetchData({
              url: path,
              payload: body,
              useCacheFlag: useCache,
              cacheKey,
              cacheDuration,
              controller: new AbortController(),
              api,
              t: () => {},
              showNotification: () => {},
            });
            return { key, responseData };
          } catch {
            return { key, responseData: null };
          }
        }),
      );

      const dataObject = results.reduce((acc, { key, responseData }) => {
        acc[key] = responseData;
        return acc;
      }, {});

      setData(dataObject);
      setLoading(false);
    };

    const everyPromiseHasBodyWithId = Object.values(promises).every(
      (promise) => promise.body?.id || promise.body?.businessProfileId || promise.body.userId,
    );
    if (everyPromiseHasBodyWithId) {
      fetchAllData();
    }
  }, [useCache, cacheDuration]);

  return { data, loading };
};

const { free, mini } = subscriptionsConfig;

export const useGetSubscriptionData = () => {
  const { businessProfile } = useDashboard();
  const { subscription } = businessProfile;
  const isReadOnlySubPlan = [free, mini].includes(subscription);

  return { isReadOnlySubPlan };
};
