import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import PlaceElement from '../PlaceElement';
import classes from './styles.module.css';
import { parsePlaces, sortList } from './utils';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import SelectAlternative from '../../../../../shared/components/Select/SelectAlternative';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as RefreshIcon } from '../../../../../shared/assets/refresh.svg';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { adminApiRoutes, apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const REVERSE_SORT_ID = 2;

const PlaceList = ({
  className,
  data,
  selectedPoint,
  selectedPlace,
  onPlaceSelect,
  businessProfiles,
  setHighlighted,
  overAllPositions,
  onReset,
  shouldRenderResetBtn,
  profileReferencePlaceId,
  adminPreview,
}) => {
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { t } = useTranslation();
  const listRef = useRef();
  const [sortValue, setSortValue] = useState(sortList[0].id);
  const elementsRefs = useRef([]);
  const observer = useRef();
  const fetched = useRef(new Set()).current;
  const placesArray = useRef([]);
  const [images, setImages] = useState([]);
  const businessNotFoundLabel = profileReferencePlaceId
    ? t('generateReport.businessCardNotFound')
    : t('setFilters.profileNotVerifiedPast');

  const handlePlaceSelect = (id) => () => {
    onPlaceSelect(id);
  };
  const { places, highlighted } = useMemo(() => {
    let parsedPlaces = parsePlaces({ data, images, selectedPoint, businessProfiles, overAllPositions });

    if (sortValue === REVERSE_SORT_ID) {
      parsedPlaces = parsedPlaces.reverse();
    }

    const selected = parsedPlaces.find((el) => el.placeReferenceId === selectedPlace);
    if (selected) {
      const photo = images.find((el) => el.id === selected.id);
      const parsedSelected = { ...selected, photo: photo ? photo.image : null };
      setHighlighted(parsedSelected);
      return { places: parsedPlaces, highlighted: parsedSelected };
    }
    return { places: parsedPlaces, highlighted: null };
  }, [data, selectedPoint, images, selectedPlace, overAllPositions, sortValue]);

  const fetchPhoto = async ({ id }) => {
    try {
      const apiGeneratePlacePhotoPath = adminPreview ? adminApiRoutes.client.generatePlacePhoto : apiRoutes.place.generatePlacePhoto;
      const apiGetPlacePhotoPath = adminPreview ? adminApiRoutes.client.getPlacePhoto : apiRoutes.place.getPlacePhoto;

      const { data: photoData } = await api.post(apiGeneratePlacePhotoPath, {
        placeId: id,
      });
      const { date, fileName } = photoData;
      const { data: photo } = await api.get(apiRoutes.getPlacePhoto(apiGetPlacePhotoPath, date, fileName), {
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
      });
      return { state: 'loaded', id, image: window.URL.createObjectURL(new Blob([photo], { type: 'image/jpg' })) };
    } catch (err) {
      return { state: 'rejected', id };
    }
  };

  const fetchAndSetImages = async (indexes) => {
    try {
      const promises = indexes.map((el) => fetchPhoto({ id: el }));
      const fetchedImages = await Promise.all(promises);
      const fulfilled = fetchedImages.filter((el) => el.state === 'loaded');
      const rejected = fetchedImages.filter((el) => el.state === 'rejected');

      [...fulfilled, ...indexes].forEach((el) => fetched.add(el.id));
      setImages((prev) => [...prev, ...fulfilled, ...rejected]);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  const handleElementRefChange = useCallback(
    (index) => (ref) => {
      if (observer.current && ref) {
        elementsRefs.current[index] = ref;
        observer.current.observe(ref);
      }
    },
    [],
  );
  const handleSortClick = (id) => {
    setSortValue(id);
  };
  const showOnListHandler = (value) => t(value.name);

  useEffect(() => {
    if (selectedPlace) {
      const index = placesArray.current.findIndex((el) => el.placeReferenceId === selectedPlace);
      if (index !== -1) {
        const { id } = placesArray.current[index];
        if (!fetched.has(id)) {
          fetchAndSetImages([id]);
        }
      }
    }
  }, [selectedPlace]);

  useEffect(() => {
    const onIntersect = async (entries) => {
      const intersected = entries.filter((el) => el.isIntersecting);
      const indexes = intersected.map((el) => {
        const index = elementsRefs.current.findIndex((item) => item && item.contains(el.target));
        return placesArray.current[index].id;
      });
      const imagesArray = indexes.filter((el) => !fetched.has(el));

      if (imagesArray.length > 0) {
        await fetchAndSetImages(imagesArray);
      }
    };

    observer.current = new IntersectionObserver(onIntersect, { root: listRef.current, threshold: 0.1 });

    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    placesArray.current = places;
  }, [places]);

  return (
    <div className={clsx(className, adminPreview && classes.adminPreview)}>
      <div className={classes.highlighted}>
        {highlighted ? (
          <>
            <div className={classes.text}>
              {t('generateReport.yourReport')}
              {shouldRenderResetBtn && (
                <Button
                  iconWidth={18}
                  Icon={RefreshIcon}
                  label={t('global.reset')}
                  onClick={onReset}
                  className={classes.resetBtn}
                />
              )}
            </div>
            <PlaceElement selectedPoint={selectedPoint} highlighted data={highlighted} photo={highlighted.photo} />
          </>
        ) : (
          <div className={classes.content}>
            <CircleIconText
              heading=""
              supportingText={businessNotFoundLabel}
              icon={Magnifier}
              supportingTextStyle={classes.emptySupportingStyle}
            />
          </div>
        )}
      </div>

      {Boolean(places.length) && (
        <div className={classes.header}>
          <div className={classes.competitionText}>{`${t('generateReport.competition')} (${places.length})`}</div>
          <SelectAlternative
            className={classes.select}
            showOnList={showOnListHandler}
            onSelect={handleSortClick}
            value={sortValue}
            list={sortList}
          />
        </div>
      )}
      <div className={classes.list} ref={listRef}>
        {places.length ? (
          places.map((el, index) => (
            <div
              key={`place-${el.id}`}
              ref={handleElementRefChange(index)}
              onClick={handlePlaceSelect(el.placeReferenceId)}
            >
              <PlaceElement selectedPoint={selectedPoint} className={classes.element} data={el} photo={el.photo} />
            </div>
          ))
        ) : (
          <div className={classes.content}>
            <CircleIconText
              headingStyle={classes.headingEmptyStyle}
              heading={t('generateReport.noConcurency')}
              supportingText={t('generateReport.noConcurencySubInfo')}
              icon={Magnifier}
              supportingTextStyle={classes.emptySupportingStyle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlaceList;
