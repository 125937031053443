export const sortByDefaultInvoice = (data) => data.sort((a, b) => {
  if (a.defaultInvoice && !b.defaultInvoice) return -1;
  if (!a.defaultInvoice && b.defaultInvoice) return 1;
  return 0;
});

export const showInvoiceDetails = (data) => {
  if (data) {
    if (data?.nip && data?.companyName) {
      return `${data.companyName} NIP: ${data.nip} ${data.postalCode} ${data.city} ${data.address}`;
    }
    return `${data.name} ${data.lastName} ${data.postalCode} ${data.city} ${data.address}`;
  }
  return '';
};
