import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useFetch, useIsMobile } from '../../../../shared/helpers/hooks';
import { getObjectWithoutEmptyValues } from '../../../../shared/helpers/helpers';
import { adminApiRoutes, apiRoutes } from '../../../../shared/helpers/apiRoutes';
import { MIN_TABLET_WIDTH, readOnlySubList } from '../../helpers/constants';
import { useDashboard } from '../../helpers/dashboard';
import classes from './styles.module.css';
import Header from '../../modules/BusinessProfileChangeHistory/Header';
import ChangeHistoryActions from '../../modules/BusinessProfileChangeHistory/ChangeHistoryActions';
import MobileList from '../../modules/BusinessProfileChangeHistory/MobileList';
import Pagination from '../../../../shared/components/Pagination';
import List from '../../../../shared/components/List';
import NoAccessInformation from '../../components/NoAccessInformation';
import { config } from './utils';

const { businessProfile: { getBusinessProfileChangeHistory } } = apiRoutes;
const { client: { acpGetBusinessProfileChangeHistory } } = adminApiRoutes;

const BusinessProfileChangeHistory = ({ userId, businessProfileId }) => {
  const { t } = useTranslation();
  const { businessProfile } = useDashboard();
  const { id, subscription } = businessProfile;
  const isMobileScreen = useIsMobile(MIN_TABLET_WIDTH);
  const isReadOnlySubPlan = readOnlySubList.includes(subscription);

  const [changeHistory, setChangeHistory] = useState({ profileChanges: [], profileChangesCount: 0 });
  const [filters, setFilters] = useState({ action: '', date: '', field: '', userId });
  const [apiFilters, setApiFilters] = useState({ limit: 5, offset: 0 });

  const apiPath = userId ? acpGetBusinessProfileChangeHistory : getBusinessProfileChangeHistory;

  const { executeRequest, responseData } = useFetch({
    url: apiPath,
    payload: { businessProfileId: id || businessProfileId, ...apiFilters, ...getObjectWithoutEmptyValues(filters) },
    config: { useCache: true, fetchOnMount: false },
  });

  const changeFiltersHandler = (name) => (value) => {
    if (t(value) === t('businessProfileChangeHistory.all')) {
      setFilters((prev) => ({ ...prev, [name]: '' }));
      return;
    }
    setFilters((prev) => ({ ...prev, [name]: value }));
    setApiFilters((prev) => ({ ...prev, offset: 0 }));
  };

  const paginationLimitChangeHandler = (limit) => {
    setApiFilters((prev) => ({ ...prev, limit, offset: 0 }));
  };

  const pageChangeHandler = (page) => {
    setApiFilters((prev) => ({ ...prev, offset: (page - 1) * apiFilters.limit }));
  };

  useEffect(() => {
    if (responseData) {
      const { profileChanges, profileChangesCount } = responseData;
      setChangeHistory({ profileChanges, profileChangesCount });
    }
  }, [responseData]);

  useEffect(() => {
    if ((id || businessProfileId) && !isReadOnlySubPlan) {
      executeRequest();
    }
  }, [id, businessProfileId, apiFilters, filters, isReadOnlySubPlan]);

  return (
    <div className={clsx(classes.wrapper, userId && classes.adminPreview)}>
      <Header />
      {isReadOnlySubPlan ? (
        <NoAccessInformation />
      ) : (
        <>
          <ChangeHistoryActions filters={filters} onFiltersChange={changeFiltersHandler} userId={userId} />
          <List className={classes.listWrapper} config={config} data={changeHistory.profileChanges} />
          {isMobileScreen && <MobileList config={config} data={changeHistory.profileChanges} />}
          {Boolean(changeHistory.profileChanges.length) && (
            <Pagination
              page={apiFilters.offset / apiFilters.limit + 1}
              onPageChange={pageChangeHandler}
              onLimitChange={paginationLimitChangeHandler}
              limit={apiFilters.limit}
              className={classes.pagination}
              total={changeHistory.profileChangesCount}
            />
          )}
        </>
      )}
    </div>
  );
};
export default BusinessProfileChangeHistory;
