import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import PaymentHistoryList from '../PaymentHistoryList';
import { paymentHistoryFiltersConfig, paymentHistoryParser } from './utils';
import { useAuth } from '../../../../../shared/helpers/auth';
import Pagination from '../../../../../shared/components/Pagination';
import classes from './styles.module.css';
import { handleApiError } from '../ChangePassword/utils';
import EmptyState from '../../../components/EmptyState';
import { payloadEnum } from '../../../views/AddPost/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import FilterTools from '../FilterTools';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import { filterChangeHandler } from '../../../../../shared/helpers/helpers';
import { getApiPayload } from '../SubscriptionSettings/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { LIMIT, OFFSET } = payloadEnum;
const { ASC, DESC } = SORT_DIRECTION_ENUM;

const PaymentHistory = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { api } = useApi();
  const { businessProfiles } = useAuth();
  const { showNotification } = useNotification();
  const [paymentHistoryData, setPaymentHistoryData] = useState({ data: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({ limit: 5, offset: 0, search: '' });
  const [order, setOrder] = useState({ column: '', direction: '' });

  const { data, total } = paymentHistoryData;
  const isNoData = !data.length;
  const isFiltersUsed = (filters.subscription || filters.paymentType || filters.status || filters.search) && !isLoading;

  const handleOrderClick = (column) => {
    setOrder((prev) => ({
      column,
      direction: prev.column === column && prev.direction === ASC ? DESC : ASC,
    }));
  };

  const fetchPaymentHistory = async ({ signal }) => {
    setIsLoading(true);
    try {
      const payload = getApiPayload({ filters, order });
      const {
        data: { results, total: totalResults },
      } = await api.post(apiRoutes.payment.listPayments, payload, { signal });

      setPaymentHistoryData({ data: paymentHistoryParser(results, businessProfiles), total: totalResults });
    } catch (err) {
      if (err.code !== 'ERR_CANCELED') {
        handleApiError({ err, showNotification, t });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const page = useMemo(() => Math.floor(filters.offset / filters.limit) + 1, [filters.offset, filters.limit]);

  const handleFiltersChange = useCallback(filterChangeHandler(setFilters), []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchPaymentHistory({ signal });

    return () => {
      controller.abort();
    };
  }, [filters, order]);

  useEffect(() => {
    if (state?.idForUser) {
      setFilters((prev) => ({
        ...prev,
        search: state.idForUser,
      }));
    }
  }, [state]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.wrapper}>
      <FilterTools
        subscription={filters.subscription}
        paymentType={filters.paymentType}
        status={filters.status}
        search={filters.search}
        dateFromValue={filters.dateFrom}
        dateToValue={filters.dateTo}
        config={paymentHistoryFiltersConfig}
        onChange={handleFiltersChange}
      />
      {isNoData ? (
        <EmptyState
          title={t('settings.noData')}
          subTitle={isFiltersUsed ? t('settings.forSelectedFiltersNoData') : t('settings.noPaymentData')}
        />
      ) : (
        <>
          <PaymentHistoryList
            data={data}
            onSort={handleOrderClick}
            sortKey={order.column}
            sortOrder={order.direction}
          />
          <Pagination
            limit={filters.limit}
            total={total}
            page={page}
            className={classes.pagination}
            onLimitChange={handleFiltersChange(LIMIT)}
            onPageChange={handleFiltersChange(OFFSET)}
          />
        </>
      )}
    </div>
  );
};

export default PaymentHistory;
