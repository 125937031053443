export const formInputEnum = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  repeatPassword: 'repeatPassword',
};
const ERR_PATH_CONFIG = {
  '/media/mediaList': 'businessProfileEdit.media',
  '/business_profile/getVerificationStatus': 'businessProfileEdit.verificationStatus',
};
const ERR_CODE_ENUM_TRANSLATED = {
  FORBIDDEN_LOGIN_OR_PASSWORD: 'errors.wrongLogin',
  FORBIDDEN_PERMISSION: 'errors.forbiddenPermission',
  FORBIDDEN_ACCESS_TEMPLATE: 'errors.forbiddenAccessTemplate',
  FORBIDDEN_ACCESS_REPORT: 'errors.forbiddenAccessReport',
  FORBIDDEN_FEATURE: 'errors.forbiddenFeature',
  FORBIDDEN_GOOGLE_ACCOUNT: 'errors.forbiddenGoogleAccount',
  INTERNAL_ERROR: 'errors.internalError',
  UNAUTHORIZED: 'errors.unauthorized',
  NOT_FOUND_USER: 'errors.notFoundUser',
  NOT_FOUND_GOOGLE_ACCOUNT: 'errors.notFoundGoogleAccount',
  NOT_FOUND_MEDIA: 'errors.notFoundMedia',
  NOT_FOUND_BUSINESS_PROFILE: 'errors.notFoundBusinessProfile',
  NOT_FOUND_PLACE: 'errors.notFoundPlace',
  NOT_FOUND_PLACES: 'errors.notFoundPlaces',
  NOT_FOUND_REPORT: 'errors.notFoundReport',
  NOT_FOUND_PLACE_PHOTO: 'errors.notFoundPlacePhoto',
  NOT_FOUND_LOCATION: 'errors.notFoundLocation',
  NOT_FOUND_CATEGORY: 'errors.notFoundCategory',
  NOT_FOUND_LOCALISATION_IN_CACHE: 'errors.notFoundLocalisationInCache',
  NOT_FOUND_PHOTO_REFERENCES: 'errors.notFoundPhotoReferences',
  NOT_FOUND_REQUESTED_ENTITY: 'errors.notFoundRequestedEntity',
  NOT_FOUND_BUSINESS_PROFILE_DATA: 'errors.notFoundBusinessProfileData',
  NOT_FOUND_PREVIEW_REPLY: 'errors.notFoundPreviewReply',
  USER_ALREADY_EXISTS: 'errors.userAlreadyExists',
  BUSINESS_PROFILE_ALREADY_EXISTS_SOME_ALREADY_ATTACHED: 'errors.businessProfileAlreadyExistsSomeAlreadyAttached',
  BUSINESS_PROFILE_ALREADY_EXISTS_ALREADY_ATTACHED: 'errors.businessProfileAlreadyExistsAlreadyAttached',
  BAD_REQUEST: 'errors.badRequest',
  FREE_FORM_SERVICE_ITEM_BAD_REQUEST: 'errors.freeFormServiceItemBadRequest',
  INVALID_WEBSITE_URI: 'errors.invalidWebsiteUri',
  INVALID_GRANT_BY_CODE: 'errors.invalidGrantByCode',
  INVALID_POSTAL_CODE: 'errors.invalidPostalCode',
  INVALID_PRIMARY_PHONE: 'errors.invalidPrimaryPhone',
  INVALID_ADDITIONAL_PHONE: 'errors.invalidAdditionalPhone',
  INVALID_STOREFRONT_ADDRESS: 'errors.invalidStorefrontAddress',
  INVALID_SERVICE_AREA: 'errors.invalidServiceArea',
  THROTTLED_MANY_CHANGED_PHONE_NUMBERS: 'errors.throttledManyChangedPhoneNumbers',
  THROTTLED_MANY_CHANGED_ADDRESS: 'errors.throttledManyChangedAddress',
  EVENT_END_DATE_ERROR: 'errors.eventEndDateError',
  RESET_PASSWORD_TOKEN_EXPIRED: 'errors.resetPasswordTokenExpired',
  PERMISSION_DENIED: 'errors.permissionDenied',
  ABORTED: 'errors.aborted',
  INVALID_PIN_DROP_REQUIRED: 'errors.invalidPinDropRequired',
  INVALID_OPENING_DATE_TOO_FAR_IN_THE_FUTURE: 'errors.invalidOpeningDateTooFarInTheFuture',
  INVALID_ARGUMENT: 'errors.invalidArgument',
  INVALID_BAD_MEDIA: 'errors.invalidBadMedia',
  INVALID_FETCHING_IMAGE: 'errors.invalidFetchingImage',
  INVALID_VERIFICATION: 'errors.invalidVerification',
  FAILED_PRECONDITION: 'errors.failedPrecondition',
  BAD_REQUEST_DATE_IS_INVALID: 'errors.badRequestDateIsInvalid',
  BAD_REQUEST_DATE_EARLIER: 'errors.badRequestDateEarlier',
  BAD_REQUEST_DATE_LATER_YEAR: 'errors.badRequestDateLaterYear',
  BAD_REQUEST_SAVE_PHOTO_FILES: 'errors.badRequestSavePhotoFiles',
  BAD_REQUEST_CATEGORY_REQUIRES_LOCALIZATION: 'errors.badRequestCategoryRequiresLocalization',
  BAD_REQUEST_INCORRECT_CODE: 'errors.badRequestIncorrectCode',
  BAD_REQUEST_SAVE_PHOTO_DB: 'errors.badRequestSavePhotoDb',
  BAD_REQUEST_HAVE_LOCATION: 'errors.badRequestHaveLocation',
  BAD_REQUEST_ONLY_DEV: 'errors.badRequestOnlyDev',
  BAD_REQUEST_LOCATION: 'errors.badRequestLocation',
  INTERNAL_ERROR_BUSINESS_PROFILE_DENIED: 'errors.internalErrorBusinessProfileDenied',
  INTERNAL_ERROR_DB: 'errors.internalErrorDb',
  INTERNAL_ERROR_ENV: 'errors.internalErrorEnv',
  INTERNAL_ERROR_EMPTY_REFRESH_TOKEN: 'errors.internalErrorEmptyRefreshToken',
  INTERNAL_ERROR_PLACE: 'errors.internalErrorPlace',
  INTERNAL_ERROR_SERVICE_ITEM: 'errors.internalErrorServiceItem',
  INTERNAL_ERROR_PARSE_LOCAL_POST: 'errors.internalErrorParseLocalPost',
  CONTENT_LENGTH_REQUIRED: 'errors.contentLengthRequired',
  CONTENT_TOO_LARGE: 'errors.contentTooLarge',
  INVALID_BASE64_DATA: 'errors.invalidBase64Data',
  INVALID_BASE64_PREFIX: 'errors.invalidBase64Prefix',
  LOCATION_CANNOT_CREATE_OFFER: 'errors.locationCannotCreateOffer',
  NOT_IMPLEMENTED: 'errors.notImplemented',
  INVALID_SERVICE_ITEM: 'errors.invalidServiceItem',
  NO_BUSINESS_PROFILE_DATA: 'errors.noBusinessProfileData',
  TOO_MANY_REQUESTS: 'errors.tooManyRequests',
  GOOGLE_TOKEN_UNAUTHORIZED: 'errors.googleTokenUnauthorized',
  CARD_CODE_EXISTS: 'errors.cardCodeExists',
  LINK_CODE_EXISTS: 'errors.linkCodeExists',
  CARD_LINK_ERROR: 'errors.cardLinkError',
  DEPENDENCY_ERROR: 'errors.dependencyError',
  NOT_FOUND_CARD: 'errors.notFoundCard',
  USER_IS_NOT_MANAGER: 'errors.userIsNotManager',
  FEATURE_IS_NOT_IN_SUBSCRIPTION: 'errors.featureIsNotInSubscription',
  NOT_FOUND_PAYMENT: 'errors.notFoundPayment',
  NOT_FOUND_ORDER: 'errors.notFoundOrder',
  REPORT_LIMIT_REACHED: 'errors.reportLimitReached',
  SUBSCRIPTION_NOT_FOUND: 'errors.subscriptionNotFound',
  INVALID_NIP: 'errors.invalidNip',
  UNSUPPORTED_NIP_COUNTRY: 'errors.unsupportedNipCountry',
  TRIAL_USED: 'errors.trialUsed',
  BP_HAVE_SUBSCRIPTION: 'errors.bpHaveSubscription',
  NOT_FOUND_USER_INVOICE_DETAIL: 'errors.notFoundUserInvoiceDetail',
  INVALID_BUYER_DATA: 'errors.invalidBuyerData',
  INVALID_PAYU_DATA: 'errors.invalidPayuData',
  PAYU_RECURRING_ORDER_NEED_CARD_TOKEN: 'errors.payuRecurringOrderNeedCardToken',
  RECURRING_SUBSCRIPTION_NOT_FOUND: 'errors.recurringSubscriptionNotFound',
  INVALID_NEW_SUBSCRIPTION_TYPE: 'errors.invalidNewSubscriptionType',
  INVALID_NEW_SUBSCRIPTION_STATUS: 'errors.invalidNewSubscriptionStatus',
  PAYMENT_IN_PROGRESS: 'errors.paymentInProgress',
  INVOICE_DETAIL_ARE_USED: 'errors.invoiceDetailAreUsed',
  PAYU_ORDER_ID_NOT_FOUND: 'errors.payuOrderIdNotFound',
  INVALID_PUBLISH_DATE: 'errors.invalidPublishDate',
  CLIENT_HAVE_ACTIVE_SUBSCRIPTION: 'errors.clientHaveActiveSubscription',
  SUBSCRIPTION_IS_NOT_TRIAL: 'errors.subscriptionIsNotTrial',
};
export const handleApiError = ({ err, t, showNotification }) => {
  if (err.code === 'ERR_NETWORK') {
    console.error('Network error:', err);
  } else if (err.code === 'ERR_BAD_REQUEST') {
    console.error('Bad request:', err);
  }
  if (err.code !== 'ERR_CANCELED') {
    const { code } = err.response?.data ?? {};
    const { url } = err.response?.config ?? {};
    const path = t(ERR_PATH_CONFIG[url] || '');
    const message = path.length ? `${path}: ${t(ERR_CODE_ENUM_TRANSLATED[code])}` : t(ERR_CODE_ENUM_TRANSLATED[code]);
    return message
      ? showNotification({ label: t('global.error'), message, type: 'error' })
      : showNotification({ label: t('global.error'), message: t('global.error'), payload: err, type: 'error' });
  }
  return null;
};

export const isButtonDisabled = (errMessage, errConfirmationPasswordMessage, formData, passwordErrorsConfig) => errMessage.message !== passwordErrorsConfig.passwordIsOk
  || errConfirmationPasswordMessage.message !== passwordErrorsConfig.passwordsAreOk
  || !formData.currentPassword.length;
