import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { alphaNumericValidator, makeRequired, urlValidator } from '../../../../../shared/components/Input/validators';
import { formFieldEnum } from '../CreateSeoCardModal/utils';
import { ReactComponent as DeleteIcon } from '../../../../../shared/assets/close.svg';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const requiredAlphaNumeric = makeRequired(alphaNumericValidator(true), 4);

const LinkFormField = ({
  index,
  onLinkDelete,
  onInputChange,
  linkForm,
  onLinkIdExists,
  linkIdExist,
  onErrorLinkInputHandler,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const { link, linkName, linkCode } = linkForm;
  const isNotFirstLink = index !== 0;
  const { link: linkKey, linkCode: linkCodeKey, linkName: linkNameKey } = formFieldEnum;

  const checkLinkIdUniqueness = async (linkId) => {
    if (linkId.length < 4) {
      return;
    }
    const {
      data: { exists },
    } = await api.post(adminApiRoutes.card.cardLinkCodeExists, { linkCode: linkId });
    onLinkIdExists(exists);
  };

  useEffect(() => {
    if (linkIdExist) {
      showNotification({ type: 'error', message: t('adminControlPanel.linkIdExists') });
    }
  }, [linkIdExist]);

  return (
    <div className={classes.wrapper}>
      <div className={clsx(classes.redirectNameInputWrapper, isNotFirstLink && classes.withDeleteAction)}>
        <div className={classes.label}>
          {t('adminControlPanel.redirectName')}
          <Input
            admin
            onChange={onInputChange(linkNameKey)}
            className={classes.redirectNameInput}
            value={linkName}
            showErrorAfterBlur
            maxLength={50}
          />
        </div>
        {isNotFirstLink && <DeleteIcon className={classes.deleteIcon} onClick={onLinkDelete} />}
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.label}>
          {t('adminControlPanel.baseCode')}
          <Input
            admin
            onChange={onInputChange(linkCodeKey)}
            value={linkCode}
            validator={requiredAlphaNumeric}
            showErrorAfterBlur
            onBlur={checkLinkIdUniqueness}
            onFocus={() => onLinkIdExists(false)}
            onError={onErrorLinkInputHandler}
          />
          {linkIdExist && <span className={classes.error}>{t('adminControlPanel.linkIdExists')}</span>}
        </div>
        <div className={classes.label}>
          {t('adminControlPanel.redirectLink')}
          <Input
            admin
            onChange={onInputChange(linkKey)}
            value={link}
            validator={(v) => urlValidator(v, true)}
            showErrorAfterBlur
            className={classes.redirectLinkInput}
            onError={onErrorLinkInputHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default LinkFormField;
