import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { useDashboard } from '../../helpers/dashboard';
import { useModal } from '../../../../shared/helpers/hooks';
import Loader from '../../components/Loader';
import Pagination from '../../../../shared/components/Pagination';
import ModalForm from '../../modules/AddPost/ModalForm';
import PostList from '../../modules/AddPost/PostList';
import AddPostHeading from '../../modules/AddPost/AddPostHeading';
import { TAB_ENUM, fetchPostListHandler, getPostID, initialFilters, payloadEnum, profileStatus } from './utils';
import { getStatusToDisplay } from '../../components/DashboardLayout/utils';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';

const AddPost = ({ adminPreview, userId, businessProfileId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const {
    businessProfile: { id, name },
  } = useDashboard();
  const { showNotification } = useNotification();

  const { modalHandler: toggleFormModal, showModal: showFormModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState(null);
  const [posts, setPosts] = useState({ published: [], planned: [], totalNumber: 0 });
  const [editPost, setEditPost] = useState(null);
  const [selectedTab, setSelectedTab] = useState(TAB_ENUM.PUBLISHED);
  const [payload, setPayload] = useState({
    businessProfileId: id || businessProfileId,
    filter: initialFilters,
  });

  const isPaginationRendered = Boolean(posts.totalNumber) && selectedTab !== TAB_ENUM.PLANNED;
  const isEmpty = !posts[selectedTab].length;
  const businessProfileStatus = useCallback(getStatusToDisplay(id, t), [id]);

  const tabSelectHandler = (tab) => {
    setSelectedTab(tab);
  };

  const handleFiltersChange = (key) => (value) => {
    setPayload((prev) => {
      const newFilter = { ...prev.filter, [key]: value };
      if (key === payloadEnum.LIMIT) {
        newFilter[payloadEnum.OFFSET] = 0;
      } else if (key === payloadEnum.OFFSET) {
        newFilter[payloadEnum.OFFSET] = prev.filter.limit * (value - 1 || 0);
      }
      return { ...prev, filter: newFilter };
    });
  };

  const navigateToPost = useCallback(
    (pathname, search, hash) => {
      toggleFormModal();
      navigate({ pathname, search, hash });
    },
    [navigate],
  );

  const loaderHandler = (value) => {
    setIsLoading(value);
  };

  const editPostHandler = (post) => {
    const postId = post.state === 'PLANNED' ? post.id : getPostID(post.name);
    setEditPost(post);
    const { type, topicType } = post;
    const lowerCaseType = type ? type.toLowerCase() : topicType.toLowerCase();
    navigateToPost('/dashboard/post', `?id=${postId}&type=${lowerCaseType}`, '#update');
  };

  const createPost = () => {
    navigateToPost('/dashboard/post', null, '#add');
  };
  const page = useMemo(() => {
    const { offset, limit } = payload.filter;
    return offset === 0 ? 1 : Math.round(offset / limit) + 1;
  }, [payload.filter.offset, payload.filter.limit]);

  const fetchPostList = async (controller = new AbortController()) => {
    const fetchListData = { payload, api, businessProfileName: name, userId, controller };
    setIsLoading(true);
    try {
      const result = await fetchPostListHandler(fetchListData);
      setPosts(result);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };
  const fetchBusinessProfileData = async () => {
    try {
      let apiPath = '/business_profile/getBusinessProfile';
      apiPath = adminPreview ? '/acp/client/getBusinessProfile' : apiPath;
      const apiPayload = { businessProfileId: id || businessProfileId };
      if (adminPreview) {
        apiPayload.userId = userId;
      }
      const {
        data: {
          phoneNumbers: { primaryPhone },
        },
      } = await api.post(apiPath, apiPayload);
      setBusinessPhoneNumber({ phone: primaryPhone });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (payload.businessProfileId) {
      fetchPostList(controller);
    }
    return () => controller.abort();
  }, [payload]);

  useEffect(() => {
    if (!businessProfileStatus) {
      return;
    }

    const verfiedWithLowerCase = t(profileStatus.verified).toLowerCase();
    const busienssProfileStatusProvided = Object.values(businessProfileStatus).every((el) => el);
    const isNotificationDisplayed = busienssProfileStatusProvided
      && businessProfileStatus?.statusWithLowerCase !== verfiedWithLowerCase
      && !adminPreview;

    if (isNotificationDisplayed) {
      showNotification({
        type: 'warning',
        message: t('posts.profileNotVerified', { status: businessProfileStatus?.statusWithLowerCase }),
      });
    }
  }, [businessProfileStatus]);

  useEffect(() => {
    if (!showFormModal) {
      setEditPost(null);
    }
  }, [showFormModal]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchBpPhoneNumber = async () => {
      let apiApth = '/business_profile/getBusinessProfileNumber';
      apiApth = adminPreview ? '/acp/client/getBusinessProfileNumber' : apiApth;
      const apiPayload = { businessProfileId: id || businessProfileId };

      if (adminPreview) {
        apiPayload.userId = userId;
      }

      try {
        const { data } = await api.post(apiApth, apiPayload, { signal: controller.signal });
        setBusinessPhoneNumber(data);
      } catch (err) {
        if (err.response.data.code === 'NO_BUSINESS_PROFILE_DATA') {
          fetchBusinessProfileData();
          return;
        }
        handleApiError({ err, showNotification, t });
      }
    };
    if (id || businessProfileId) {
      fetchBpPhoneNumber();
    }

    return () => controller.abort();
  }, [businessProfileId, id, adminPreview, userId]);

  return (
    <div className={clsx(classes.container, adminPreview && classes.adminPreview)}>
      {isLoading && (
        <div className={classes.overlay}>
          <Loader />
        </div>
      )}
      {showFormModal && (
        <ModalForm
          modalHandler={toggleFormModal}
          editPost={editPost}
          loadingHandler={loaderHandler}
          fetchPosts={fetchPostList}
          businessPhoneNumber={businessPhoneNumber}
        />
      )}
      <div className={clsx(classes.body, isEmpty && classes.emptyWrapper)}>
        <AddPostHeading
          onTabSelect={tabSelectHandler}
          selectedTab={selectedTab}
          pillNumber={posts.totalNumber || 0}
          createPost={createPost}
          adminPreview={adminPreview}
        />
        <PostList
          isLoading={isLoading}
          posts={posts[selectedTab]}
          onEdit={editPostHandler}
          createPost={createPost}
          selectedTab={selectedTab}
          onSortChange={handleFiltersChange(payloadEnum.ORDER)}
          loadingHandler={loaderHandler}
          fetchPosts={fetchPostList}
          adminPreview={adminPreview}
          businessPhoneNumber={businessPhoneNumber}
        />
        {isPaginationRendered && (
          <Pagination
            onLimitChange={handleFiltersChange(payloadEnum.LIMIT)}
            onPageChange={handleFiltersChange(payloadEnum.OFFSET)}
            className={classes.pagination}
            limit={payload.filter.limit}
            page={page}
            total={posts.totalNumber}
          />
        )}
      </div>
    </div>
  );
};

export default AddPost;
