import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import Table from '../../../../shared/components/Table';
import { debounce } from '../../../../shared/helpers/debounce';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import { USER_LIST_NAVIGATION_KEY_ENUM, columnsConfig, getEmptyStateMessage, userDataParser } from './utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH, SORT_COLUMNS } from '../AdministratorList/utils';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import ViewWrapper from '../../components/ViewWrapper';
import ExpandedUserRow from '../../modules/UserList/ExpanedUserRow';
import classes from './styles.module.css';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const { clients } = USER_LIST_NAVIGATION_KEY_ENUM;
const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { login } = SORT_COLUMNS;

const UserList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();

  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '' });
  const [orderBy, setOrderBy] = useState({ column: login, direction: ASC });
  const [isLoading, setIsLoading] = useState(false);

  const emptyStateMessage = getEmptyStateMessage(t, filters, clients);

  const onUserEditClick = (row) => {
    const { id } = row;
    navigate(`/admin/users/${id}`);
  };

  const fetchList = async () => {
    setIsLoading(true);
    try {
      const filtersWithNoNullValue = removeNullValues(filters);
      const {
        data: { results, total },
      } = await api.post(adminApiRoutes.client.clientList, { filters: filtersWithNoNullValue, orderBy });
      setUserData(userDataParser(results, t));
      setTotalRecords(total);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const onExpandRowRender = (row) => (
    <ExpandedUserRow userData={row} setLoading={setIsLoading} tab={row.id} onListFetch={fetchList} />
  );

  useEffect(() => {
    fetchList();
  }, [orderBy, filters]);

  return (
    <ViewWrapper
      title={t('adminControlPanel.users', { value: totalRecords })}
      filters={filters}
      isLoading={isLoading && !userData.length}
      handleFiltersChange={handleFiltersChange}
      orderBy={orderBy}
      totalRecords={totalRecords}
    >
      <Table
        className={classes.tableWrapper}
        tableBodyStyle={classes.tableStyle}
        expandableRow
        data={userData}
        orderBy={orderBy}
        columns={columnsConfig(t)}
        emptyStateTitle={emptyStateMessage}
        onSortClick={sortHandler}
        editHandler={onUserEditClick}
        onExpandRowRender={onExpandRowRender}
      />
    </ViewWrapper>
  );
};

export default UserList;
