import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useDashboard } from '../../../helpers/dashboard';
import Input from '../../../../../shared/components/Input';
import { makeRequired } from '../../../../../shared/components/Input/validators';
import Button from '../../../../../shared/components/Button';
import { fetchBusienssProfileServiceArea } from './utils';
import { getBusinessLocationInputs } from '../../BusinessProfileCreate/FormBusinessLocation/utils';
import { generateUniqueOptions } from '../../../views/Verification/utils';
import { COUNTRIES, GOOGLE_VERIFY_URL } from '../../../helpers/constants';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/circleInfo.svg';
import { ReactComponent as LocationSVG } from '../../../../../shared/assets/businessLocation.svg';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const AddressForm = ({ getVerificationOptions, setAddress, standAlone, businessCreated }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const [country, setCountry] = useState(null);
  const [location, setLocation] = useState({
    postalCode: '',
    locality: '',
    addressLines: [],
    regionCode: 'PL',
  });

  const properId = businessCreated?.id || businessProfile?.id;
  const translatedBusinessLocationInputs = getBusinessLocationInputs(t);
  const someInputIsEmpty = Object.values(location).some((value) => !value);

  const handleLocation = (name) => (value) => {
    if (location[name] === value) {
      return;
    }
    setLocation((prev) => {
      const current = {
        ...prev,
        [name]: name === 'addressLines' ? [value] : value,
      };
      return current;
    });
  };

  const handleClick = async () => {
    const {
      data: { options },
    } = await api.post(apiRoutes.businessProfile.getVerificationOptions, {
      businessProfileId: businessProfile.id,
      context: {
        address: {
          ...location,
        },
      },
    });
    getVerificationOptions((prev) => {
      const unique = generateUniqueOptions(prev, options);
      return unique;
    });

    setAddress({
      address: {
        ...location,
      },
    });
  };

  const googleVerificationBtnHandler = () => {
    window.open(GOOGLE_VERIFY_URL, '_blank');
  };

  useEffect(() => {
    const controller = new AbortController();

    fetchBusienssProfileServiceArea({ api, id: properId, controller }).then((userCountry) => {
      setCountry(userCountry[0]);
      const countryCode = COUNTRIES.find(({ name }) => name === userCountry[0]).code;
      setLocation((prev) => ({
        ...prev,
        regionCode: countryCode,
      }));
    });

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className={clsx(classes.wrapper, standAlone && classes.standAloneWrapper)}>
      <div className={classes.content}>
        <div className={classes.leftSide}>
          <div className={classes.title}>{t('businessProfileCreate.missingDataTitle')}</div>
          <div className={classes.subTitle}>{t('businessProfileCreate.missingDataSubtitle')}</div>
          <div className={classes.form}>
            <Input readOnly label={t('businessProfileEdit.country')} value={country} />
            {translatedBusinessLocationInputs.map(({ transformer, label, name, showErrorAfterBlur, validator }) => (
              <Input
                key={name}
                transformer={transformer}
                className={classes.input}
                value={location[name]}
                label={label}
                onChange={handleLocation(name)}
                validator={makeRequired(validator)}
                showErrorAfterBlur={showErrorAfterBlur}
              />
            ))}
          </div>
          <div className={classes.helperText}>
            <InfoIcon className={classes.infoIcon} />
            {t('businessProfileCreate.missingDataHelperText')}
          </div>
        </div>
        <div className={classes.rightSide}>
          <LocationSVG className={classes.rightSide} />
        </div>
      </div>
      <div className={classes.actionsContainer}>
        <Button
          label={t('businessProfileCreate.verificationOnGoogle')}
          className={classes.googleVerificationBtn}
          onClick={googleVerificationBtnHandler}
        />
        <Button
          readOnly={someInputIsEmpty}
          label={t('businessProfileCreate.sendAddress')}
          className={classes.sendDataBtn}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default AddressForm;
