import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { ReactComponent as Rocket } from '../../../../../shared/assets/rocket.svg';
import { subscriptionsConfig } from '../../../helpers/constants';

const { basic, pro, trial, mini } = subscriptionsConfig;

const Header = ({ businessProfile }) => {
  const { t } = useTranslation();

  const subscriptionWithPermissionToFeautures = [basic, pro, trial, mini].includes(businessProfile?.subscription);

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <Rocket className={classes.icon} />
        <h3 className={classes.title}>{t('aiConfig.seoAssistant')}</h3>
        <Pill
          className={clsx(classes.assistantStatusPill, subscriptionWithPermissionToFeautures && classes.active)}
          label={subscriptionWithPermissionToFeautures ? t('aiConfig.active') : t('aiConfig.notActive')}
        />
      </div>
      <div className={classes.header}>
        <h3>
          {t('aiConfig.title1')}
          <span>{t('aiConfig.title2')}</span>
        </h3>
      </div>
    </div>
  );
};
export default Header;
