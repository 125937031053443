import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { seoCardListClientTableConfig } from '../List/utils';
import Button from '../../../../../shared/components/Button';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const CardItem = ({ item }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { api } = useApi();

  const navigateToDetails = useCallback(() => {
    navigate(seoRoutes.cardDetails(item.id));
  }, [navigate, item.id]);

  return (
    <div key={item.id} className={classes.wrapper}>
      {seoCardListClientTableConfig(t).map((config) => (
        <div className={classes.dataItemWrapper} key={config.header}>
          {Boolean(config.header.length) && <p className={classes.key}>{t(config.header)}</p>}
          <p className={classes.value}>{item[config.property]}</p>
        </div>
      ))}
      <Button label={t('global.details')} className={classes.detailsBtn} onClick={navigateToDetails}/>
    </div>
  );
};

export default CardItem;
