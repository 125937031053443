import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import Textarea from '../../../../../shared/components/Textarea';
import Button from '../../../../../shared/components/Button';
import { useNotification } from '../../../../../shared/helpers/notification';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { ANSWER_MAX_LENGTH_FIELD } from '../../../views/Opinions/utils';
import { StateEnum } from '../../../../../shared/helpers/state';
import Loader from '../../../components/Loader';
import { replaceWhitespace } from '../../../../../shared/components/Input/transformers';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const AnswerTemplateModal = ({ modalHandler, fetch, title, templateToChange }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [state, setState] = useState(StateEnum.initializing);
  const [answerTemplateText, setAnswerTemplateText] = useState('');

  const buttonLabel = templateToChange ? t('global.save') : t('opinions.addTemplate');

  const answerChangeHandler = (value) => setAnswerTemplateText(value);

  const onSaveHandler = async () => {
    const apiPath = templateToChange ? apiRoutes.reviewReplyTemplate.editTemplate : apiRoutes.reviewReplyTemplate.addTemplate;
    const parsedTemplateText = replaceWhitespace(answerTemplateText.trim());
    const payload = templateToChange
      ? { id: templateToChange.id, content: parsedTemplateText }
      : { content: parsedTemplateText };
    setState(StateEnum.loading);
    try {
      await api.post(apiPath, payload);
      showNotification({ label: t('global.success'), message: t('opinions.success'), type: 'success' });
      await fetch();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    modalHandler();
  };

  useEffect(() => {
    if (templateToChange) {
      setAnswerTemplateText(templateToChange.content);
    }
  }, [templateToChange]);

  return (
    <Modal
      contentStyle={classes.modalContent}
      onCancel={modalHandler}
      modalTitle={title}
      rwdBackTitle="modalMobile.goBackToTemplates"
    >
      {state === StateEnum.loading && (
        <div className={classes.overlay}>
          <Loader className={classes.loader} />
        </div>
      )}
      <div className={classes.modalContentAreaWrapper}>
        <Textarea
          maxLength={ANSWER_MAX_LENGTH_FIELD}
          value={answerTemplateText}
          onChange={answerChangeHandler}
          className={classes.modalTextArea}
          label={t('opinions.enterTemplateContent')}
        />
      </div>
      <div className={classes.actions}>
        <Button
          onClick={() => modalHandler(null)}
          label={t('global.cancel')}
          className={clsx(classes.modalButton, classes.cancelButton)}
        />
        <Button
          readOnly={!answerTemplateText.length}
          onClick={onSaveHandler}
          label={buttonLabel}
          className={classes.modalButton}
        />
      </div>
    </Modal>
  );
};
export default AnswerTemplateModal;
