import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CTAImage from '../../../../shared/assets/ctaImg.webp';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const CallToAction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateToRegister = () => {
    navigate(seoRoutes.registerMethods);
  };
  return (
    <section className={classes.ctaSection}>
      <div className={classes.leftSide}>
        <h2 className={classes.title}>{t('landingPage.callToActionTitle')}</h2>
        <p className={classes.description}>{t('landingPage.callToActionSubtitle')}</p>
        <Button label={t('landingPage.signUpForFree')} className={classes.signUpBtn} onClick={navigateToRegister} />
      </div>
      <div className={classes.rightSide}>
        <img className={classes.CTAImage} src={CTAImage} alt="CTAImage" />
      </div>
    </section>
  );
};

export default CallToAction;
