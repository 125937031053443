import React, { useContext } from 'react';

export const defaultAdmin = {
  name: null,
  lastName: null,
  fullName: null,
  login: null,
  role: null,
  id: null,
  isSuperAdmin: false,
  setAdmin: ({ id, login, name, lastName, role, isSuperAdmin }) => {
    throw 'Admin context is not initialized';
  },
  clearAdmin: () => {
    throw 'Admin context is not initialized';
  },
};

export const AdminContext = React.createContext(defaultAdmin);

export const useAdmin = () => useContext(AdminContext);
