import React from 'react';
import i18next from 'i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';

export const mobileListConfig = [
  {
    key: 'name',
    header: 'settings.businessCard',
    render: (val) => <div className={classes.businessCard}>{val}</div>,
    width: 0.75,
  },
  {
    key: 'businessCardStatus',
    header: 'settings.businessCardStatus',
    render: (val) => <Pill label={i18next.t(`settings.${val}`)} className={clsx(classes.pill, classes[val])} />,
    width: 0.5,
  },
  {
    key: 'subscription',
    header: 'settings.plan',
    render: (val) => <div className={classes.cell}>{val}</div>,
    width: 0.25,
  },
  {
    key: 'opinieoCount',
    header: 'settings.opinieoCards',
    render: (val) => <div className={classes.cell}>{val}</div>,
    width: 0.25,
  }
];
