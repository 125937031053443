import React from 'react';
import classes from './styles.module.css';

const CreateCardModalHeading = ({ stepText, title, titleSubInfo }) => (
  <div className={classes.modalHeader}>
    <div className={classes.stepWrapper}>{stepText}</div>
    <div className={classes.stepTitle}>{title}</div>
    <div className={classes.titleSubInfo}>{titleSubInfo}</div>
  </div>
);

export default CreateCardModalHeading;
