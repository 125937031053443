import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';

const ArrowButton = ({ direction, onClick }) => (
  <div onClick={() => onClick(direction)} className={classes.arrowWrapper}>
    <Arrow className={clsx(classes.arrow, direction === 'left' ? classes.arrowLeft : classes.arrowRight)} />
  </div>
);

export default ArrowButton;
