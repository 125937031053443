import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import UserData from '../../modules/AIConfig/UserData';
import Questionnaire from '../../modules/AIConfig/Questionnaire';
import Header from '../../modules/AIConfig/Header';
import { useAuth } from '../../../../shared/helpers/auth';
import Button from '../../../../shared/components/Button';
import { subscriptionsConfig } from '../../helpers/constants';
import BusinessProfileWithNoAccess from '../../modules/AIConfig/BusinessProfileWithNoAccess';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const { trial, basic, pro, mini } = subscriptionsConfig;

const AIConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { businessProfiles } = useAuth();

  const [businessProfileData, setBusinessProfileData] = useState({});

  const businessProfileId = pathname.split('/').pop();
  const isPermittedSubscription = [mini, trial, basic, pro].includes(businessProfileData.subscription);

  const goBackHandler = () => {
    navigate(-1);
  };

  useEffect(() => {
    const foundProfile = businessProfiles.find((item) => String(item.id) === String(businessProfileId));
    if (!foundProfile) {
      navigate(seoRoutes.dashboard);
    }
    setBusinessProfileData(foundProfile);
  }, []);

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.wrapper}>
        <Header businessProfile={businessProfileData} />
        <UserData businessProfile={businessProfileData} />
        {isPermittedSubscription ? (
          <Questionnaire businessProfile={businessProfileData} />
        ) : (
          <BusinessProfileWithNoAccess />
        )}
      </div>
      <Button label={t('aiConfig.resignation')} className={classes.button} onClick={goBackHandler} />
    </div>
  );
};
export default AIConfig;
