import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApiContext, makeApiProvider } from '../../helpers/api';
import { getStorageTokens, useAuth } from '../../helpers/auth';
import WelcomeLayout from '../../../app/SEO/components/Layout';
import ReportLayout from '../../../app/SEO/components/ReportLayout';
import RegisterMethods from '../../../app/SEO/views/RegisterMethods';
import Welcome from '../../../app/SEO/views/Welcome';
import AddPost from '../../../app/SEO/views/AddPost';
import Login from '../../../app/SEO/views/Login';
import GenerateReport from '../../../app/SEO/views/GenerateReport';
import PickBusinessProfiles from '../../../app/SEO/views/PickBusinessProfiles';
import Dashboard from '../../../app/SEO/views/Dashboard';
import ReportList from '../../../app/SEO/views/ReportList';
import BusinessProfileEdit from '../../../app/SEO/views/BusinessProfileEdit';
import SetBusinessKeyWords from '../../../app/SEO/views/SetBusinessKeyWords';
import Opinions from '../../../app/SEO/views/Opinions/index';
import AnswerTemplates from '../../../app/SEO/views/AnswerTemplates';
import RemindPassword from '../../../app/SEO/views/RemindPassword';
import Settings from '../../../app/SEO/views/Settings';
import SetPassword from '../../../app/SEO/views/SetPassword';
import BusinessProfileCreate from '../../../app/SEO/views/BusinessProfileCreate';
import { useNotification } from '../../helpers/notification';
import VerificationView from '../../../app/SEO/views/Verification';
import BusinessProfileChangeHistory from '../../../app/SEO/views/BusinessProfileChangeHistory';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { fetchAdminSession, fetchSeoSession } from './utils';
import AdminLayout from '../../../app/Admin/components/AdminLayout';
import DashboardLayout from '../../../app/SEO/components/DashboardLayout';
import WelcomeAdminLayout from '../../../app/Admin/components/AdminWelcomeLayout';
import AdminLogin from '../../../app/Admin/views/AdminLogin';
import RemindAdminPassword from '../../../app/Admin/views/RemindAdminPassword';
import SetAdminPassword from '../../../app/Admin/views/SetAdminPassword';
import { useAdmin } from '../../../app/Admin/helpers/admin';
import AdminSettings from '../../../app/Admin/views/AdminSettings';
import AdministratorList from '../../../app/Admin/views/AdministratorList';
import AdministratorDetails from '../../../app/Admin/views/AdministratorDetails';
import UserList from '../../../app/Admin/views/UserList';
import UserDetails from '../../../app/Admin/views/UserDetails';
import SeoCards from '../../../app/Admin/views/SeoCards';
import SeoCardDetails from '../../../app/Admin/views/SeoCardDetails';
import LandingPage from '../../../app/LandingPage';
import ClientSeoCardList from '../../../app/SEO/views/ClientSeoCardList';
import ClientSeoCardDetails from '../../../app/SEO/views/ClientSeoCardDetails';
import PrivacyPolicy from '../../../app/LandingPage/modules/PrivacyPolicy';
import TermsOfService from '../../../app/LandingPage/modules/TermsOfService';
import Reauthorization from '../../../app/SEO/views/Reauthorization';
import GoogleAuthorizationModal from '../GoogleAuthorizationModal';
import MediaView from '../../../app/SEO/views/Media';
import Subscription from '../../../app/SEO/views/Subscription';
import ManagerList from '../../../app/Admin/views/ManagerList';
import ManagerDetails from '../../../app/Admin/views/ManagerDetails';
import LoaderOverlay from '../LoaderOverlay';
import Notifications from '../../../app/SEO/views/Notifications';
import { adminRoutes, seoRoutes } from '../../helpers/innerRoutes';
import { apiRoutes } from '../../helpers/apiRoutes';
import AIConfig from '../../../app/SEO/views/AIConfig';

const Router = () => {
  const { pathname, search } = useLocation();

  const { isAuth, setAuth, clearAuth, isAdmin, isManager } = useAuth();
  const { setAdmin, role, clearAdmin } = useAdmin();
  const { showNotification } = useNotification();
  const [showUnauthorizedModal, setShowUnauthorizedModal] = useState(false);
  const [tokens, setTokens] = useState(getStorageTokens());
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const isModerator = role === 'moderator';

  const apiProvider = useMemo(() => {
    const onLogout = () => {
      clearAuth();
    };
    const onAdminLogut = () => {
      clearAdmin();
    };
    const handleTokens = ({ token, refreshToken, adminToken, refreshAdminToken }) => {
      setTokens((prev) => ({ ...prev, token, refreshToken, adminToken, refreshAdminToken }));
    };

    const api = makeApiProvider({
      tokens,
      setTokens: handleTokens,
      onLogout,
      onAdminLogut,
      setSession: setAuth,
      setAdmin,
      pathname,
      setShowUnauthorizedModal,
    });
    return { tokens, setTokens, api, onLogout };
  }, [tokens, setTokens, setAuth, clearAuth, pathname]);

  useEffect(() => {
    const controller = new AbortController();
    const { api } = apiProvider;
    const isAdminControlPanelOrigin = pathname.includes(adminRoutes.admin);
    if (isAdminControlPanelOrigin) {
      fetchAdminSession({ api, controller, setAuth, handleApiError, t, showNotification, setAdmin });
    } else {
      fetchSeoSession({ api, controller, setAuth, handleApiError, t, showNotification });
    }

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setTokens(getStorageTokens());
  }, [
    localStorage.getItem('token'),
    localStorage.getItem('refreshToken'),
    localStorage.getItem('adminToken'),
    localStorage.getItem('refreshAdminToken'),
  ]);

  const grandAuthorization = async (code) => {
    const { api } = apiProvider;
    setIsLoading(true);
    try {
      await api.post(apiRoutes.auth.reauthorization, { code });
      showNotification({ message: t('Reauthorization successful'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === seoRoutes.reauthorization) {
      const queryParams = new URLSearchParams(search);
      const code = queryParams.get('code');

      if (code) {
        grandAuthorization(code);
      }
    }
  }, [pathname]);

  if (isAuth === null || isAdmin === null || isLoading) {
    return <LoaderOverlay />;
  }

  return (
    <ApiContext.Provider value={apiProvider}>
      {showUnauthorizedModal && isAuth && <GoogleAuthorizationModal />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/politykaprywatnosci" element={<PrivacyPolicy />} />
        <Route path="/regulamin" element={<TermsOfService />} />
        <Route
          path="*"
          element={
            isAuth && !isAdmin ? <Navigate to={seoRoutes.dashboard} /> : <Navigate to={seoRoutes.welcome.slice(1)} />
          }
        />
        {!isAuth ? (
          <Route path="/*" element={<WelcomeLayout />}>
            <Route path={seoRoutes.welcome.slice(1)} element={<Welcome />} />
            <Route path={seoRoutes.registerMethods.slice(1)} element={<RegisterMethods />} />
            <Route path={seoRoutes.login.slice(1)} element={<Login />} />
            <Route path={seoRoutes.setPassword.slice(1)} element={<SetPassword />} />
            <Route path={seoRoutes.reauthorization.slice(1)} element={<Reauthorization />} />
            <Route path={seoRoutes.remindPassword.slice(1)} element={<RemindPassword />} />
          </Route>
        ) : (
          <>
            <Route path="/*" element={<ReportLayout />}>
              <Route path={seoRoutes.report.slice(1)} element={<GenerateReport />} />
              <Route path={seoRoutes.importProfiles.slice(1)} element={<PickBusinessProfiles />} />
              <Route path={seoRoutes.setFilters.slice(1)} element={<SetBusinessKeyWords />} />
              <Route path={seoRoutes.createBusinessProfileStandAlone.slice(1)} element={<BusinessProfileCreate />} />
              <Route path={`${seoRoutes.aiConfig.slice(1)}/*`} element={<AIConfig />} />
            </Route>
            <Route path={seoRoutes.dashboard} element={<DashboardLayout />}>
              <Route path="" element={<Dashboard />} />
              <Route path={seoRoutes.reportList} element={<ReportList />} />
              <Route path={seoRoutes.editBusinessProfile} element={<BusinessProfileEdit />} />
              <Route path={seoRoutes.changeHistory} element={<BusinessProfileChangeHistory />} />
              <Route path={seoRoutes.localPost} element={<AddPost />} />
              <Route path={seoRoutes.opinions} element={<Opinions />} />
              <Route path={seoRoutes.answerTemplates} element={<AnswerTemplates />} />
              <Route path={seoRoutes.media} element={<MediaView />} />
              <Route path={seoRoutes.createBusinessProfile} element={<BusinessProfileCreate />} />
              <Route path={seoRoutes.verification} element={<VerificationView />} />
              <Route path={seoRoutes.opinieoCards} element={<ClientSeoCardList />} />
              <Route path={seoRoutes.notifications} element={<Notifications />} />
              <Route path="settings/*" element={<Settings />} />
              <Route path="cards/:id" element={<ClientSeoCardDetails />} />
              {!isManager && <Route path={seoRoutes.subscription} element={<Subscription />} />}
            </Route>
          </>
        )}
        <Route path={adminRoutes.changePasswordByEmail} element={<WelcomeAdminLayout />}>
          <Route path="" element={<SetAdminPassword />} />
        </Route>
        <Route
          path="/admin/*"
          element={isAdmin ? <Navigate to={adminRoutes.admin} /> : <Navigate to={adminRoutes.auth} />}
        />
        {isAdmin ? (
          <Route path={adminRoutes.admin} element={<AdminLayout />}>
            {!isModerator && (
              <>
                <Route path={adminRoutes.administrators} element={<AdministratorList />} />
                <Route path="administrators/:id" element={<AdministratorDetails />} />
              </>
            )}
            <Route path={adminRoutes.users} element={<UserList />} />
            <Route path={adminRoutes.managers} element={<ManagerList />} />
            <Route path={adminRoutes.cards} element={<SeoCards />} />
            <Route path="users/:id" element={<UserDetails />} />
            <Route path="managers/:id" element={<ManagerDetails />} />
            <Route path="cards/:id" element={<SeoCardDetails />} />
            <Route path="settings/*" element={<AdminSettings />} />
          </Route>
        ) : (
          <Route path={adminRoutes.auth} element={<WelcomeAdminLayout />}>
            <Route path="" element={<AdminLogin />} />
            <Route path={adminRoutes.remindPassword} element={<RemindAdminPassword />} />
          </Route>
        )}
      </Routes>
    </ApiContext.Provider>
  );
};

export default Router;
