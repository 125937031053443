import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as RemoveIcon } from '../../../../../shared/assets/trashAlt.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/editPencil.svg';
import { ReactComponent as Copy } from '../../../../../shared/assets/copy.svg';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const AnswerTemplateItem = ({ answer, fetch, onEdit, className, onClick, userId }) => {
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const [showMoreActive, setShowMoreActive] = useState(false);

  const toggleShowMoreActive = () => setShowMoreActive((prev) => !prev);
  const useTemplateHandler = () => onClick(answer.content);
  const editTemplateHandler = () => onEdit(answer.id);

  const deleteTemplate = async () => {
    const { id } = answer;
    try {
      await api.post(apiRoutes.reviewReplyTemplate.deleteTemplate, { id });
      await fetch();
      showNotification({ label: 'Success', message: t('opinions.success'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  return (
    <div className={clsx(styles.wrapper, className, showMoreActive && styles.showLongTemplate)}>
      {onEdit && !userId && (
        <div className={styles.actions}>
          <div className={styles.rowActionBox}>
            <div onClick={editTemplateHandler} className={styles.actionDescription}>
              {t('opinions.editTemplate')}
            </div>
            <EditIcon onClick={editTemplateHandler} className={styles.actionIcon} />
          </div>
          <div className={styles.rowActionBox}>
            <div onClick={deleteTemplate} className={styles.actionDescription}>
              {t('opinions.deleteTemplate')}
            </div>
            <RemoveIcon className={styles.actionIcon} onClick={deleteTemplate} />
          </div>
        </div>
      )}
      <div>
        <div className={clsx(styles.answer, showMoreActive && styles.showFull)}>{answer.content}</div>
        {answer.content.length > 60 && (
          <Button
            className={styles.showMoreBtn}
            label={showMoreActive ? t('global.showLess') : t('global.showMore')}
            onClick={toggleShowMoreActive}
          />
        )}
      </div>
      {!onEdit && (
        <Button
          onClick={useTemplateHandler}
          iconWidth={22}
          className={styles.useTemplateBtn}
          Icon={Copy}
          label={t('opinions.useTemplate')}
        />
      )}
    </div>
  );
};

export default AnswerTemplateItem;
