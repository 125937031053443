import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import { renderStatus, renderDate, renderTypePill } from '../../../views/Notifications/utils';

const NotificationCardList = ({ data, selectedNotifications, handleListAction }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.list}>
      {data.map((row) => (
        <div key={row.id} className={classes.card}>
          <div className={classes.header}>
            <div className={classes.left}>
              <Checkbox
                className={classes.checkbox}
                checkboxStyle={classes.checkboxStyle}
                value={selectedNotifications.includes(row.id)}
                onClick={() => handleListAction(row.id)}
              />
              {renderDate(row.date)}
            </div>
            <div className={classes.right}>
              {renderTypePill(row.type)}
              {renderStatus(row.date)}
            </div>
          </div>
          <div className={classes.body}>
            {row.connectedBusinessProfile.image && (
              <div className={classes.imageContainer}>
                <img src={row.connectedBusinessProfile.image} alt="business profile" className={classes.image} />
              </div>
            )}
            {row.connectedBusinessProfile.name && row.connectedBusinessProfile.address && (
            <div>
              <p className={classes.title}>{row.connectedBusinessProfile.name}</p>
              <p className={classes.adress}>{row.connectedBusinessProfile.address}</p>
            </div>
            )}
          </div>
          <div className={classes.footer}>{t(row.content)}</div>
        </div>
      ))}
    </div>
  );
};
export default NotificationCardList;
