import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { cardListCollection } from './utils';
import WhyIsWorthCard from '../WhyIsWorthCard';
import Button from '../../../../shared/components/Button';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const WhyIsWorth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigationToSignUp = () => {
    navigate(seoRoutes.welcome);
  };
  return (
    <section id="whyIsworth" className={classes.sectionWrapper}>
      <h2 className={classes.sectionTitle}>{t('landingPage.whyIsWorth')}</h2>
      <div className={classes.cardCollectionWrapper}>
        {cardListCollection(t).map((card) => (
          <WhyIsWorthCard key={card.title} card={card} className={classes.singleCardWrapper} />
        ))}
      </div>
      <div className={classes.doYouKnow}>
        <div className={classes.blurCircle} />
        <div className={classes.blurCircle} />
        <h2 className={classes.doYouKnowTitle}>{t('landingPage.doYouKnowTitle')}</h2>
        <p className={classes.doYouKnowDescription}>{t('landingPage.doYouKnowDescription')}</p>
        <Button
          label={t('landingPage.signUpForFree')}
          className={classes.signUpBtn}
          onClick={handleNavigationToSignUp}
        />
      </div>
    </section>
  );
};

export default WhyIsWorth;
