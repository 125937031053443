import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../../../../../shared/components/Modal';
import Button from '../../../../../shared/components/Button';
import Calendar from '../../../../../shared/components/Calendar';
import Table from '../../../../../shared/components/Table';
import AdminPagination from '../../../components/AdminPagination';
import Pagination from '../../../../../shared/components/Pagination';
import Select from '../../../../../shared/components/Select';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { getSelectDateLabel } from '../CardStatistic/utils';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { clientLimitList, limitListDaily, parseLinkData, rangeValues, singleLinkStatisticTableConfig } from './utils';
import { FILTERS_ENUM } from '../../../views/AdministratorList/utils';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import { exportCSVDataHandler } from '../../../helpers/utils';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { apiRoutes, adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { MIN_TABLET_WIDTH } from '../../../../SEO/helpers/constants';
import classes from './styles.module.css';

const { ASC, DESC } = SORT_DIRECTION_ENUM;

const LinkStatisticModal = ({ link, onClose, admin, cardData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const paginiationLimitList = admin ? limitListDaily : clientLimitList;

  const [selectedDate, setSelectedDate] = useState({ dateFrom: '', dateTo: '' });
  const [showCalendar, setShowCalendar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkStatisticData, setLinkStatisticData] = useState();
  const [totalNumber, setTotalNumber] = useState();
  const [filters, setFilters] = useState({
    limit: paginiationLimitList[0],
    offset: 0,
  });
  const [orderBy, setOrderBy] = useState({ column: 'counted_hour', direction: 'ASC' });

  const modalTitle = link.linkName ? `${link.linkName} \n${link.linkCode}` : link.linkCode;
  const btnLabel = getSelectDateLabel(selectedDate, t);
  const isMobile = useIsMobile(MIN_TABLET_WIDTH);
  const mobileModalTitle = isMobile ? t('global.back') : null;
  const translatedOptions = rangeValues(t);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const handleDateChangeFetchData = async ({ dateFrom, dateTo }) => {
    setIsLoading(true);
    const parsedFilters = removeNullValues({
      linkId: link.id,
      orderBy,
      ...filters,
      dateFrom,
      dateTo,
    });
    try {
      const apiPath = admin ? adminApiRoutes.card.linkStatistic : apiRoutes.card.linkStatistic;
      const {
        data: { results, total },
      } = await api.post(apiPath, {
        filters: parsedFilters,
      });
      setLinkStatisticData(parseLinkData({ results, admin, groupRange: filters?.groupRange }));
      setTotalNumber(total);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };
  const onCloseCalendar = () => {
    setSelectedDate(null);
    handleDateChangeFetchData({});
    toggleCalendar();
  };

  const onDateChangeHandler = (value) => {
    if (!value) {
      setSelectedDate(null);
      setFilters((prev) => ({ ...prev, offset: 0 }));
      handleDateChangeFetchData({});
    }
    setSelectedDate(value);
    handleDateChangeFetchData({ dateFrom: value.dateFrom, dateTo: value.dateTo });
    toggleCalendar();
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0 }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };
  const handleRecordAggregationChange = (value) => {
    const foundValue = translatedOptions.find((el) => el.id === value).value;
    setFilters((prev) => ({ ...prev, groupRange: foundValue }));
    setSelectedDate(null);
    setLinkStatisticData([]);
  };

  const handleExportStatistic = (origin) => async () => {
    const parsedFilters = removeNullValues({
      linkId: link.id,
      orderBy,
      dateFrom: selectedDate?.dateFrom,
      dateTo: selectedDate?.dateTo,
      groupRange: filters?.groupRange,
    });
    const apiPath = origin === 'admin' ? adminApiRoutes.card.linkStatisticsExport : apiRoutes.card.linkStatisticsExport;
    try {
      const response = await api.post(apiPath, { filters: { ...parsedFilters, orderBy } }, { responseType: 'blob' });
      const fileName = 'link_statistic';
      exportCSVDataHandler({ response, fileName, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  const page = useMemo(
    () => (filters.offset === 0 ? 1 : Math.round(filters.offset / filters.limit) + 1),
    [filters.offset, filters.limit],
  );

  useEffect(() => {
    handleDateChangeFetchData({ dateFrom: selectedDate?.dateFrom, dateTo: selectedDate?.dateTo });
  }, [orderBy, filters]);

  if (!linkStatisticData) {
    return <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />;
  }
  return (
    <Modal
      onCancel={onClose}
      titleStyle={clsx(classes.titleStyle, !admin && classes.clientTitle)}
      modalTitle={modalTitle}
      contentStyle={clsx(classes.modal, showCalendar && classes.modalWithCalendar)}
      onBack={() => onClose()}
      rwdBackTitle={mobileModalTitle}
    >
      {showCalendar && (
        <Calendar
          onClose={onCloseCalendar}
          futureDaysNotAvailable
          quickSelect
          admin={admin}
          onChange={onDateChangeHandler}
          selectedDate={selectedDate?.dateFrom}
          selectedEndPeriodDate={selectedDate?.dateTo}
          monthOnly={filters.groupRange === '1 month'}
          availableStartDate={cardData.createdAt}
          isSingleMonth={false}
        />
      )}
      {(isLoading || !linkStatisticData) && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      <div className={clsx(classes.heading, !admin && classes.clientHeading)}>
        <div>{link.link}</div>
      </div>
      <div className={classes.toolsWrapper}>
        {admin && (
          <Select
            label={t('adminControlPanel.agregationType')}
            admin
            list={translatedOptions}
            showOnList={(v) => v?.label}
            value={filters?.groupRange || translatedOptions[0].id}
            onSelect={handleRecordAggregationChange}
            className={classes.selectClass}
          />
        )}
        <Button
          label={btnLabel}
          Icon={CalendarIcon}
          className={clsx(classes.datePickerBtn, !admin && classes.clientBtn)}
          onClick={toggleCalendar}
          iconStyle={classes.calendarIconStyle}
        />
      </div>
      <div className={classes.body}>
        <Table
          columns={singleLinkStatisticTableConfig(t, admin)}
          data={linkStatisticData}
          edit={false}
          orderBy={orderBy}
          className={classes.table}
          tableBodyStyle={classes.bodyTableClass}
          onSortClick={sortHandler}
          emptyStateTitle={t('adminControlPanel.noData')}
          admin={admin}
        />
        {admin ? (
          <AdminPagination
            limit={filters.limit}
            offset={filters.offset}
            total={totalNumber}
            onLimitChange={handleFiltersChange('limit')}
            onPageChange={handleFiltersChange('offset')}
            paginationLimitList={limitListDaily}
            onExportData={handleExportStatistic('admin')}
          />
        ) : (
          <Pagination
            limit={filters.limit}
            total={totalNumber}
            onPageChange={handleFiltersChange('offset')}
            onLimitChange={handleFiltersChange('limit')}
            className={classes.pagination}
            page={page}
            paginationLimitList={paginiationLimitList}
            withExport
            exportHandler={handleExportStatistic('client')}
          />
        )}
      </div>
    </Modal>
  );
};

export default LinkStatisticModal;
