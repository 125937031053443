import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as CircleInfo } from '../../assets/circleInfo.svg';

const SubInformation = ({ className, label }) => (
  <div className={clsx(classes.subInformationBox, className)}>
    <CircleInfo className={classes.icon} />
    <div className={classes.label}>{label}</div>
  </div>
);
export default SubInformation;
