import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { SIGN_INPUT_MAX_LENGTH } from '../Questionnaire/utils';
import { replaceWhitespace } from '../../../../../shared/components/Input/transformers';

const SignSection = ({ value, handleFormChange }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.questionnaireSection}>
      <span className={classes.question}>{t('aiConfig.signLabel')}</span>
      <div className={classes.keywordsWrapper}>
        <Input
          className={classes.input}
          value={value}
          onChange={handleFormChange}
          maxLength={SIGN_INPUT_MAX_LENGTH}
          transformer={replaceWhitespace}
        />
      </div>
    </div>
  );
};

export default SignSection;
