import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../shared/components/Button';
import { useAdmin } from '../../helpers/admin';
import { useAuth } from '../../../../shared/helpers/auth';
import { ReactComponent as Chevron } from '../../../../shared/assets/chevronDown.svg';
import { ReactComponent as AdminSettings } from '../../../../shared/assets/adminSettings.svg';
import { ReactComponent as Logout } from '../../../../shared/assets/logoutACP.svg';
import { ReactComponent as Bell } from '../../../../shared/assets/bell.svg';
import { useCloseOnOutsideClick } from '../../../../shared/helpers/hooks';
import { adminRoutes } from '../../../../shared/helpers/innerRoutes';

const { auth, settings } = adminRoutes;

const TopbarMenu = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fullName, login, clearAdmin } = useAdmin();
  const { clearAuth } = useAuth();
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useRef();

  const toggleMenuHandler = () => {
    setShowMenu((prev) => !prev);
  };

  const navigateToSettings = () => {
    navigate(settings);
    toggleMenuHandler();
  };

  const handleLogout = () => {
    clearAdmin();
    clearAuth();
    localStorage.removeItem('adminToken');
    localStorage.removeItem('refreshAdminToken');
    navigate(auth);
    toggleMenuHandler();
  };

  useCloseOnOutsideClick(menuRef, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  return (
    <div ref={menuRef} className={classes.wrapper}>
      <Button Icon={Bell} className={classes.notificationBtn} />
      <div className={classes.userData}>
        <div className={classes.fullName}>{fullName}</div>
        <div className={classes.login}>{login}</div>
        {showMenu && (
          <div className={classes.menu}>
            <div onClick={navigateToSettings} className={classes.menuItem}>
              <AdminSettings className={classes.menuIcon} />
              {t('global.settings')}
            </div>
            <div onClick={handleLogout} className={classes.menuItem}>
              <Logout className={classes.menuIcon} />
              {t('global.logout')}
            </div>
          </div>
        )}
      </div>
      <Chevron onClick={toggleMenuHandler} className={clsx(classes.chevronIcon, showMenu && classes.rotatedIcon)} />
    </div>
  );
};

export default TopbarMenu;
