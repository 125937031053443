import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import Button from '../../../../../shared/components/Button';
import ManageService from '../ManageService';
import { PriceEnums } from '../ServicesForm/utils';
import { POLISH_CURRENCY } from '../../../helpers/constants';
import { ReactComponent as TrashIcon } from '../../../../../shared/assets/bin.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/editPost.svg';

const ServiceItem = ({
  service,
  onChange,
  category,
  onServiceDelete,
  readOnly,
  isEdit,
  adminPreview,
  isReadOnlySubPlan,
}) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [descriptionBoxActive, setDescriptionBoxActive] = useState(false);
  const isActionRendered = !adminPreview && !isReadOnlySubPlan;

  const openEditModalHandler = () => {
    if (readOnly) return;
    setModal((prev) => !prev);
  };

  return (
    <>
      {modal && (
        <div className={classes.modalWrapper}>
          <Modal
            onCancel={() => setModal(false)}
            modalTitle={t('businessProfileEdit.editServiceDetail')}
            contentStyle={classes.content}
            rwdBackTitle={t('global.goBack')}
          >
            <ManageService
              category={category}
              onChange={onChange}
              service={service}
              onModalClose={() => setModal(false)}
              onDeleteService={() => onServiceDelete(service.serviceTypeId, category)}
            />
          </Modal>
        </div>
      )}
      <div className={clsx(classes.serviceItem)}>
        <div className={classes.serviceDetails}>
          <div className={clsx(classes.serviceName, descriptionBoxActive && classes.serviceItemActiveDescription)}>
            {service?.displayName}
          </div>

          {![PriceEnums.noPrice].includes(service.priceType) && service?.price && (
            <div className={classes.servicePrice}>
              <div>{`${service?.price} ${service?.currencyCode ?? POLISH_CURRENCY}`}</div>
            </div>
          )}
          {isActionRendered && (
            <div className={clsx(classes.actions, !isEdit && classes.noUserClick)}>
              <EditIcon
                onClick={openEditModalHandler}
                className={clsx(classes.trashIcon, !isEdit && classes.noUserClick)}
              />
              <TrashIcon
                onClick={() => onServiceDelete(service.serviceTypeId, category)}
                className={clsx(classes.trashIcon, !isEdit && classes.noUserClick)}
              />
            </div>
          )}
        </div>
        <div>
          {service?.description && (
            <div
              className={clsx(classes.serviceDescription, descriptionBoxActive && classes.serviceItemActiveDescription)}
            >
              {service?.description}
            </div>
          )}
          {(service?.description?.length > 30 || service.displayName.length > 30) && (
            <div onClick={() => setDescriptionBoxActive((prev) => !prev)} className={classes.showMore}>
              {descriptionBoxActive ? t('global.showLess') : t('global.showMore')}
            </div>
          )}
        </div>
        {isActionRendered && (
          <Button onClick={openEditModalHandler} className={classes.rwdEditService} label="Edytuj usługę" />
        )}
      </div>
      <div className={classes.horizontalLine} />
    </>
  );
};
export default ServiceItem;
