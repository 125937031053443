import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useModal } from '../../../../shared/helpers/hooks';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import Table from '../../../../shared/components/Table';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH, SORT_COLUMNS } from '../AdministratorList/utils';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import {
  columnsConfigManager,
  getEmptyStateMessage,
  USER_LIST_NAVIGATION_KEY_ENUM,
  userDataParser,
} from '../UserList/utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import classes from './styles.module.css';
import AddManagerModal from '../../modules/Manager/AddManagerModal';
import ViewWrapper from '../../components/ViewWrapper';
import ManagerExpandedRow from '../../modules/Manager/ManagerExpandedRow';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { login } = SORT_COLUMNS;
const ManagerList = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '' });
  const [orderBy, setOrderBy] = useState({ column: login, direction: ASC });
  const [isLoading, setIsLoading] = useState(false);

  const { modalHandler: toggleAddManagerModalForm, showModal: addManagerModalFormOpen } = useModal();
  const emptyStateMessage = getEmptyStateMessage(t, filters, USER_LIST_NAVIGATION_KEY_ENUM.managers);

  const fetchList = async () => {
    setIsLoading(true);
    try {
      const filtersWithNoNullValue = removeNullValues(filters);
      const {
        data: { results, total },
      } = await api.post(adminApiRoutes.client.managerList, { filters: filtersWithNoNullValue, orderBy });
      setUserData(userDataParser(results, t));
      setTotalRecords(total);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const onExpandRowRender = (row) => (
    <ManagerExpandedRow
      rowData={row}
      setLoading={setIsLoading}
      tab={USER_LIST_NAVIGATION_KEY_ENUM.managers}
      onListFetch={fetchList}
    />
  );
  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };
  const onUserEditClick = (row) => {
    const { id } = row;
    navigate(`/admin/managers/${id}`);
  };

  useEffect(() => {
    fetchList();
  }, [orderBy, filters]);

  return (
    <div>
      {addManagerModalFormOpen && <AddManagerModal onClose={toggleAddManagerModalForm} onListFetch={fetchList} />}
      <ViewWrapper
        title={t('adminControlPanel.managers', { value: totalRecords })}
        isLoading={isLoading && !userData.length}
        filters={filters}
        onCallToActionClick={toggleAddManagerModalForm}
        handleFiltersChange={handleFiltersChange}
        totalRecords={totalRecords}
        orderBy={orderBy}
        addBtnLabel={t('adminControlPanel.addManager')}
        onAddButtonClick={toggleAddManagerModalForm}
      >
        <div className={classes.body}>
          <Table
            className={classes.tableWrapper}
            tableBodyStyle={classes.tableStyle}
            expandableRow
            data={userData}
            orderBy={orderBy}
            columns={columnsConfigManager(t)}
            emptyStateTitle={emptyStateMessage}
            onSortClick={sortHandler}
            editHandler={onUserEditClick}
            onExpandRowRender={onExpandRowRender}
          />
        </div>
      </ViewWrapper>
    </div>
  );
};

export default ManagerList;
