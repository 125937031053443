import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ViewTitle from '../../components/ViewTitle';
import classes from './styles.module.css';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { getButtonProp, initUserData, parseUserData } from './utils';
import Button from '../../../../shared/components/Button';
import UserData from '../../modules/AdministratorDetails/UserData';
import AccountInfo from '../../modules/AdministratorDetails/AccountInfo';
import { useModal } from '../../../../shared/helpers/hooks';
import DesactivateUserConfirmationModal from '../../modules/AdministratorDetails/DesactivateUserConfirmationModal';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const AdministratorDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { showModal, modalHandler } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(initUserData);
  const [userInitialInfo, setUserInitialInfo] = useState({ name: '', lastName: '' });
  const [readOnlySaveBtn, setReadOnlySaveBtn] = useState(true);

  const { login, name, lastName, active } = userData;

  const successNotificationMessage = active
    ? t('adminControlPanel.desactivateAccountSuccess')
    : t('adminControlPanel.activateAccountSuccess');

  const showDesactivateAccountButton = userData.roleType !== t('adminControlPanel.superAdmin');

  const onFormUserDataChange = (key) => (value) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  const fetchUserData = async (controller = new AbortController()) => {
    setIsLoading(true);
    try {
      const {
        data: { results },
      } = await api.post(
        adminApiRoutes.user.userList,
        { filters: { limit: 1, offset: 0, id: Number(id) } },
        { signal: controller.signal },
      );
      const [firstUser] = results;
      if (firstUser) {
        setUserData(parseUserData(firstUser, t));
        setUserInitialInfo({ name: firstUser.name, lastName: firstUser.lastName });
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const saveChangeHandler = async () => {
    setIsLoading(true);
    try {
      await api.post(adminApiRoutes.user.updateUser, { login, name, lastName, active });
      await fetchUserData();
      showNotification({ type: 'success', message: t('global.saveWithSuccess') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const desactivateAccountHandler = async () => {
    setIsLoading(true);
    try {
      if (showModal) {
        modalHandler();
      }
      await api.post(adminApiRoutes.user.updateUser, { login, name, lastName, active: !active });
      showNotification({ type: 'success', message: successNotificationMessage });
      await fetchUserData();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const { buttonLabel, onButtonClick } = getButtonProp({ active, desactivateAccountHandler, modalHandler, t });

  useEffect(() => {
    const controller = new AbortController();

    fetchUserData(controller);

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const nameWithChange = userData.name !== userInitialInfo.name;
    const lastNameWithChange = userData.lastName !== userInitialInfo.lastName;
    const isReadOnlyBtn = nameWithChange || lastNameWithChange;
    setReadOnlySaveBtn(!isReadOnlyBtn);
  }, [userData]);

  if (isLoading) {
    return <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />;
  }
  return (
    <div className={classes.viewContainer}>
      {showModal && (
        <DesactivateUserConfirmationModal
          onClose={modalHandler}
          desactivateAccountHandler={desactivateAccountHandler}
        />
      )}
      <ViewTitle withArrowBack title={userData.fullName} />
      <div className={classes.wrapper}>
        <div className={classes.rowContainer}>
          <UserData userData={userData} onChange={onFormUserDataChange} />
          <div>
            <Button
              readOnly={readOnlySaveBtn}
              label={t('adminControlPanel.saveChanges')}
              className={classes.saveBtn}
              onClick={saveChangeHandler}
            />
          </div>
        </div>
        <AccountInfo userData={userData} />
        {showDesactivateAccountButton && (
          <Button className={classes.desactivateAccountBtn} label={buttonLabel} onClick={onButtonClick} />
        )}
      </div>
    </div>
  );
};

export default AdministratorDetails;
