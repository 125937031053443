import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';
import { adminRoutes } from '../../../../shared/helpers/innerRoutes';

const { managers, users, administrators } = adminRoutes;

export const apiPathConfigForExport = {
  [managers]: {
    endpoint: adminApiRoutes.client.listExport,
    fileName: 'managerList',
  },
  [users]: {
    endpoint: adminApiRoutes.client.listExport,
    fileName: 'clientList',
  },
  [administrators]: {
    fileName: 'acpUsers',
    endpoint: adminApiRoutes.user.listExport,
  },
};
