import React, { useState } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Calendar from '../Calendar';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { useIsMobile } from '../../helpers/hooks';
import { MAX_MOBILE_WIDTH, SMALL_MOBILE_WIDTH } from '../../../app/SEO/helpers/constants';

const DateInput = ({
  minDate,
  maxDate,
  selectPeriodAllowed,
  twoMonths,
  selectOnDateClick = !twoMonths,
  placeholder,
  className,
  onChange,
  userId,
  admin,
  value: dateInputValue = '',
  dateFrom,
  dateTo = dateFrom,
  messageForDateAfterMaxDate,
}) => {
  const [selectedDate, setSelectedDate] = useState({ dateFrom, dateTo });
  const [showCalendar, setShowCalendar] = useState(false);
  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const handleDateChange = (value) => {
    setSelectedDate(value);
    onChange(value);
    setShowCalendar(false);
    if (selectOnDateClick) {
      setShowCalendar(false);
    }
  };

  const onClose = () => {
    setShowCalendar(false);
    setSelectedDate({ dateFrom: '', dateTo: '' });
    if (twoMonths) {
      onChange({ dateFrom: '', dateTo: '' });
    } else {
      onChange({ date: '' });
    }
  };

  const handleCalendarClick = (event) => {
    event.stopPropagation();
  };

  if (showCalendar && isSmallMobile) {
    return (
      <div onClick={handleCalendarClick} className={classes.calendarWrapper}>
        <Calendar
          className={clsx(userId && classes.calendarWithUser)}
          maxDate={maxDate}
          minDate={minDate}
          oneDayChoose={!selectPeriodAllowed}
          isSingleMonth={!twoMonths}
          quickSelect={twoMonths}
          onClose={onClose}
          onChange={handleDateChange}
          selectedDate={selectedDate?.dateFrom || selectedDate?.date}
          selectedEndPeriodDate={selectedDate?.dateTo || selectedDate?.date}
          admin={admin || userId}
          selectOnDateClick={selectOnDateClick}
          messageForDateAfterMaxDate={messageForDateAfterMaxDate}
        />
      </div>
    );
  }

  return (
    <div
      onClick={() => setShowCalendar(true)}
      className={clsx(classes.wrapper, admin && classes.adminWrapper, className)}
    >
      <div className={classes.inputWrapper}>
        <div className={classes.label}>{placeholder}</div>
        <div className={classes.placeholder}>{dateInputValue?.length ? dateInputValue : ''}</div>
      </div>
      <CalendarIcon className={clsx(classes.icon, (admin || userId) && classes.adminIconColor)} />
      {
        showCalendar && !isSmallMobile && (
          <div onClick={handleCalendarClick} className={classes.calendarWrapper}>
            <Calendar
              className={clsx(userId && classes.calendarWithUser)}
              maxDate={maxDate}
              minDate={minDate}
              oneDayChoose={!selectPeriodAllowed}
              isSingleMonth={!twoMonths}
              quickSelect={twoMonths}
              onClose={onClose}
              onChange={handleDateChange}
              selectedDate={selectedDate?.dateFrom || selectedDate?.date}
              selectedEndPeriodDate={selectedDate?.dateTo || selectedDate?.date}
              admin={admin || userId}
              selectOnDateClick={selectOnDateClick}
              messageForDateAfterMaxDate={messageForDateAfterMaxDate}
            />
          </div>
        )
}
    </div>
  );
};

export default DateInput;
