import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { cardInformationConfig, getProperActivationText, renderInputComponent } from './utils';
import Button from '../../../../../shared/components/Button';
import { useModal } from '../../../../../shared/helpers/hooks';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import DesactivateCardModal from '../../../../../shared/components/DesactivateCardModal';
import DeleteCardModal from '../../../../../shared/components/DeleteCardModal';
import { useAdmin } from '../../../helpers/admin';
import { ROLE_ENUM } from '../../../views/AdministratorDetails/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { adminRoutes } from '../../../../../shared/helpers/innerRoutes';

const CardInformationData = ({ cardData, fetchCardData, onFormChange }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const admin = useAdmin();

  const { id, email, firstName, lastName, cardName, active } = cardData;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);

  const { showModal, setShowModal } = useModal();
  const { activationBtnLabel, notificationMessage } = getProperActivationText(active, t);

  const activateHandler = async () => {
    setIsLoading(true);
    const payload = { cardId: id, email, firstName, lastName, cardName, active: !active };
    try {
      await api.post(adminApiRoutes.card.updateCardDetails, payload);
      showNotification({ type: 'success', message: notificationMessage });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      fetchCardData();
    }
  };

  const deleteHandler = async () => {
    setIsLoading(true);
    const payload = { cardId: id };
    try {
      await api.post(adminApiRoutes.card.cardDelete, { ...payload });
      showNotification({ type: 'success', message: t('adminControlPanel.deleteCardSuccess') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      navigate(adminRoutes.cards);
    }
  };

  const activationBtnHandler = async () => {
    setSelectedModal('DEACTIVATE');
    if (cardData.active) {
      setShowModal(true);
    } else {
      await activateHandler();
    }
  };

  const onCloseDeactivationModal = () => {
    setSelectedModal(null);
    setShowModal(false);
  };

  const deleteBtnHandler = () => {
    setSelectedModal('DELETE');
    setShowModal(true);
  };

  const onCloseDeleteModal = () => {
    setSelectedModal(null);
    setShowModal(false);
  };

  const getRightModal = (modal) => {
    switch (modal) {
      case 'DEACTIVATE':
        return <DesactivateCardModal onClose={onCloseDeactivationModal} activationHandler={activateHandler} />;
      case 'DELETE':
        return <DeleteCardModal onClose={onCloseDeleteModal} deleteCardHandler={deleteHandler} />;
      default:
        return false;
    }
  };

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      {showModal && getRightModal(selectedModal)}
      <div className={classes.cardInformationHeading}>{t('adminControlPanel.cardInformation')}</div>
      <div className={classes.cardInformationWrapper}>
        {cardInformationConfig(t, cardData).map((field) => renderInputComponent({ field, form: cardData, onInputChange: onFormChange }),)}
      </div>
      <div className={classes.actions}>
        <Button label={activationBtnLabel} onClick={activationBtnHandler} />
        <Button
          label={t('adminControlPanel.deleteCard')}
          className={classes.deleteCardBtn}
          onClick={deleteBtnHandler}
          readOnly={admin.role !== ROLE_ENUM.superAdmin}
        />
      </div>
    </div>
  );
};

export default CardInformationData;
