import dayjs from 'dayjs';
import { DATE_FORMAT_DD_MM_YYYY, POLISH_CURRENCY } from '../../app/SEO/helpers/constants';
import { PAYLOAD_KEY_ENUM } from './enums';

export const getLocalStorageProfileStatus = (id) => localStorage.getItem(`status-${id}`);

export const getObjectWithoutEmptyValues = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value) {
    acc[key] = value;
  }
  return acc;
}, {});

export const trimValues = (data) => {
  if (typeof data === 'string') {
    return data.trim();
  }

  if (Array.isArray(data)) {
    return data.map(trimValues);
  }

  if (typeof data === 'object' && data !== null) {
    return Object.entries(data).reduce((accumulator, [key, value]) => {
      const trimmedValue = trimValues(value);
      if (trimmedValue !== '') {
        accumulator[key] = trimmedValue;
      }
      return accumulator;
    }, {});
  }
  return data;
};

export const generateKey = (el, idx) => `${el}-${idx}`;

export const formatCurrency = (amount, currency = POLISH_CURRENCY) => new Intl.NumberFormat('pl-PL', { style: 'currency', currency }).format(amount || 0);

export const filterChangeHandler = (setter) => (name) => (value) => {
  if (name === PAYLOAD_KEY_ENUM.OFFSET) {
    setter((prev) => ({
      ...prev,
      offset: prev.limit * (value - 1),
    }));
    return;
  }
  if (name === PAYLOAD_KEY_ENUM.LIMIT) {
    setter((prev) => ({
      ...prev,
      limit: value,
      offset: 0,
    }));
    return;
  }
  setter((prev) => ({
    ...prev,
    offset: 0,
    [name]: value,
  }));
};

export const formatDate = (date, format = DATE_FORMAT_DD_MM_YYYY) => {
  if (dayjs(date).isValid()) {
    return dayjs(date).locale('pl').format(format);
  }
  return '-';
};
