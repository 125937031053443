import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { useFetchData } from '../../../../../shared/helpers/hooks';
import { FILTERS_ENUM, MIN_SEARCH_LENGTH } from '../../../views/AdministratorList/utils';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import classes from './styles.module.css';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import SearchInput from '../../../../../shared/components/SearchInput';
import EmptyList from '../../../../../shared/components/EmptyList';
import AdminPagination from '../../../components/AdminPagination';
import Table from '../../../../../shared/components/Table';
import { managerBusinessCardTableColumnsConfig, renderBussinessCardDetails, sortConfig } from './utils';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';
import { adminApiRoutes } from '../../../../../shared/helpers/apiRoutes';

const { LIMIT, OFFSET, SEARCH } = FILTERS_ENUM;
const { ASC, DESC } = SORT_DIRECTION_ENUM;

const ManagerBusinessCardList = ({ userId }) => {
  const { t } = useTranslation();
  const { fetchData, data, isLoading } = useFetchData();
  const [results, setResults] = useState(data?.results || []);
  const [total, setTotal] = useState(data?.total || 0);
  const [orderBy, setOrderBy] = useState({ column: sortConfig.sortName, direction: ASC });

  const [filters, setFilters] = useState({ limit: 10, offset: 0, userId });

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const handleSortChange = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  useEffect(() => {
    setResults(data?.results || []);
    setTotal(data?.total || 0);
  }, [data]);

  useEffect(() => {
    const filtersWithValues = removeNullValues(filters);
    fetchData(adminApiRoutes.client.businessProfileList, { filters: filtersWithValues, orderBy });
  }, [userId, filters, orderBy]);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      <SearchInput
        className={classes.searchInput}
        onSearch={handleFiltersChange(SEARCH)}
        label={t('global.search')}
        value={filters.search}
      />
      {results.length ? (
        <Table
          className={classes.tableWrapper}
          tableBodyStyle={classes.tableStyle}
          expandableRow
          data={results}
          columns={managerBusinessCardTableColumnsConfig(t)}
          onSortClick={handleSortChange}
          onExpandRowRender={renderBussinessCardDetails}
          emptyStateTitle={t('adminControlPanel.noBusnessCardsToDisplay')}
          orderBy={orderBy}
          admin
          edit={false}
        />
      ) : (
        <EmptyList title={t('adminControlPanel.noBusnessCardsToDisplay')} />
      )}
      {Boolean(results.length) && (
        <AdminPagination
          className={classes.paginationWrapper}
          total={total}
          limit={filters.limit}
          offset={filters.offset}
          onPageChange={handleFiltersChange(OFFSET)}
          onLimitChange={handleFiltersChange(LIMIT)}
          noExport
        />
      )}
    </div>
  );
};

export default ManagerBusinessCardList;
