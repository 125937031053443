import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import AnswerTemplateModal from '../../modules/AnswerTemplates/AnswerTemplateModal';
import { useNotification } from '../../../../shared/helpers/notification';
import { StateEnum } from '../../../../shared/helpers/state';
import Loader from '../../components/Loader';
import { sortTemplateList } from './utils';
import AnswerTemplatesEmptyState from '../../modules/AnswerTemplates/AnswerTemplatesEmptyState';
import AnswerTemplateContent from '../../modules/AnswerTemplates/AnswerTemplateContent';
import AnswerTemplateHeading from '../../modules/AnswerTemplates/AnswetTemplateHeading';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { adminApiRoutes, apiRoutes } from '../../../../shared/helpers/apiRoutes';

const AnswerTemplates = ({ adminPreview, userId }) => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [state, setState] = useState();
  const [sortTemplate, setSortTemplate] = useState(sortTemplateList[0].value);
  const [showModal, setShowModal] = useState({ create: false, edit: { show: false, id: null } });
  const [templates, setTemplates] = useState([]);

  const editId = showModal.edit.id;
  const templateToChange = editId && templates.find((el) => el.id === editId);
  const emptyContentCondition = !templates.length && ![StateEnum.initializing, StateEnum.loading].includes(state);

  const createModalHandler = () => {
    setShowModal((prev) => ({ ...prev, create: !prev.create }));
  };

  const fetchTemplates = async () => {
    const controller = new AbortController();
    setState(StateEnum.loading);
    try {
      const apiPath = adminPreview ? adminApiRoutes.client.getTemplates : apiRoutes.reviewReplyTemplate.getTemplates;
      const userPayload = { orderBy: 'created_at', order: sortTemplate };
      const adminPayload = { ...userPayload, userId };
      const apiPayload = adminPreview ? adminPayload : userPayload;
      const { data } = await api.post(apiPath, apiPayload, { signal: controller.signal });
      localStorage.setItem('templates', JSON.stringify(data.templates));
      setTemplates(data.templates);
      setState(StateEnum.success);
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    }
    return () => controller.abort();
  };

  const editAnswerTemplate = (id) => {
    setShowModal((prev) => ({ ...prev, edit: { show: !prev.edit.show, id } }));
  };

  const sortTemplateHandler = (id) => {
    setSortTemplate(sortTemplateList.find((el) => el.id === id).value);
  };

  useLayoutEffect(() => {
    const templatesFromLocalStorage = JSON.parse(localStorage.getItem('templates'));
    if (templatesFromLocalStorage && !userId) {
      setTemplates(templatesFromLocalStorage);
      setState(StateEnum.success);
    } else {
      fetchTemplates();
    }
  }, []);

  if ([StateEnum.loading, StateEnum.initializing].includes(state)) {
    return (
      <div className={classes.overlay}>
        <Loader className={classes.loader} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.wrapper, adminPreview && classes.adminPreview)}>
      {showModal.create && (
        <AnswerTemplateModal
          title={t('opinions.createTemplate')}
          fetch={fetchTemplates}
          modalHandler={createModalHandler}
        />
      )}
      <AnswerTemplateHeading createModalHandler={createModalHandler} templates={templates} userId={userId} />
      <div className={classes.content}>
        {emptyContentCondition ? (
          <div className={classes.empty}>
            <AnswerTemplatesEmptyState createModalHandler={createModalHandler} adminPreview={adminPreview} />
          </div>
        ) : (
          <AnswerTemplateContent
            templates={templates}
            editAnswerTemplate={editAnswerTemplate}
            fetchTemplates={fetchTemplates}
            showModal={showModal}
            sortTemplate={sortTemplate}
            sortTemplateHandler={sortTemplateHandler}
            templateToChange={templateToChange}
            createTemplate={createModalHandler}
            userId={userId}
          />
        )}
      </div>
    </div>
  );
};
export default AnswerTemplates;
