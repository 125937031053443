import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

export const keys = {
  storefrontAddress: 'storefrontAddress',
  serviceArea: 'serviceArea',
  phoneNumbers: 'phoneNumbers',
  categories: 'categories',
  regularHours: 'regularHours',
  websiteUri: 'websiteUri',
  openInfo: 'openInfo',
  photos: 'mediaCount',
  posts: 'postsNumber',
  services: 'serviceItems',
  description: 'description',
  error: 'server error',
};
const MAX_SCORE = 11;
const createListItem = (id, name, value, key, navigate) => ({ id, name, value, key, navigate });

const { editBusinessProfile, media, localPost } = seoRoutes;
export const basicData = [
  {
    id: 1,
    name: 'dashboard.basicCompletion',
    list: [
      createListItem(1, 'dashboard.locality', true, keys.storefrontAddress, editBusinessProfile),
      createListItem(2, 'dashboard.area', true, keys.serviceArea, editBusinessProfile),
      createListItem(3, 'dashboard.phoneNumber', true, keys.phoneNumbers, editBusinessProfile),
      createListItem(4, 'dashboard.primaryCategory', false, keys.categories, editBusinessProfile),
    ],
  },
];
export const averageData = [
  {
    id: 2,
    name: 'dashboard.middleCompletion',
    list: [
      createListItem(1, 'dashboard.openingHours', true, keys.regularHours, editBusinessProfile),
      createListItem(2, 'dashboard.website', false, keys.websiteUri, editBusinessProfile),
      createListItem(3, 'dashboard.openingDate', true, keys.openInfo, editBusinessProfile),
    ],
  },
];
export const fullData = [
  {
    id: 3,
    name: 'dashboard.fullCompletion',
    list: [
      createListItem(1, 'dashboard.photos', true, keys.photos, media),
      createListItem(2, 'dashboard.posts', true, keys.posts, localPost),
      createListItem(3, 'dashboard.services', false, keys.services, editBusinessProfile),
      createListItem(4, 'dashboard.description', false, keys.description, editBusinessProfile),
    ],
  },
];
export const initData = [...basicData];

const getList = (data) => data.flatMap((el) => el.list);

const getNumberOfCompletedItems = (data) => {
  const dataWithTruthyValues = data.filter((el) => el.value).length;
  const dataWithErrorValues = data.filter((el) => el.value === keys.error).length;

  if (data.some((el) => el.value === keys.error)) {
    return dataWithTruthyValues - dataWithErrorValues;
  }
  return dataWithTruthyValues;
};

const parseData = (data, profileData) => data.map((el) => ({
  ...el,
  list: el.list.map((row) => {
    const { key } = row;
    if (key === keys.regularHours && !profileData[key]) {
      return { ...row, value: profileData[keys.openInfo] && profileData[keys.openInfo].status === 'OPEN' };
    }
    if (key === keys.openInfo) {
      return { ...row, value: profileData[key] && Boolean(Object.keys(profileData[key]?.openingDate || {}).length) };
    }
    const value = profileData[key];
    return { ...row, value: value === keys.error ? keys.error : Boolean(value) };
  }),
}));

const getParticularDataDetails = (profileData) => {
  const parsedBasicData = parseData(initData, profileData);
  const parsedBasicDataLists = getList(parsedBasicData);
  const isBasicDataCompleted = parsedBasicDataLists.every((el) => el.value);
  const parsedAverageData = parseData(averageData, profileData);
  const parsedAverageDataList = getList(parsedAverageData);
  const isAverageDataCompleted = parsedAverageDataList.every((el) => el.value);
  const parsedFullData = parseData(fullData, profileData);
  const parsedFullDataLists = getList(parsedFullData);

  return {
    parsedBasicData,
    parsedBasicDataLists,
    isBasicDataCompleted,
    parsedAverageData,
    parsedAverageDataList,
    isAverageDataCompleted,
    parsedFullData,
    parsedFullDataLists,
  };
};

export const completionData = (profileData) => {
  const getCommonDataScore = (basic, average, full) => {
    const basicDataScore = getNumberOfCompletedItems(basic);
    const averageDataScore = getNumberOfCompletedItems(average);
    const fullDataScore = getNumberOfCompletedItems(full);

    return { basicDataScore, averageDataScore, fullDataScore };
  };
  const {
    isAverageDataCompleted,
    isBasicDataCompleted,
    parsedAverageData,
    parsedAverageDataList,
    parsedBasicData,
    parsedBasicDataLists,
    parsedFullData,
    parsedFullDataLists,
  } = getParticularDataDetails(profileData);

  const { basicDataScore, averageDataScore, fullDataScore } = getCommonDataScore(
    parsedBasicDataLists,
    parsedAverageDataList,
    parsedFullDataLists,
  );

  if (!isBasicDataCompleted) {
    const score = ((basicDataScore + averageDataScore) / MAX_SCORE).toFixed(2);
    return { parsedData: parsedBasicData, score };
  }

  if (isBasicDataCompleted && !isAverageDataCompleted) {
    const score = ((basicDataScore + averageDataScore) / MAX_SCORE).toFixed(2);
    return { parsedData: parsedAverageData, score };
  }
  const score = ((basicDataScore + averageDataScore + fullDataScore) / MAX_SCORE).toFixed(2);
  return { parsedData: parsedFullData, score };
};

export const completionConfig = (score) => {
  const parsedScore = (score * MAX_SCORE).toFixed(0);
  if (parsedScore <= 6) {
    return { color: 'var(--green500)', label: 'dashboard.basic' };
  }
  if (parsedScore > 6 && parsedScore < 10) {
    return { color: 'var(--orange500)', label: 'dashboard.average' };
  }
  if (parsedScore >= 10 && parsedScore < 11) {
    return { color: 'var(--green500)', label: 'dashboard.high' };
  }
  return { color: 'var(--green500)', label: 'dashboard.full' };
};
