import React from 'react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import clsx from 'clsx';
import { paymentMethodConfig, paymentMethodEnum } from '../../Subscription/PaymentMethodItem/utils';
import Pill from '../../../../../shared/components/Pill';
import classes from './styles.module.css';
import { DATE_FORMAT_DD_MM_YYYY, subscriptionsConfig } from '../../../helpers/constants';
import Button from '../../../../../shared/components/Button';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../../../shared/helpers/enums';
import { formatCurrency } from '../../../../../shared/helpers/helpers';

const { basic, mini, pro } = subscriptionsConfig;
const { ACTIVE, CANCELED } = SUBSCRIPTION_STATUS_ENUM;
const { oneTimeForMonth, oneTimeForYear, recurring } = paymentMethodEnum;

export const SUBSCRIPTION_ACTION_KEY_ENUM = {
  MANAGE: 'manage',
};
export const paymentTypeLabel = {
  one_time_1: 'settings.paymentTypeOneMonth',
  one_time_12: 'settings.paymentTypeOneYear',
  recurring: 'settings.paymentTypeRecurring',
};

export const getDateLabel = (paymentType, status) => {
  const recurringCanceled = status === 'CANCELED' && paymentType === paymentMethodEnum.recurring;
  if ([oneTimeForMonth, oneTimeForYear].includes(paymentType) || recurringCanceled || !paymentType) {
    return i18next.t('settings.expirationDate');
  }
  return i18next.t('settings.nextPaymentDate');
};

export const getSubscriptionDateLabel = (paymentType, status) => getDateLabel(paymentType, status);

export const getSubscriptionWithPrice = (subscription, paymentType) => {
  if (!paymentMethodConfig[subscription]) return 0;
  return paymentMethodConfig[subscription][paymentType] || 0;
};

export const getConnectedBusinessProfile = (name, address) => ({ name, address });

export const getPayment = (paymentType) => paymentTypeLabel[paymentType] || '-';

export const getDate = (subscriptionDateLabel, subscriptionDate) => `${subscriptionDateLabel}\n${subscriptionDate}`;

export const getSubscription = (subscription, subscriptionWithPrice) => ({
  namePlan: subscription,
  price: subscriptionWithPrice.price,
  priceTag: String(i18next.t(subscriptionWithPrice.priceTagShort)).trim(),
});

export const parseDataToListColumns = (data) => data.map((item) => {
  const subscriptionDate = dayjs(item.subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY);
  const subscriptionDateLabel = getSubscriptionDateLabel(item.paymentType, item.status);
  const subscriptionWithPrice = getSubscriptionWithPrice(item.subscription, item.paymentType);
  return {
    ...item,
    id: item.bpId,
    manage: item.paymentType === paymentMethodEnum.recurring,
    connectedBusinessProfile: getConnectedBusinessProfile(item.name, item.address),
    payment_type: getPayment(item.paymentType),
    subscription_date: getDate(subscriptionDateLabel, subscriptionDate),
    subscription: getSubscription(item.subscription, subscriptionWithPrice),
  };
});

export const renderBusinessProfile = ({ name, address }) => (
  <div className={classes.businessProfileInfoWrapper}>
    <div className={classes.businessProfileName}>{name}</div>
    <div className={classes.busienssProfileAddress}>{address}</div>
  </div>
);

export const renderPaymentMethod = (paymentMethod) => {
  const [type, period] = paymentMethod.split('\n');

  return (
    <>
      <div className={classes.paymentMethodType}>{type}</div>
      <div className={classes.paymentMethodPeriod}>{period}</div>
    </>
  );
};

export const renderPlanInformation = ({ namePlan, price }) => (
  <>
    <div className={classes.namePlan}>{namePlan}</div>
    <div className={classes.planPrice}>{formatCurrency(price)}</div>
  </>
);

export const renderDate = (date) => {
  const [label, value] = date.split('\n');

  return (
    <>
      <div className={classes.dateLabel}>{label}</div>
      <div className={classes.dateValue}>{value}</div>
    </>
  );
};

export const pillStatusConfig = {
  active: 'subscription.active',
  pending: 'subscription.pending',
  canceled: 'subscription.canceled',
};

export const renderStatusPill = (status, t) => {
  const lowerCaseVal = status.toLowerCase();
  const subStatus = pillStatusConfig[lowerCaseVal];
  return <Pill label={t(subStatus)} className={clsx(classes.pill, classes[lowerCaseVal])} />;
};
const isSmallScreen = window.innerWidth < 1024;

export const subscriptionListConfig = (t) => [
  {
    key: 'subscription',
    header: 'settings.plan',
    render: renderPlanInformation,
    sort: 'subscription',
    width: isSmallScreen ? '100px' : 1,
  },
  {
    key: 'payment_type',
    header: 'settings.payment',
    render: (val) => renderPaymentMethod(t(val)),
    sort: 'payment_type',
    width: isSmallScreen ? '210px' : 1,
  },
  {
    key: 'connectedBusinessProfile',
    header: 'settings.connectedBusinessProfile',
    render: (val) => renderBusinessProfile(val),
    width: isSmallScreen ? '210px' : 2,
  },
  {
    key: 'status',
    header: 'settings.subscriptionStatus',
    render: (val) => renderStatusPill(val, t),
    width: isSmallScreen ? '210px' : 1,
  },
  {
    key: 'subscription_date',
    header: 'settings.date',
    render: (val) => renderDate(val),
    width: isSmallScreen ? '150px' : 0.75,
    sort: 'subscription_date',
  },
  {
    key: 'manage',
    action: true,
    render: (value) => (value ? <Button className={classes.mangeBtn} label={t('settings.manage')} /> : null),
    width: isSmallScreen ? '200px' : 0.75,
  },
];

export const subscriptionFilterConfig = {
  'settings.filtersPlan': [
    { label: 'landingPage.pricingSection.mini', value: mini, filterKey: 'subscription' },
    { label: 'landingPage.pricingSection.basic', value: basic, filterKey: 'subscription' },
    { label: 'landingPage.pricingSection.pro', value: pro, filterKey: 'subscription' },
  ],
  'settings.filtersStatus': [
    { label: 'settings.active', value: ACTIVE, filterKey: 'status' },
    { label: 'settings.canceled', value: CANCELED, filterKey: 'status' },
  ],
  'settings.filtersPaymentType': [
    { label: 'settings.singleMonthly', value: oneTimeForMonth, filterKey: 'paymentType' },
    { label: 'settings.singleYearly', value: oneTimeForYear, filterKey: 'paymentType' },
    { label: 'settings.recurring', value: recurring, filterKey: 'paymentType' },
  ],
};

export const DEFAULT_FILTERS = {
  offset: 0,
  limit: 10,
  search: '',
};

export const getApiPayload = ({ filters = {}, order = {} } = {}) => {
  const filtersWithValues = removeNullValues(filters);
  const orderWithValues = removeNullValues(order);
  const payload = { filters: { ...filtersWithValues } };
  const { column, direction } = orderWithValues;
  if (column && direction) {
    payload.orderBy = { column, direction };
  }

  return payload;
};
