import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Select from '../../../../../shared/components/Select';
import Button from '../../../../../shared/components/Button';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { renderAddress, renderBasicData, showOnListInvoiceData } from './utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useDashboard } from '../../../helpers/dashboard';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

const ChangeInvoiceDataModal = ({ onClose, invoiceData, currentInvoiceData, refetchFn }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const {
    businessProfile: { id: businessProfileId },
  } = useDashboard();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedInvoiceData, setSelectedInvoiceData] = useState(invoiceData[0]);

  const onSelectInvoiceData = (value) => {
    const foundDataIndex = invoiceData.findIndex((data) => data.id === value);
    setSelectedInvoiceData(invoiceData[foundDataIndex]);
  };

  const submitChangeInvoiceData = async () => {
    if (currentInvoiceData.id === selectedInvoiceData.id) {
      onClose();
      return;
    }
    if (invoiceData.length === 1) {
      onClose();
      return;
    }
    setIsLoading(true);
    try {
      await api.post(apiRoutes.subscription.changeInvoiceData, {
        businessProfileId,
        invoiceDetailsId: selectedInvoiceData.id,
      });
      await refetchFn();
      onClose();
      showNotification({ message: t('subscription.invoiceDataChanged'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };
  if (!invoiceData) {
    return <LoaderOverlay />;
  }

  return (
    <Modal contentStyle={classes.modal} onCancel={onClose} modalTitle={t('subscription.changeInvoiceData')}>
      {isLoading && <LoaderOverlay customStyle={classes.loader} />}
      <div className={classes.changeDataSubInfo}>{t('subscription.changeInvoiceDataTitle')}</div>
      <Select
        list={invoiceData}
        value={selectedInvoiceData}
        showOnList={showOnListInvoiceData}
        onSelect={onSelectInvoiceData}
      />
      <div className={classes.inoviceDataWrapper}>
        <div className={classes.basicData}>{renderBasicData({ selectedInvoiceData })}</div>
        <div className={classes.adressData}>{renderAddress({ selectedInvoiceData })}</div>
      </div>
      <div className={classes.additionalInformation}>
        <CircleInfo />
        <div>{t('subscription.changeWillBeVisibleWithNextPayment')}</div>
      </div>
      <Button label={t('global.changeData')} className={classes.changeDataBtn} onClick={submitChangeInvoiceData} />
    </Modal>
  );
};

export default ChangeInvoiceDataModal;
