import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { EXTERNAL_ADDRESSES } from '../../../../shared/helpers/const';
import { useNotification } from '../../../../shared/helpers/notification';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const DashboardTopbarSubmenu = ({ onLogout = () => {}, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  const navigateToSettings = (e) => {
    navigate(seoRoutes.settings);
    onClose(e);
  };
  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(EXTERNAL_ADDRESSES.EMAIL);
    showNotification({ type: 'success', message: t('dashboard.mailCopied') });
    onClose(e);
  };
  return (
    <>
      <div className={classes.subMenuItem} onClick={navigateToSettings}>
        {t('dashboard.settings')}
      </div>
      <div onClick={copyToClipboard} className={classes.subMenuItem}>
        <a href={`mailto:${EXTERNAL_ADDRESSES.EMAIL}`}>{t('dashboard.contactUs')}</a>
      </div>
      <div className={classes.subMenuItem} onClick={onLogout}>
        {t('dashboard.logout')}
      </div>
    </>
  );
};

export default DashboardTopbarSubmenu;
