import dayjs from 'dayjs';
import i18next from 'i18next';
import { parseStringByLanguage, parsingStringStars } from '../Opinions/utils';
import {
  ACTION_ENUM,
  CLIENT_PATH_ENUM,
  DATA_NAME_ENUM,
  METRIC_TYPE_ENUM,
  PROFILE_STATUS_ENUM,
} from '../../helpers/enums';
import {
  DEFAULT_DATE_FORMAT,
  INTERACTION_KEYS,
  INTERACTION_TYPES,
  SEARCH_KEYS,
  SEARCH_TYPES,
  subscriptionsConfig,
} from '../../helpers/constants';
import { CHANGE_KEY_CONFIG } from '../BusinessProfileChangeHistory/utils';
import { ADMIN_PATH_ENUM } from '../../../Admin/helpers/enums';

const changeHistoryConfig = {
  PROFILE_CHANGE: 'dashboard.editBusinessCard',
  POST: 'dashboard.post',
  PHOTO: 'dashboard.photo',
  VIDEO: 'dashboard.video',
};

const { duplicate, suspended, notVerified } = PROFILE_STATUS_ENUM;

const createRequest = ({ path, body, adminPreview, userId }) => {
  if (adminPreview) {
    return { path, body: { ...body, userId } };
  }
  return { path, body };
};

const parseBusinessProfileStatistic = ({ performance = {}, reviews = {}, posts = { countAllPosts: 0 } }) => {
  if (!performance || !performance.statistics) return {};
  const { statistics: { totalSearch = 0, searchIncrease = 0, totalInteractions = 0, interactionsIncrease = 0 } = {} } = performance;
  const { countAllPosts = 0 } = posts;
  const averageRating = reviews?.averageRating ?? null;

  return {
    totalViews: totalSearch,
    viewsChange: searchIncrease.toFixed(1),
    totalInteractions,
    interactionsChange: interactionsIncrease.toFixed(1),
    averageRating: averageRating !== null ? averageRating.toFixed(1) : '-',
    totalPosts: countAllPosts,
  };
};

export const dashboardApiPathConfigGetter = (adminPreview) => {
  const clientPaths = CLIENT_PATH_ENUM;
  const adminPaths = ADMIN_PATH_ENUM;
  const paths = {
    getBusinessProfile: 'getBusinessProfile',
    localPostStatistic: 'localPostStatistic',
    reviews: 'getReviews',
    mediaList: 'getMedia',
    performance: 'businessProfilePerformance',
    keywords: 'businessProfileKeywords',
    changeContent: 'changeContent',
  };

  return Object.fromEntries(
    Object.entries(paths).map(([key, value]) => [key, adminPreview ? adminPaths[value] : clientPaths[value]]),
  );
};

export const dashboardPromisesData = ({
  businessProfileId,
  adminPreview,
  userId,
  subscription,
  businessVerificationStatus,
  businessProfile,
}) => {
  const { getBusinessProfile, localPostStatistic, reviews, mediaList, performance, changeContent, keywords } = dashboardApiPathConfigGetter(adminPreview);

  const createCommonRequests = () => {
    if (businessProfile && adminPreview) {
      return {
        localPostStatistic: createRequest({
          path: localPostStatistic,
          body: { businessProfileId },
          adminPreview,
          userId,
        }),
        changeContent: createRequest({
          path: changeContent,
          body: { businessProfileId },
          adminPreview,
          userId,
        }),
      };
    }
    return {
      getBusinessProfile: createRequest({
        path: getBusinessProfile,
        body: { businessProfileId },
        adminPreview,
        userId,
      }),
      mediaList: createRequest({
        path: mediaList,
        body: { businessProfileId, limit: 5 },
        adminPreview,
        userId,
      }),
      localPostStatistic: createRequest({
        path: localPostStatistic,
        body: { businessProfileId },
        adminPreview,
        userId,
      }),
    };
  };

  const proSubRequestList = {
    ...createCommonRequests(),
    performance: createRequest({
      path: performance,
      body: { businessProfileId },
      adminPreview,
      userId,
    }),
    keywords: createRequest({ path: keywords, body: { businessProfileId }, adminPreview, userId }),
    changeContent: createRequest({
      path: changeContent,
      body: { businessProfileId },
      adminPreview,
      userId,
    }),
    reviews: createRequest({
      path: reviews,
      body: { businessProfileId, orderBy: 'update_time desc', limit: 2 },
      adminPreview,
      userId,
    }),
  };

  const freeSubRequestList = {
    ...createCommonRequests(),
    performance: createRequest({
      path: performance,
      body: { businessProfileId },
      adminPreview,
      userId,
    }),
    keywords: createRequest({ path: keywords, body: { businessProfileId }, adminPreview, userId }),
    reviews: createRequest({
      path: reviews,
      body: { businessProfileId, orderBy: 'update_time desc', limit: 2 },
      adminPreview,
      userId,
    }),
  };

  if (businessVerificationStatus !== PROFILE_STATUS_ENUM.verified) {
    return createCommonRequests();
  }
  return subscription === subscriptionsConfig.pro ? proSubRequestList : freeSubRequestList;
};

export const formatChangeHistoryData = (t, historyData = []) => (historyData || []).map(({ createdAt, type, name, action }) => {
  const { PHOTO, POST, VIDEO } = changeHistoryConfig;
  const date = dayjs(createdAt).format(DEFAULT_DATE_FORMAT);
  const shortDate = dayjs(createdAt).format('DD.MM');
  const isMediaItem = [t(PHOTO), t(VIDEO)].includes(t(changeHistoryConfig[type]));
  const isPostItem = t(changeHistoryConfig[type]) === t(POST);
  const formattedType = t(changeHistoryConfig[type]);
  const actionEnumValue = ACTION_ENUM[action] || '';
  const formattedAction = t(actionEnumValue).toLowerCase();
  const formattedName = t(CHANGE_KEY_CONFIG[name]);

  if (isMediaItem) {
    const value = `${t('dashboard.media')}: ${formattedAction} "${formattedType}"`;
    return { date, value, shortDate };
  }
  if (isPostItem) {
    const value = `${formattedType}: ${formattedAction} "${name}"`;
    return { date, value, shortDate };
  }
  const value = `${formattedType}: ${formattedAction} "${formattedName}"`;
  return { date, shortDate, value };
});

export const groupDataByDate = (data) => data.reduce((acc, { date, ...rest }) => {
  if (!acc[date]) {
    acc[date] = [];
  }
  acc[date].push(rest);
  return acc;
}, {});

export const mapDataWithValuesByDate = (parsedDataWithValuesByDate, searchData = []) => Object.keys(parsedDataWithValuesByDate).map((date) => ({
  date,
  values: parsedDataWithValuesByDate[date],
  valueAsNumber: searchData.find((item) => item.date === date)?.value,
}));

export const mapDataWithValues = (datesCollection, parsedHistoryData) => datesCollection?.map((date) => {
  const historyDataWithDate = parsedHistoryData.find((item) => item.date === date);
  const shortDate = dayjs(date).format('DD.MM');
  if (!historyDataWithDate) {
    return { date, values: [], valueAsNumber: null, shortDate };
  }
  return { ...historyDataWithDate, shortDate };
});

export const groupValuesByType = (parsedHistoryDataWithValues) => parsedHistoryDataWithValues?.map(({ values, ...rest }) => {
  const groupedValues = values.reduce((acc, { type, ...value }) => {
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(value);
    return acc;
  }, {});
  return { ...rest, values: groupedValues };
});

const parseAdditionalSearchStatistic = (performance) => {
  if (!performance || !performance.statistics) return [];
  const { currentPeriod = {}, lastPeriod = {} } = performance.statistics;
  const collection = SEARCH_TYPES?.map((type) => {
    const key = SEARCH_KEYS[type];
    const currentValue = currentPeriod[key] || 0;
    const lastValue = lastPeriod[key] || 0;
    const change = currentValue - lastValue;
    const changePercent = lastValue !== 0 ? ((currentValue - lastValue) / lastValue) * 100 : 100;
    return {
      name: type,
      value: currentValue,
      change,
      changePercent,
    };
  });
  return collection;
};

const parseAdditionalInteractions = (performance) => {
  if (!performance || !performance.statistics) return [];
  const { currentPeriod, lastPeriod } = performance.statistics;

  const interactions = INTERACTION_TYPES.map((type) => {
    const key = INTERACTION_KEYS[type];
    const lastValue = lastPeriod[key];

    return {
      name: type,
      value: currentPeriod[key],
      change: currentPeriod[key] - lastValue,
      changePercent: lastValue !== 0 ? ((currentPeriod[key] - lastValue) / lastValue) * 100 : 100,
    };
  });

  return interactions.sort((a, b) => {
    if (a.name === 'dashboard.dailyMetricUnknown') return 1;
    if (b.name === 'dashboard.dailyMetricUnknown') return -1;
    return b.value - a.value;
  });
};

const aggregateIncomingDataByDate = (data) => data?.reduce((accumulatedData, { date, value }) => {
  const existingEntry = accumulatedData.find((entry) => entry.date === date);
  if (existingEntry) {
    return accumulatedData.map((entry) => (entry.date === date ? { ...entry, value: entry.value + value } : entry));
  }

  return [...accumulatedData, { date, value }];
}, []);

const parseChartData = (performance) => {
  if (!performance) return [];
  const { viewsAndInteractions: data, statistics } = performance;
  const searchData = data?.filter(({ metricType }) => Object.values(METRIC_TYPE_ENUM).includes(metricType));

  const totalSearchData = aggregateIncomingDataByDate(searchData);

  const totalSearchDataSorted = totalSearchData?.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
  return { generalSearchData: totalSearchDataSorted, delta: statistics?.searchIncrease.toFixed(1) };
};

const parseKeywords = (keywords) => {
  const parsedKeywords = !keywords
    ? []
    : keywords
      .map((el) => ({
        keyword: el.searchKeyword,
        value: el.value,
        displayValue: el.value === 15 ? '< 15' : el.value,
      }))
      .sort((a, b) => b.value - a.value);

  const maxValue = parsedKeywords.reduce((acc, { value }) => (value > acc ? value : acc), 0);
  return { keywords: parsedKeywords, maxValue };
};

const parseOpinions = (data) => {
  if (!data) return [];
  const { reviews = [] } = data;

  return reviews.map((el) => {
    const parsedComment = parseStringByLanguage(el.comment) || '';
    const [native] = parsedComment;
    return {
      reviewerPhoto: el.reviewer.profilePhotoUrl,
      reviewerName: el.reviewer.displayName,
      rating: parsingStringStars(el.starRating),
      createdAt: dayjs(el.updateTime).format('DD.MM.YYYY'),
      reviewText: native,
      id: el.reviewId,
    };
  });
};
const parseKeywordsWithOpinion = (reviews, keywordsData) => {
  const { keywords } = keywordsData || {};
  const parsedKeywords = parseKeywords(keywords);
  const parsedOpinions = parseOpinions(reviews);
  return { ...parsedKeywords, parsedOpinions };
};

const parseInteractionsChartData = (performance) => {
  if (!performance) return [];
  const { viewsAndInteractions = [] } = performance;
  if (!Array.isArray(viewsAndInteractions)) {
    return [];
  }

  const generalInteractions = viewsAndInteractions.filter(
    ({ metricType }) => !Object.values(METRIC_TYPE_ENUM).includes(metricType),
  );

  const totalInteractionsData = aggregateIncomingDataByDate(generalInteractions);

  return totalInteractionsData.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());
};

export const parseHistoryHandler = ({ historyData, searchData, t }) => {
  const { generalSearchData } = searchData;
  const datesCollection = generalSearchData?.map(({ date }) => date);
  const data = formatChangeHistoryData(t, historyData);
  const parsedDataWithValuesByDate = groupDataByDate(data);
  const parsedHistoryData = mapDataWithValuesByDate(parsedDataWithValuesByDate, generalSearchData);
  const parsedHistoryDataWithValues = mapDataWithValues(datesCollection, parsedHistoryData);
  const parsedHistoryGroupedByValuesType = groupValuesByType(parsedHistoryDataWithValues);
  return parsedHistoryGroupedByValuesType;
};

const parseVerifiedProfileData = (data, formData, profileStatus, posts) => {
  const { keywords, reviews, performance, changeContent } = data;
  const generalSearchData = parseChartData(performance);
  return {
    profileStatus,
    formData,
    generalSearchData,
    performance,
    statistic: parseBusinessProfileStatistic({ performance, reviews, posts }),
    keywordWithOpinions: parseKeywordsWithOpinion(reviews, keywords),
    additionalSearchStatistic: parseAdditionalSearchStatistic(performance),
    additionalInteractions: parseAdditionalInteractions(performance),
    interactionsChartData: parseInteractionsChartData(performance),
    userChangeHistory: parseHistoryHandler({
      historyData: changeContent,
      searchData: generalSearchData,
      t: i18next.t,
    }),
  };
};
export const parseDashboardData = (data, profileStatus, businessProfile) => {
  const form = data.getBusinessProfile || businessProfile;
  const posts = data.localPostStatistic;
  const mediaData = data.mediaList || businessProfile?.media;

  const formWithCounts = {
    ...form,
    postsNumber: posts?.countAllPosts || 0,
    mediaCount: mediaData?.actualMedia?.totalMediaItemCount || 0,
  };

  const formData = Object.keys(formWithCounts).reduce(
    (acc, key) => ({ ...acc, [key]: formWithCounts[key] }),

    {},
  );

  const profileNotFullyVerified = [duplicate, suspended, notVerified].includes(profileStatus);

  return profileNotFullyVerified
    ? { profileStatus, formData }
    : parseVerifiedProfileData(data, formData, profileStatus, posts);
};

const pathConfig = [
  {
    id: 1,
    path: 'localPostStatistic',
    name: 'statistic',
  },
  { id: 2, path: 'getKeywords', name: 'keywords' },
  { id: 3, path: 'getPerformance', name: 'performance' },
  { id: 4, path: 'getBusinessProfile', name: 'form' },
  { id: 5, path: 'getReviews', name: 'reviews' },
  { id: 6, path: 'mediaList', name: 'mediaCount' },
  { id: 7, path: 'getBusinessProfileChangeContent', name: 'changeContent' },
];

export const pathParser = (url) => {
  const pathArray = url.split('/');
  const pathValue = pathArray[pathArray.length - 1];
  const pathConfigItem = pathConfig.find(({ path }) => path === pathValue).name;
  return pathConfigItem;
};

export const rejectedParser = (responses) => {
  const rejected = responses
    .filter((p) => p.status === 'rejected')
    .map((el) => {
      const { reason } = el;
      const { url } = reason.config;
      return { dataValue: false, dataName: pathParser(url), status: reason.response.status };
    });
  return rejected;
};

export const fullFilledParser = (responses) => {
  const parsedResponses = responses.map(({ dataValue, dataName }) => ({
    dataValue,
    dataName: pathParser(dataName),
  }));

  return parsedResponses.filter((response) => response !== null);
};

export const fetchDataHandler = async ({ api, signal, promises, profileStatus }) => {
  const responses = await Promise.allSettled(promises.map(({ path, body }) => api.post(path, body, { signal })));
  const rejected = rejectedParser(responses);
  const resolved = fullFilledParser(responses);
  const fullData = [...resolved, ...rejected];
  return parseDashboardData(fullData, profileStatus);
};

const parseStatistics = (statistics = {}) => ({
  totalViews: statistics.totalSearch || 0,
  viewsChange: (statistics.searchIncrease || 0).toFixed(1),
  totalInteractions: statistics.totalInteractions || 0,
  interactionsChange: (statistics.interactionsIncrease || 0).toFixed(1),
});

export const parseDataOnPeriodChange = (data = {}) => {
  const { keywordsData = {}, perfomanceData = {}, userChangeHistory = [] } = data;
  const { statistics = {} } = perfomanceData;
  const keywordsCollection = Object.values(keywordsData).flatMap((el) => el);

  return {
    performance: perfomanceData,
    parsedKeywords: parseKeywords(keywordsCollection),
    parsedStatistic: parseStatistics(statistics),
    chartData: parseChartData(perfomanceData),
    interactionsChartData: parseInteractionsChartData(perfomanceData),
    additionalSearchStatistic: parseAdditionalSearchStatistic(perfomanceData),
    additionalInteractions: parseAdditionalInteractions(perfomanceData),
    userChangeHistory: parseHistoryHandler({
      historyData: userChangeHistory,
      searchData: parseChartData(perfomanceData),
      t: i18next.t,
    }),
  };
};

const fetchData = async (api, payload, userId, apiPathKey) => {
  const isAdminPreview = Boolean(userId);
  const { [apiPathKey]: apiPath } = dashboardApiPathConfigGetter(isAdminPreview);
  const { body, path } = createRequest({ path: apiPath, body: payload, adminPreview: isAdminPreview, userId });
  return api.post(path, body).then((response) => response.data);
};

export const fetchPerformanceData = (api, payload, userId) => fetchData(api, payload, userId, DATA_NAME_ENUM.performance);

export const fetchKeywordsData = (api, payload, userId) => fetchData(api, payload, userId, DATA_NAME_ENUM.keywords);

export const fetchUserChangeHistory = (api, payload, userId) => {
  const { businessProfileId, dateFrom: from, dateTo: to } = payload;
  const newPayload = { businessProfileId, from, to, userId };

  return fetchData(api, newPayload, userId, DATA_NAME_ENUM.changeContent);
};
