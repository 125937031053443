import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import { averageData, basicData, fullData } from '../ProfileCompletion/utils';

const ProfileCompletionModal = ({ modalHandler }) => {
  const { t } = useTranslation();
  const dataSet = [...basicData, ...averageData, ...fullData];
  return (
    <Modal modalTitle={t('dashboard.profileCompletionModal')} contentStyle={classes.modalStyle} onCancel={modalHandler}>
      <div>
        <div className={classes.description}>{t('dashboard.howItWorksDescription')}</div>
        <div>
          <ol className={classes.reasonsWrapper}>
            <li>{t('dashboard.betterVisibility')}</li>
            <li>{t('dashboard.moreTrust')}</li>
            <li>{t('dashboard.easierContact')}</li>
            <li>{t('dashboard.higherConversion')}</li>
            <li>{t('dashboard.betterImage')}</li>
            <li>{t('dashboard.localCompetitiveness')}</li>
          </ol>
        </div>
      </div>
      <div className={classes.description}>
        <p>{t('dashboard.howWeCalculate')}</p>
        <span>{t('dashboard.scoringExplanation')}</span>
        <ol className={classes.reasonsWrapper}>
          <li>{t('dashboard.basicLevel')}</li>
          <li>{t('dashboard.mediumLevel')}</li>
          <li>{t('dashboard.fullLevel')}</li>
        </ol>
        <Trans i18nKey="dashboard.completionProcess" components={{ bold: <b /> }} />
      </div>
    </Modal>
  );
};

export default ProfileCompletionModal;
