import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';

const ReportLeftCount = ({ noReportsLeft, totalReportsInSubPlan, availableReports }) => (
  <>
    <span className={clsx(classes.reportUsed, noReportsLeft && classes.redValue)}>
      {availableReports}
    </span>
    {!noReportsLeft && `/${totalReportsInSubPlan}`}
  </>
);

export default ReportLeftCount;
