import { ReactComponent as HomeIcon } from '../../../../shared/assets/home.svg';
import { ReactComponent as ListIcon } from '../../../../shared/assets/map.svg';
import { ReactComponent as EditIcon } from '../../../../shared/assets/editProfile.svg';
import { ReactComponent as AddPost } from '../../../../shared/assets/grid.svg';
import { ReactComponent as Opinions } from '../../../../shared/assets/chat.svg';
import { ReactComponent as Camera } from '../../../../shared/assets/camera.svg';
import { ReactComponent as CreditCard } from '../../../../shared/assets/credit-card.svg';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

const {
  dashboard,
  editBusinessProfile,
  reportList,
  changeHistory,
  localPost,
  opinions,
  media,
  answerTemplates,
  subscription,
  aiConfigAssistant
} = seoRoutes;
export const sections = (t, businessProfileId) => [
  {
    name: t('dashboard.dashboard'),
    Icon: HomeIcon,
    route: dashboard,
  },
  {
    name: t('dashboard.reports'),
    Icon: ListIcon,
    route: reportList,
  },
  {
    name: t('dashboard.editBusinessCard'),
    Icon: EditIcon,
    route: editBusinessProfile,
    expandable: true,
    childrenName: [
      {
        name: t('dashboard.changeHistory'),
        route: changeHistory,
        expandable: true,
      },
    ],
  },
  {
    name: t('dashboard.posts'),
    Icon: AddPost,
    route: localPost,
  },
  {
    name: t('dashboard.opinions'),
    Icon: Opinions,
    route: opinions,
    expandable: true,
    childrenName: [
      {
        name: t('dashboard.answerTemplates'),
        route: answerTemplates,
      },
      {
        name: t('opinions.aiConfigAssistant'),
        route: aiConfigAssistant(businessProfileId),
      }
    ],
  },
  {
    name: t('dashboard.media'),
    Icon: Camera,
    route: media,
  },
  {
    name: t('dashboard.subscription'),
    Icon: CreditCard,
    route: subscription,
  },
];

export const externalSections = (t) => [
  {
    name: t('dashboard.reports'),
    Icon: ListIcon,
    route: reportList,
  },
];
