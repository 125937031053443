export const getCategoryReferenceString = (categoryReferenceId) => {
  if (!categoryReferenceId) return '';
  const categoryReference = categoryReferenceId?.split('_');
  return categoryReference.join(' ');
};

export const initializeCategoryData = ({ form }) => {
  const { primaryCategory, additionalCategories } = form.categories;
  const parsedPrimaryCategory = { ...primaryCategory, key: Math.random() };
  const parsedAdditionalCategories = additionalCategories?.map((el) => ({
    ...el,
  })) || [];

  return ({
    primaryCategory: parsedPrimaryCategory,
    additionalCategories: parsedAdditionalCategories,
  });
};

export const getCategoryList = (categories) => Object.values(categories).flat(1)
  .reduce((acc, { categoryReferenceId }) => {
    if (categoryReferenceId) {
      return [...acc, categoryReferenceId];
    }
    return acc;
  }, []);
