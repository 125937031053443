import { toCapitalize } from '../../../../../shared/helpers/strings';
import { TODAY } from '../../../../../shared/helpers/const';

const getMaxDayInMonth = (monthNumber) => TODAY.month(monthNumber - 1).daysInMonth();

const getPeriodValueFromTwoMonths = (months) => {
  const sortedMonths = [...months].sort((a, b) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    }
    return a.monthNumber - b.monthNumber;
  });
  const [from, to] = sortedMonths;
  const maxDaysInMonth = getMaxDayInMonth(to.monthNumber, to.year);
  return `01 ${from.label} - ${maxDaysInMonth} ${to.label}`;
};
const getPeriodValueFromOneMonth = (month) => {
  const selectedMonthWithNoYear = month?.label.split(' ')[0];
  const maxDayInMonth = getMaxDayInMonth(month?.monthNumber);
  return `01 ${selectedMonthWithNoYear} - ${maxDayInMonth} ${month?.label}`;
};

export const displayPeriodHandler = (selectedMonths) => (selectedMonths.length === 2
  ? getPeriodValueFromTwoMonths(selectedMonths)
  : getPeriodValueFromOneMonth(selectedMonths[0]));

export const addUniqueMonth = (monthNumber, setter) => setter((prevSelectedMonths) => prevSelectedMonths.filter((selectedMonth) => selectedMonth.monthNumber !== monthNumber),);

export const pickOneMonth = (setter, month) => setter([month]);

export const periodSelectHandler = (setter, month) => setter((prevSelectedMonths) => {
  const newSelectedSortedMonths = [...prevSelectedMonths, month].sort((a, b) => a.monthNumber - b.monthNumber);
  return newSelectedSortedMonths;
});

export const getSixMonthsBack = () => Array.from({ length: 6 }, (_, i) => {
  const date = TODAY.subtract(i, 'month').locale('pl');
  return {
    label: toCapitalize(date.format('MMM YYYY')),
    monthNumber: date.month() + 1,
    year: date.year(),
  };
}).reverse();

export const fillMonths = (fromMonth, toMonth) => Array.from({ length: toMonth - fromMonth + 1 }, (_, index) => fromMonth + index);

export const getSelectedMonths = (selectedMonths) => {
  const calculateValue = ({ year, monthNumber }) => year * 12 + monthNumber;

  if (selectedMonths.length === 0) return [];

  if (selectedMonths.length === 1) return [selectedMonths[0].monthNumber];

  let [from, to] = selectedMonths.sort((a, b) => calculateValue(a) - calculateValue(b));

  from = calculateValue(from);
  to = calculateValue(to);

  const months = Array.from({ length: to - from + 1 }, (_, i) => {
    const value = i + from;
    const year = Math.floor(value / 12);
    const monthNumber = value % 12 === 0 ? 12 : value % 12;
    return { year, monthNumber };
  });

  return months.map((month) => month.monthNumber);
};

export const handleMonthSelection = (setSelectedMonths, monthNumbersList, selectedMonths, month) => {
  if (monthNumbersList.length >= 2) {
    setSelectedMonths([]);
  }
  const { monthNumber } = month;
  const isMonthSelected = monthNumbersList.includes(monthNumber);
  const selectedMonthsCount = selectedMonths.length;

  if (isMonthSelected) {
    addUniqueMonth(monthNumber, setSelectedMonths);
  } else if (selectedMonthsCount === 2) {
    pickOneMonth(setSelectedMonths, month);
  } else {
    periodSelectHandler(setSelectedMonths, month);
  }
};

const getPeriod = (from, to = from) => {
  const currentMonth = TODAY.month() + 1;
  const isCurrentMonth = to.monthNumber === currentMonth;

  const parsedFrom = `${from.year}-${String(from.monthNumber).padStart(2, '0')}-01`;
  let maxDaysToDate = new Date(to.year, to.monthNumber, 0).getDate();

  if (isCurrentMonth) {
    maxDaysToDate = TODAY.subtract(1, 'day').format('DD');
  }

  const parsedTo = `${to.year}-${String(to.monthNumber).padStart(2, '0')}-${maxDaysToDate}`;
  return { dateFrom: parsedFrom, dateTo: parsedTo };
};
export const getSelectedPeriod = (selectedMonths) => {
  if (selectedMonths.length === 0) {
    return { dateFrom: '', dateTo: '' };
  }

  if (selectedMonths.length === 1) {
    return getPeriod(selectedMonths[0]);
  }

  const [from, to] = selectedMonths;
  return getPeriod(from, to);
};
