import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { accountDetailsConfig, parseHistoryStatus } from './utils';
import { useApi } from '../../../../shared/helpers/api';
import Button from '../../../../shared/components/Button';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { useModal } from '../../../../shared/helpers/hooks';
import DesactivateAccountModal from '../../modules/UserDetails/DesactivateAccountModal';
import { adminApiRoutes } from '../../../../shared/helpers/apiRoutes';

const AccountInformation = ({ data, isManager, fetchData }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [userChangeHistory, setUserChangeHistory] = useState({ data: [], total: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [changeHistoryFilters, setChangeHistoryFilters] = useState({
    limit: 2,
    offset: 0,
  });
  const { showModal, modalHandler } = useModal();

  const isButtonDisplayed = userChangeHistory.data.length > 0 && userChangeHistory.total > 2;

  const fetchActiveChangeList = async () => {
    setIsLoading(true);
    try {
      const { data: changeHistoryData } = await api.post(adminApiRoutes.client.clientActiveChangeList, {
        userId: data.id,
        filters: changeHistoryFilters,
      });
      const parsedData = parseHistoryStatus(changeHistoryData.results, t);
      setUserChangeHistory({ data: parsedData, total: changeHistoryData.total });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLimitChange = () => {
    if (userChangeHistory.data.length < userChangeHistory.total) {
      setChangeHistoryFilters((prev) => ({ ...prev, limit: userChangeHistory.total, offset: 0 }));
    } else {
      setChangeHistoryFilters({ limit: 2, offset: 0 });
    }
  };

  const btnLabel = userChangeHistory.data.length < userChangeHistory.total ? t('global.showAll') : t('global.showLess');

  useEffect(() => {
    if (data.id) {
      fetchActiveChangeList();
    }
  }, [data.id, changeHistoryFilters]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loader} />;
  }

  return (
    <>
      {showModal && <DesactivateAccountModal onClose={modalHandler} userData={data} fetchData={fetchData} />}

      <div className={classes.sectionTitle}>{t('adminControlPanel.accountInformationData')}</div>
      <div className={classes.sectionDataWrapper}>
        {accountDetailsConfig({ t, data, isManager, fetchData }).map(({ title, key, value }) => (
          <div key={key}>
            <div className={classes.dataTitle}>{title}</div>
            <div className={classes.dataValue}>{value}</div>
          </div>
        ))}
        {!isManager && (
          <Button
            className={classes.actionBtn}
            label={data.active ? t('adminControlPanel.desactivateAccount') : t('adminControlPanel.activateAccount')}
            onClick={modalHandler}
          />
        )}

        {userChangeHistory.data?.map(({ id, createdAt, comment, type }) => (
          <div key={id} className={classes.userChangeHistoryDataWrapper}>
            <div>
              <div className={classes.dataTitle}>{t('adminControlPanel.accountChangeTypeAndDate')}</div>
              <div className={classes.dataValue}>{`${type} - ${createdAt}`}</div>
            </div>
            <div className={classes.dataTitle}>{t('adminControlPanel.accountChangeReason')}</div>
            <div className={classes.dataValueComment}>{comment}</div>
          </div>
        ))}
        {isButtonDisplayed && (
          <Button label={btnLabel} className={classes.showMoreReasonBtn} onClick={handleLimitChange} />
        )}
      </div>
    </>
  );
};

export default AccountInformation;
