import React from 'react';
import i18next from 'i18next';
import classes from './styles.module.css';
import { formatDate } from '../../../../../shared/helpers/helpers';
import { DATE_FORMAT_DD_MM_YYYY_HH_MM } from '../../../helpers/constants';
import { renderPaidDetails, renderStatus } from '../PaymentHistoryList/utils';

export const paymentHistoryItemConfig = [
  {
    name: '',
    render: (item) => (
      <div className={classes.connectedBusinessProfile}>
        <p>{item.connectedBusinessProfile.name}</p>
        <p>{item.connectedBusinessProfile.address}</p>
      </div>
    )
  }, {
    name: 'settings.orderDate',
    render: (item) => (
      <div className={classes.date}>
        <p>{formatDate(item.date, DATE_FORMAT_DD_MM_YYYY_HH_MM)}</p>
      </div>
    )
  },
  {
    name: 'settings.transactionId',
    render: (item) => (
      <div className={classes.transactionId}>
        <p>{item.orderNo}</p>
      </div>
    )
  },
  {
    name: 'settings.plan',
    render: (item) => (
      <div className={classes.plan}>
        <p>{item.subscription}</p>
      </div>
    )
  },
  {
    name: 'settings.payment',
    render: (item) => (
      <div className={classes.paymentType}>
        <p>{i18next.t(item.paymentType)}</p>
      </div>
    )
  },
  {
    name: 'settings.paymentPrice',
    render: (item) => (
      <div className={classes.amount}>
        <p>{`${item.amount / 100} PLN`}</p>
      </div>
    )
  }, {
    name: 'settings.paymentMethod',
    render: ({ paidBy }) => renderPaidDetails({ value: paidBy.value, type: paidBy.type, Icon: paidBy.Icon })
  },
  {
    name: 'settings.status',
    render: (item) => renderStatus({ value: item.status.value, label: item.status.label })
  },

];
