import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeroSection from './modules/HeroSection';
import classes from './styles.module.css';
import About from './modules/About';
import WhyIsWorth from './modules/WhyIsWorth';
import Footer from './modules/Footer';
import CallToAction from './modules/CallToAction';
import Features from './modules/Features';
import Pricing from './modules/Pricing';

const LandingPage = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', offset: 100 });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return (
    <div className={classes.landingPage}>
      <HeroSection />
      <About />
      <WhyIsWorth />
      <Features />
      <Pricing />
      <CallToAction />
      <Footer />
      <div className={classes.background} />
      <div className={classes.triangle} />
    </div>
  );
};

export default LandingPage;
