import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Router from './shared/components/Router';
import { AuthContext } from './shared/helpers/auth';
import { AdminContext } from './app/Admin/helpers/admin';

const App = () => {
  const [auth, setAuth] = useState({
    isAuth: null,
    login: null,
    id: null,
    businessProfiles: null,
    isAdmin: true,
    isManager: false,
  });
  const [admin, setAdmin] = useState({ name: null, lastName: null, role: null, id: null, login: null, fullName: null });
  const { i18n } = useTranslation();

  const adminContextValue = useMemo(() => {
    const handleAdmin = ({ id, login, name, lastName, role, isSuperAdmin }) => {
      setAdmin({ id, login, name, lastName, role, fullName: `${name} ${lastName}`, isSuperAdmin });
    };
    const clearAdmin = () => {
      setAdmin({ name: null, lastName: null, role: null, id: null, login: null, fullName: null, isSuperAdmin: false });
      localStorage.removeItem('adminToken');
      localStorage.removeItem('refreshAdminToken');
    };
    return { ...admin, setAdmin: handleAdmin, clearAdmin };
  }, [auth]);

  const authContextValue = useMemo(() => {
    const handleAuth = ({ isAuth, id, login, isAdmin, businessProfiles, isManager }) => {
      setAuth({ isAuth, id, login, isAdmin, businessProfiles, isManager });
    };
    const clearAuth = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      sessionStorage.clear();
      setAuth({ isAuth: false, isAdmin: false });
    };
    return { ...auth, setAuth: handleAuth, clearAuth };
  }, [auth]);

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <BrowserRouter>
      <AuthContext.Provider value={authContextValue}>
        <AdminContext.Provider value={adminContextValue}>
          <Router />
        </AdminContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
};

export default App;
