import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { paymentMethodConfig, paymentMethodEnum } from '../PaymentMethodItem/utils';
import { ReactComponent as LogoIcon } from '../../../../../shared/assets/logoIcon.svg';
import { ReactComponent as Check } from '../../../../../shared/assets/checkCircle.svg';
import { formatCurrency } from '../../../../../shared/helpers/helpers';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { MAX_MOBILE_WIDTH } from '../../../helpers/constants';

const renderPriceTag = ({ price, priceTag }) => (
  <div className={classes.priceTag}>
    {formatCurrency(price)}
    <span>{priceTag}</span>
  </div>
);

const SubscriptionPlanInfo = ({ subscription, paymentType }) => {
  const { t } = useTranslation();
  const isSmallMobile = useIsMobile(MAX_MOBILE_WIDTH);

  const price = paymentType ? paymentMethodConfig[subscription][paymentType]?.price : 0;
  const isYearly = paymentType === paymentMethodEnum.oneTimeForYear;
  const priceTag = isYearly ? t('subscription.perYearShort') : t('subscription.perMonthShort');

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.leftSideRow}>
        <LogoIcon className={classes.logoIcon} />
        <div>
          <div className={classes.planDetails}>
            <div className={classes.title}>{t('subscription.yourPlan')}</div>
            <Pill className={classes.subscriptionPill} label={subscription} />
          </div>
          <div className={classes.statusWrapper}>
            <Check className={classes.checkIcon} />
            <span className={classes.planStatusInfo}>{t('subscription.activePlanStatus')}</span>
          </div>
          {isSmallMobile && (
            <div className={clsx(classes.rightSideRow, classes.mobileVisible)}>
              {renderPriceTag({ price, priceTag })}
            </div>
          )}
        </div>
      </div>
      {!isSmallMobile && <div className={classes.rightSideRow}>{renderPriceTag({ price, priceTag })}</div>}
    </div>
  );
};

export default SubscriptionPlanInfo;
