import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import RelatedItem from '../RelatedItem';
import { seoRoutes } from '../../../../../shared/helpers/innerRoutes';

const RelatedSubscriptions = ({ data, admin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectBusinessProfile } = useDashboard();
  const onShowDetailsClick = (subscription) => {
    const { businessProfileId } = subscription;
    navigate(seoRoutes.subscription);
    selectBusinessProfile(businessProfileId);
  };

  const relatedBusinessItem = (subscription) => (
    <div key={subscription.id} className={classes.relatedItem}>
      <RelatedItem subscription={subscription} admin={admin} />
      {!admin && (
        <Button
          onClick={() => onShowDetailsClick(subscription)}
          className={classes.relatedBtn}
          label={t('settings.showDetails')}
        />
      )}
    </div>
  );

  return (
    <div className={classes.relatedSubscriptions}>
      <div className={classes.relatedSubscriptionsTitle}>{t('invoices.relatedSubscriptions')}</div>
      <div className={classes.list}>{data.map(relatedBusinessItem)}</div>
    </div>
  );
};

export default RelatedSubscriptions;
