import React from 'react';
import classes from './styles.module.css';
import { ReactComponent as EmptyListIcon } from '../../assets/emptyState.svg';

const EmptyList = ({ title, admin }) => (
  <div className={classes.wrapper}>
    {admin ? <EmptyListIcon /> : null}
    <div className={classes.title}>{title}</div>
  </div>
);

export default EmptyList;
