import { apiRoutes } from '../../../../shared/helpers/apiRoutes';

export const fetchVerificationOptions = async (api, id) => {
  const {
    data: { options },
  } = await api.post(apiRoutes.businessProfile.getVerificationOptions, { businessProfileId: id });
  return options;
};

export const verificationErrorEnum = {
  alreadyVerified: "The company is already verified or don't have verification method",
  onGoogle: 'Verification on google site',
};

export const generateUniqueOptions = (prevOptions, newOptions) => {
  const combinedOptions = [...prevOptions, ...newOptions, { verificationMethod: 'GOOGLE', method: 'GOOGLE' }];
  const uniqueOptions = combinedOptions.reduce((acc, current) => {
    const x = acc.find((item) => item.verificationMethod === current.verificationMethod);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);
  return uniqueOptions;
};

export const handleError = (error, setVerifyOptions) => {
  const {
    response: {
      data: { message },
    },
  } = error;
  if (message === verificationErrorEnum.onGoogle) {
    setVerifyOptions([{ verificationMethod: 'GOOGLE', method: 'GOOGLE' }]);
    return;
  }

  setVerifyOptions([]);
};

export const parsePhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  const parsedPhoneNumber = phoneNumber?.split(' ').join('');
  return parsedPhoneNumber;
};

export const verificationPending = ['processing', 'verified', 'duplicate', 'suspended'];

export const fetchOptions = async ({ setIsLoading, api, businesssId, setVerifyOptions }) => {
  try {
    setIsLoading(true);
    const options = await fetchVerificationOptions(api, businesssId);
    setVerifyOptions((prev) => {
      const unique = generateUniqueOptions(prev, options);
      return unique;
    });
  } catch (error) {
    handleError(error, setVerifyOptions);
  } finally {
    setIsLoading(false);
  }
};

export const fetchStatus = async ({ api, setBusinessStatus, businesssId, showNotification, t, parseProfileStatus }) => {
  try {
    const { data } = await api.post(apiRoutes.businessProfile.getVerificationStatus, {
      businessProfileId: businesssId,
    });
    setBusinessStatus(parseProfileStatus(data, t));
  } catch (error) {
    showNotification({ message: t('global.error'), type: 'error' });
  }
};
