import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Textarea from '../../../../../shared/components/Textarea';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';
import { ANSWER_MAX_LENGTH_FIELD } from '../../../views/Opinions/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import Loader from '../../../components/Loader';
import { parseStringsWithWhiteSpaces } from '../../../../../shared/helpers/strings';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { ReactComponent as PlaceHolderImage } from '../../../../../shared/assets/noImage.svg';

const ReplyTextArea = ({ textareaVisibilityHandler, opinionId, fetchOpinions, value, edit }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const [state, setState] = useState(StateEnum.initializing);
  const { showNotification } = useNotification();
  const [answerText, setAnswerText] = useState('');
  const { businessProfile } = useDashboard();

  const answerChangeHandler = (val) => setAnswerText(val);

  const submitAnswer = async () => {
    const parsedAnswer = parseStringsWithWhiteSpaces(answerText);
    const payload = {
      businessProfileId: businessProfile.id,
      reviewId: `/reviews/${opinionId}`,
      comment: parsedAnswer,
    };

    setState(StateEnum.loading);

    try {
      await api.post(apiRoutes.review.upsertReply, payload);
      await fetchOpinions();
      textareaVisibilityHandler();
      showNotification({
        label: t('global.success'),
        message: t('opinions.savedCorrectly'),
        type: 'success',
      });
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
  };
  useEffect(() => {
    setAnswerText(value || '');
  }, [value]);

  const textAreaLabel = edit ? t('opinions.editPublicReply') : t('opinions.addPublicReply');

  return (
    <div className={classes.wrapper}>
      {state === StateEnum.loading && (
        <div className={classes.overlay}>
          <Loader className={classes.loader} />
        </div>
      )}
      <div className={classes.textContainer}>
        <div className={classes.row}>
          {businessProfile?.image ? (
            <img src={businessProfile.image} alt={t('opinions.businessProfileImage')} className={classes.image} />
          ) : (
            <PlaceHolderImage className={classes.image} />
          )}
          <div className={classes.textAreaWrapper}>
            <Textarea
              value={answerText}
              onChange={answerChangeHandler}
              label={textAreaLabel}
              maxLength={ANSWER_MAX_LENGTH_FIELD}
              className={classes.textArea}
              textAreaStyle={classes.textAreaStyle}
            />
          </div>
        </div>
        <div className={classes.answerActions}>
          <Button
            onClick={textareaVisibilityHandler}
            className={clsx(classes.button, classes.cancel)}
            label={t('opinions.cancel')}
          />
          <Button
            readOnly={!answerText.length}
            onClick={submitAnswer}
            className={clsx(classes.button)}
            label={t('global.add')}
          />
        </div>
      </div>
    </div>
  );
};
export default ReplyTextArea;
