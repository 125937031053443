import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../shared/helpers/api';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import ViewHeaderBar from '../../modules/ClientSeoCarrdDetails/ViewHeaderBar';
import CardDetails from '../../modules/ClientSeoCarrdDetails/CardDetails';
import classes from './styles.module.css';
import ClientCardStatistic from '../../modules/ClientSeoCarrdDetails/ClientCardStatistic';
import { headerTabs } from '../../modules/ClientSeoCarrdDetails/ViewHeaderBar/utils';
import { clientSeoCardListParser } from '../ClientSeoCardList/utils';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import { apiRoutes } from '../../../../shared/helpers/apiRoutes';

const ClientSeoCardDetails = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { id } = useParams();
  const [cardData, setCardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(t(headerTabs[0].title));

  const onTabClick = (title) => {
    setActiveTab(t(title));
  };

  const fetchCardDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(apiRoutes.card.cardDetails, { cardId: Number(id) });
      setCardData(clientSeoCardListParser([data], t));
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id && typeof Number(id) === 'number') {
      fetchCardDetails();
    }
  }, [id]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div>
      <ViewHeaderBar cardData={cardData} activeTab={activeTab} onTabClick={onTabClick} />
      {activeTab === t('seoCard.data') && <CardDetails cardData={cardData} fetchData={fetchCardDetails} />}
      {activeTab === t('seoCard.statistics') && (
        <ClientCardStatistic cardData={cardData[0]} fetchData={fetchCardDetails} />
      )}
    </div>
  );
};

export default ClientSeoCardDetails;
