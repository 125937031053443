import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { formKey, getQuestionnaireOption, initialFormState } from './utils';
import QuestionnaireOptions from '../QuestionnaireOptions';
import KeywordsSection from '../KeywordsSection';
import SignSection from '../SignSection';
import ReviewResponseExamples from '../ReviewResponseExamples';
import AITabs from '../AITabs';
import { aiTabsConfig } from '../AITabs/utils';

const { article, formality, responseLength, singularity, sign } = formKey;
const {
  review: { deletePromptConfiguration, upsertPromptConfiguration, getPromptConfiguration, getReviewResponseExamples },
} = apiRoutes;
const KEYWORDS_ARRAY_LENGTH = 5;
const Questionnaire = ({ businessProfile }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialFormState);
  const [originalForm, setOriginalForm] = useState(null);
  const [examplesVisible, setExamplesVisible] = useState(false);
  const [responseExamples, setResponseExamples] = useState([]);

  const formWithNoDifference = useMemo(() => isEqual(form, originalForm), [form, originalForm]);
  const isDataFetched = useMemo(() => originalForm !== null, [originalForm]);
  const positiveBtnLabel = isDataFetched ? t('aiConfig.updateConfiguration') : t('aiConfig.saveConfiguration');

  const handleFormChange = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddKeyword = (value) => {
    const isKeywordsArrayFull = form.customKeywords.length >= KEYWORDS_ARRAY_LENGTH;
    if (value && !form.customKeywords.includes(value) && !isKeywordsArrayFull) {
      setForm((prev) => ({ ...prev, customKeywords: [...prev.customKeywords, value.trim()] }));
    }
  };

  const handleKeywordDelete = (keyword) => {
    setForm((prev) => ({ ...prev, customKeywords: prev.customKeywords.filter((el) => el !== keyword) }));
  };

  const resetFormState = () => {
    setOriginalForm(null);
    setForm(initialFormState);
  };

  const toggleTab = (name) => () => {
    setExamplesVisible(name === aiTabsConfig.examples);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const onCloseExampleBtnClick = () => {
    setExamplesVisible(false);
  };

  const fetchReviewResponseExamples = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(getReviewResponseExamples, { businessProfileId: businessProfile.id });
      setResponseExamples(data);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await api.post(upsertPromptConfiguration, { businessProfileId: businessProfile.id, ...removeNullValues(form) });
      showNotification({ message: t('aiConfig.configurationSaved'), type: 'success' });
      setOriginalForm(form);
      await fetchReviewResponseExamples();
      setExamplesVisible(true);
    } catch (err) {
      handleApiError({ err, t, showNotification });
      setExamplesVisible(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPromptConfiguration = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(getPromptConfiguration, { businessProfileId: businessProfile.id });
      if (data) {
        const { id, userId, ...rest } = data;
        setForm(rest);
        setOriginalForm(rest);
        await fetchReviewResponseExamples();
      } else {
        resetFormState();
      }
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConfiguration = async () => {
    setIsLoading(true);
    try {
      await api.post(deletePromptConfiguration, { businessProfileId: businessProfile.id });
      resetFormState();
      showNotification({ message: t('aiConfig.configurationDeleted'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (businessProfile.id && !isDataFetched) {
      fetchPromptConfiguration();
    }
  }, [businessProfile, isDataFetched]);

  if (examplesVisible) {
    return (
      <ReviewResponseExamples
        businessProfile={businessProfile}
        goBackHandler={onCloseExampleBtnClick}
        toggler={toggleTab}
        setResponseExamples={setResponseExamples}
        responseExamples={responseExamples}
      />
    );
  }

  return (
    <div className={classes.questionnaire}>
      {formWithNoDifference && isDataFetched && <AITabs toggler={toggleTab} current={aiTabsConfig.config} />}
      {isLoading && <LoaderOverlay customStyle={classes.overlay} />}
      <div className={classes.title}>{t('aiConfig.questionnaireTitle')}</div>
      {[formality, article, singularity, responseLength].map((key) => (
        <QuestionnaireOptions
          key={key}
          questionLabel={t(`aiConfig.${key}Label`)}
          selectedValue={form[key]}
          options={getQuestionnaireOption(key, t)}
          handleFormChange={handleFormChange(key)}
        />
      ))}
      <KeywordsSection
        value={form.customKeywords}
        handleAddKeyword={handleAddKeyword}
        handleDelete={handleKeywordDelete}
      />
      <SignSection value={form.sign} handleFormChange={handleFormChange(sign)} />
      <div className={classes.buttonsWrapper}>
        {isDataFetched && (
          <Button
            onClick={deleteConfiguration}
            label={t('aiConfig.deleteConfiguration')}
            className={classes.deleteBtn}
          />
        )}
        <Button onClick={goBackHandler} className={classes.backButton} label={t('aiConfig.backButton')} />
        <Button
          label={positiveBtnLabel}
          onClick={handleSubmit}
          readOnly={formWithNoDifference && isDataFetched}
          className={classes.submitButton}
        />
      </div>
    </div>
  );
};

export default Questionnaire;
