import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { emailValidator } from '../../../../../shared/components/Input/validators';
import Button from '../../../../../shared/components/Button';
import { useKeyDown } from '../../../../../shared/helpers/hooks';

const DeleteSubscriptionConfirmationModal = ({ onClose, user, onConfirm }) => {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState('');

  const onEmailChange = (value) => {
    setUserEmail(value);
  };

  useKeyDown({ key: 'Enter', callback: onConfirm, canInvoke: user.login === userEmail });

  return (
    <Modal onCancel={onClose} modalTitle={t('adminControlPanel.deleteSubscription')} titleStyle={classes.modalTitle} contentStyle={classes.modalWrapper} >
      <div>
        <p className={classes.subInformation}>{t('adminControlPanel.deleteSubscriptionConfirmation')}</p>
        <p className={classes.label}>{t('adminControlPanel.passUserEmail')}</p>
        <Input
          admin
          label={t('adminControlPanel.userEmail')}
          value={userEmail}
          showErrorAfterBlur
          validator={emailValidator}
          onChange={onEmailChange}
        />
      </div>
      <div className={classes.separator} />
      <Button
        readOnly={user.login !== userEmail}
        onClick={onConfirm}
        label={t('global.confirm')}
        className={classes.confirmBtn}
      />
    </Modal>
  );
};

export default DeleteSubscriptionConfirmationModal;
