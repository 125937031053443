import { ReactComponent as NoBusinessProfileIcon } from '../../../../shared/assets/noBusinessProfile.svg';
import { ReactComponent as BusinessProfileIcon } from '../../../../shared/assets/businessProfile.svg';
import { seoRoutes } from '../../../../shared/helpers/innerRoutes';

export const WelcomeOptionEnum = {
  login: 'login',
  register: 'register',
};
const { login, registerMethods } = seoRoutes;

export const options = [
  {
    id: WelcomeOptionEnum.login,
    name: 'welcome.nameLogin',
    route: login,
    Icon: BusinessProfileIcon,
    text: 'welcome.textLogin',
  },
  {
    id: WelcomeOptionEnum.register,
    name: 'welcome.nameRegister',
    route: registerMethods,
    Icon: NoBusinessProfileIcon,
    text: 'welcome.textRegister',
  },
];
export const hoverStateHandler = (id, onHover) => {
  const item = onHover.find((el) => el.id === id);
  if (item?.hover) {
    return true;
  }
  return false;
};
