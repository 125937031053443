import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared/components/Button';
import Input from '../../../../../shared/components/Input';
import { ReactComponent as Trash } from '../../../../../shared/assets/trash.svg';
import classes from './styles.module.css';
import { ReactComponent as CircleAdd } from '../../../../../shared/assets/addCircle.svg';
import { SERVICE_NAME_MAX_LENGTH } from '../ServicesForm/utils';
import { parseStringsWithWhiteSpaces } from '../../../../../shared/helpers/strings';

const CustomService = ({
  deleteCustomInputHandler,
  onAddCustomService,
  customServices,
  onCustomServiceChangeHandler,
  alreadyExist,
  structuredServicesLength,
}) => {
  const { t } = useTranslation();
  const [innerText, setInnerText] = useState('');

  const onChangeCustomServiceHandler = (value) => {
    setInnerText(value);
  };
  const onBlurCustomServiceHandler = (id) => {
    onCustomServiceChangeHandler(id, innerText);
    setInnerText('');
  };

  return (
    <>
      <div className={clsx(classes.addServices)}>
        {Boolean(structuredServicesLength) && t('businessProfileEdit.noOfferedServiceAddNew')}
        <Button
          readOnly={alreadyExist}
          onClick={onAddCustomService}
          iconWidth={16}
          Icon={CircleAdd}
          className={classes.addServicesButton}
          label={t('businessProfileEdit.addCustomService')}
        />
      </div>
      <div className={classes.customInputsContainer}>
        {Boolean(customServices.length)
          && customServices.map(({ serviceTypeId, displayName }) => (
            <div key={serviceTypeId}>
              <div className={classes.customInput}>
                <Input
                  maxLength={SERVICE_NAME_MAX_LENGTH}
                  value={displayName}
                  onChange={onChangeCustomServiceHandler}
                  className={classes.inputCustomService}
                  label={t('businessProfileEdit.customService')}
                  transformer={parseStringsWithWhiteSpaces}
                  onBlur={() => onBlurCustomServiceHandler(serviceTypeId)}
                />
                <div className={classes.trashButton}>
                  <Trash
                    onClick={() => deleteCustomInputHandler(serviceTypeId)}
                    className={classes.trashIcon}
                  />
                </div>
              </div>
            </div>
          ))}
        {alreadyExist && (
          <div className={classes.serviceAlreadyExist}>{t('businessProfileEdit.serviceAlreadyExist')}</div>
        )}
      </div>
    </>
  );
};
export default CustomService;
