import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { aiTabsConfig } from './utils';

const AITabs = ({ toggler, current }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.tabsWrapper}>
      <div className={current === aiTabsConfig.config ? classes.active : classes.inactive} onClick={toggler(aiTabsConfig.config)}>{t('aiConfig.configurator')}</div>
      <div className={current === aiTabsConfig.examples ? classes.active : classes.inactive} onClick={toggler(aiTabsConfig.examples)}>{t('aiConfig.answerExamples')}</div>
    </div>
  );
};

export default AITabs;
