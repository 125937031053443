import { apiRoutes } from '../../../../../shared/helpers/apiRoutes';

export const getCountriesFromArea = (areas) => {
  const countries = areas.map(({ placeName }) => {
    const match = placeName.match(/\b([\w\s]+)$/);
    return match ? match[1] : null;
  });
  const uniqueCountries = [...new Set(countries)];
  return uniqueCountries;
};

export const fetchBusienssProfileServiceArea = async ({ api, id, controller }) => {
  const { data } = await api.post(
    apiRoutes.businessProfile.getBusinessProfile,
    {
      businessProfileId: id,
      forDashboard: false,
    },
    { controller: controller.signal },
  );
  const { serviceArea } = data;
  const countries = getCountriesFromArea(serviceArea);
  return countries;
};
